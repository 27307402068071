/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";
import { RSelect } from "../../components/Component";
import DatePicker from "react-datepicker";
import states from "states-us";
import { codes, byAlpha2, byAlpha3, byNumeric } from "iso-country-codes";
import { useFormik, Field } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  carriernameair: yup.string().required("Carrier Name is Required"),
  aircountry: yup.string().required("Country is Required"),
  flightair: yup.string().required("Flight is Required"),
  airwaybillair: yup.string().required("AirWill Bill is Required"),
  airstate: yup.string().required("AirWill Bill is Required"),
  airport: yup.string().required("Airport is Required"),
  dateair: yup.string().required("Date Arrival is Required"),
});

const Air = ({ setActiveStep, pn, setpn, value, option }) => {
  const usestados = [];
  states.forEach((element) =>
    usestados.push({ value: element.name, label: element.name })
  );

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  const [startDate, setStartDate] = useState(new Date());

  const volver = () => {
    setActiveStep(1);
  };

  const formik = useFormik({
    initialValues: {
      carriernameair: pn.air.carriernameair,
      aircountry: pn.air.aircountry,
      flightair: pn.air.flightair,
      airwaybillair: pn.air.airwaybillair,
      airstate: pn.air.airstate,
      airport: pn.air.airport,
      dateair: pn.air.dateair,
      timeair: pn.air.timeair,
    },
    validationSchema,
    onSubmit: (values) => {
      setpn({
        ...pn,
        mode: {
          mode: value,
          purpose: option.proposito,
          other: option.otro,
        },
        idpn: values.airwaybillair,
        air: {
          carriernameair: values.carriernameair,
          aircountry: values.aircountry,
          flightair: values.flightair,
          airwaybillair: values.airwaybillair,
          airstate: values.airstate,
          airport: values.airport,
          dateair: values.dateair,
          timeair: values.timeair,
        },
      });
      setActiveStep(3);
    },
  });
  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit} id="validation-forms">
        <Row className="g-4">
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="carriernameair">
                Name of Carrier *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="carriernameair"
                  id="carriernameair"
                  className="form-control"
                  defaultValue={formik.values.carriernameair}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.carriernameair &&
                    formik.errors.carriernameair}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="country">
                Country *
              </label>
              <div className="form-control-wrap">
                <RSelect
                  options={countries}
                  id="aircountry"
                  name="aircountry"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("aircountry", selectedOption.value)
                  }
                  defaultValue={formik.values.aircountry}
                />
                <span className="text-danger">
                  {formik.touched.aircountry && formik.errors.aircountry}
                </span>
                <strong className="text-info">
                    {formik.values.aircountry
                      ? `Country Selected: ${formik.values.aircountry}`
                      : null}
                  </strong>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="trackingcourier">
                Flight Number *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="flightair"
                  name="flightair"
                  className="form-control"
                  defaultValue={formik.values.flightair}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.flightair && formik.errors.flightair}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="airwaybillair">
                Airway Bill Number *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="airwaybillair"
                  id="airwaybillair"
                  className="form-control"
                  defaultValue={formik.values.airwaybillair}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.airwaybillair && formik.errors.airwaybillair}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="stateair">
                State that Product is Flying into *
              </label>
              <div className="form-control-wrap">
                <RSelect
                  options={usestados}
                  id="airstate"
                  name="airstate"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("airstate", selectedOption.value)
                  }
                  defaultValue={formik.values.airstate}
                />
                <span className="text-danger">
                  {formik.touched.airstate && formik.errors.airstate}
                </span>
                <strong className="text-info">
                  {formik.values.airstate
                    ? `State Selected: ${formik.values.airstate}`
                    : null}
                </strong>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="stateair">
                Name of U.S. Airport of Entry *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="airport"
                  id="airport"
                  className="form-control"
                  defaultValue={formik.values.airport}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.airport && formik.errors.airport}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="arrivalair">
                Date of Arrival *
              </label>
              <div className="form-control-wrap">
                <DatePicker
                  selected={formik.values.dateair}
                  name="dateair"
                  defaultValue={formik.values.dateair}
                  onChange={(a) => formik.setFieldValue("dateair", a)}
                  className="form-control date-picker"
                />
                <span className="text-danger">
                  {formik.touched.dateair && formik.errors.dateair}
                </span>{" "}
              </div>
              <div className="form-note">
                Date Format <code>mm/dd/yyyy</code>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="timeair">
                Time of Arrival
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="timeair"
                  id="timeair"
                  className="form-control"
                  defaultValue={formik.values.timeair}
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="1">
            <Button onClick={volver}>Back</Button>
          </Col>
          <Col lg="1">
            <Button type="submit">Next</Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Air;

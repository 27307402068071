/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import { Button, Icon, Col, Row, RSelect } from "../../../components/Component";
import { codes } from "iso-country-codes";
import Swal from "sweetalert2";
import { useAuth0 } from "@auth0/auth0-react";
import { SaveCnee } from "../helpers/savecnee";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";

const ModalCnee = ({ modal, setModal, modalTab, setModalTab }) => {
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });
  const [direccion, setdireccion] = React.useState("");
  const searchOptions = {
    componentRestrictions: { country: ["us"] },
  };

  const handleSelect = async (address, placeId) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    const { short_name: estado = "" } =
      place.address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      ) || {};
    const { long_name: ciudad = "" } =
      place.address_components.find((c) => c.types.includes("locality")) || {};
    const { long_name: calle = "" } =
      place.address_components.find((c) => c.types.includes("route")) || {};
    const { long_name: pais = "" } =
      place.address_components.find((c) => c.types.includes("country")) || {};
    const { short_name: iso = "" } =
      place.address_components.find((c) => c.types.includes("country")) || {};
    const { short_name: numero = "" } =
      place.address_components.find((c) => c.types.includes("street_number")) ||
      {};
    const { long_name: distrito = "" } =
      place.address_components.find((c) =>
        c.types.includes("administrative_area_level_2")
      ) || {};

    setAddress(address);
    setCoordinates(latLng);
    setupdate({
      ...update,
      zip: postalCode,
      estado: estado,
      ciudad: ciudad,
      calle: calle,
      pais: pais,
      numero: numero,
      iso: iso,
      barrio: distrito,
    });
  };

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  const { user } = useAuth0();

  const exchange = (e) => {
    setupdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };

  const changepais = (e) => {
    setupdate({
      ...update,
      pais: e.value,
    });
  };

  const [update, setupdate] = useState({
    id_cliente: user.sub,
    legalname: "",
    dbaname: "",
    tipo: "",
    tax: "",
    telefono: "",
    web: "",
    contactname: "",
    cargo: "",
    email: "",
    ws: "",
    numero: "",
    calle: "",
    barrio: "",
    ciudad: "",
    estado: "",
    pais: "",
    zip: "",
  });

  const savefinal = () => {
    if (
      update.legalname === "" ||
      update.dbaname === "" ||
      update.tipo === "" ||
      update.tax === "" ||
      update.telefono === "" ||
      update.web === "" ||
      update.contactname === "" ||
      update.cargo === "" ||
      update.email === "" ||
      update.ws === "" ||
      update.numero === "" ||
      update.calle === "" ||
      update.barrio === "" ||
      update.ciudad === "" ||
      update.estado === "" ||
      update.pais === "" ||
      update.zip === ""
    ) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Please Complete All Information",
        showConfirmButton: true,
      });
    } else {
      SaveCnee(update).then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => setModal(false));
      });
    }
  };

  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered"
      size="lg"
      toggle={() => setModal(false)}
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            setModal(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">New Company (FIOR-IOR-BUYER)</h5>
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "1" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("1");
                }}
                href="#personal"
              >
                Personal
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "2" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("2");
                }}
                href="#address"
              >
                Company
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "3" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("3");
                }}
                href="#address"
              >
                Address
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTab === "1" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="contact-name">
                      Contact Name
                    </label>
                    <input
                      type="text"
                      id="contactname"
                      className="form-control"
                      name="contactname"
                      onChange={exchange}
                      //defaultValue={update ? update.contactname : null}
                      placeholder="Enter Full contact name"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="cargo">
                      Position in the Company
                    </label>
                    <input
                      type="text"
                      id="cargo"
                      className="form-control"
                      name="cargo"
                      onChange={exchange}
                      // //  defaultValue={update ? update.cargo : null}
                      placeholder="Enter display name"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="email">
                      Amazon Sell Center E-mail
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="form-control"
                      name="email"
                      onChange={exchange}
                      ////  defaultValue={update ? update.email : null}
                      placeholder="E-mail"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="telefono">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="telefono"
                      className="form-control"
                      name="telefono"
                      onChange={exchange}
                      ////  defaultValue={update ? update.telefono : null}
                      placeholder="Phone number"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="telefono">
                      WhatsApp Number
                    </label>
                    <input
                      type="text"
                      id="ws"
                      className="form-control"
                      name="ws"
                      onChange={exchange}
                      //// defaultValue={update ? update.ws : null}
                      placeholder="WhatsApp Number"
                    />
                  </FormGroup>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="lg" onClick={savefinal}>
                        Save Buyer
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTab === "2" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="12">
                  <FormGroup>
                    <label className="form-label" htmlFor="legalname">
                      Company Legal Name
                    </label>
                    <input
                      type="text"
                      id="legalname"
                      className="form-control"
                      name="legalname"
                      onChange={exchange}
                      // //  defaultValue={update ? update.legalname : null}
                      placeholder="Enter Company Legal Name"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="dbaname">
                      Company Initials
                    </label>
                    <input
                      type="text"
                      id="dbaname"
                      className="form-control"
                      name="dbaname"
                      onChange={exchange}
                      // //  defaultValue={update ? update.dbaname : null}
                      placeholder="Enter Company Initials"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="tipocorporacion">
                      Corporation Type
                    </label>
                    <input
                      type="text"
                      id="tipo"
                      className="form-control"
                      name="tipo"
                      onChange={exchange}
                      ////   defaultValue={update ? update.tipo : null}
                      placeholder="Enter Company Type "
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="tax_id">
                      Tax Identification Number
                    </label>
                    <input
                      type="text"
                      id="tax"
                      className="form-control"
                      name="tax"
                      onChange={exchange}
                      ////  defaultValue={update ? update.tax : null}
                      placeholder="Enter tax number"
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="website">
                      Web Site
                    </label>
                    <input
                      type="text"
                      id="web"
                      className="form-control"
                      name="web"
                      onChange={exchange}
                      ////  defaultValue={update ? update.web : null}
                      placeholder="Enter Company Web Site"
                    />
                  </FormGroup>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="lg" onClick={savefinal}>
                        Save Buyer
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTab === "3" ? "active" : ""}`}
              id="address"
            >
              <Row className="gy-4">
                <Col md="12">
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                    searchOptions={searchOptions}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        {/* <p>Latitude: {coordinates.lat}</p>
                      <p>Longitude: {coordinates.lng}</p> */}
                        <label
                          className="form-label text-primary"
                          htmlFor="calle"
                        >
                          Search Address
                        </label>
                        <input
                          className="form-control"
                          {...getInputProps({ placeholder: "Type address" })}
                        />

                        <div>
                          {loading ? <div>...loading</div> : null}

                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                            };

                            return (
                              <div
                                key={suggestion.description}
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label" htmlFor="calle">
                      Street
                    </label>
                    <input
                      type="text"
                      id="calle"
                      name="calle"
                      onChange={exchange}
                      defaultValue={update ? update.calle : null}
                      className="form-control"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="numero">
                      Number
                    </label>
                    <input
                      type="text"
                      id="numero"
                      name="numero"
                      defaultValue={update ? update.numero : null}
                      className="form-control"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="barrio">
                      District
                    </label>
                    <input
                      type="text"
                      id="barrio"
                      name="barrio"
                      onChange={exchange}
                      defaultValue={update ? update.barrio : null}
                      className="form-control"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="ciudad">
                      City
                    </label>
                    <input
                      type="text"
                      id="ciudad"
                      name="ciudad"
                      onChange={exchange}
                      defaultValue={update ? update.ciudad : null}
                      className="form-control"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="estado">
                      State
                    </label>
                    <input
                      type="text"
                      id="estado"
                      name="estado"
                      defaultValue={update ? update.estado : null}
                      className="form-control"
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="ciudad">
                      Country
                    </label>
                    <input
                      type="text"
                      id="pais"
                      name="pais"
                      defaultValue={update ? update.pais : null}
                      className="form-control"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="zip">
                      Zip Code
                    </label>
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      //onChange={exchange}
                      defaultValue={update ? update.zip : null}
                      className="form-control"
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="lg" onClick={savefinal}>
                        Save Buyer
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalCnee;

/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
// import { pusher_ecl } from "../../lib";
import {
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween,
  Button,
} from "../../components/Component";
// import Sellerinfo from "./sellerinfo";
// import { GetSeller } from "../helpers/getseller";
import { useAuth0 } from "@auth0/auth0-react";
import { monthNames, todaysDate } from "../../utils/Utils";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";
import CalculatorB2C from "../../B2C/calculator";
import QuotationB2C from "../../B2C/quotations";
import B2Cbooked from "../../B2C/booked";

const Principal = () => {
  const { user } = useAuth0();
  const [modaldoc, setModaldoc] = useState(false);

  const [factura, setfactura] = useState(false);

  //   var channel = pusher_ecl.subscribe("my-channel");
  //   channel.bind("my-event", function (data) {
  //     alert(JSON.stringify(data));
  //   });

  const [rates, setrates] = useState();

  const [hidden, sethidden] = useState({
    calculadora: true,
    cotizacion: false,
    booking: false,
  });

  const calculadora = () => {
    sethidden({
      calculadora: true,
      cotizacion: false,
      booking: false,
    });
  };
  const cotizaciones = () => {
    sethidden({
      calculadora: false,
      cotizacion: true,
      booking: false,
    });
  };

  const booking = () => {
    sethidden({
      calculadora: false,
      cotizacion: false,
      booking: true,
    });
  };

  return (
    <React.Fragment>
      <Head title="Dashboard"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                B2C Seller - Quick Quotation and Booking
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Delivery Inventory to FBA</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Row>
          <Col sm="12" md="3" lg="3">
            <Card className="card-bordered">
              <CardBody className="card-inner">
                <CardTitle tag="h5">Quick Calculator</CardTitle>
                <Row>
                  <Col sm="3">
                    <img
                      src="icons8-calculator-512.svg"
                      weigth="80"
                      height="80"
                      alt=""
                    />{" "}
                  </Col>{" "}
                  <Col sm="9">
                    <CardText>
                      Generate your logistic cost estimates and quotations.
                    </CardText>{" "}
                  </Col>
                </Row>
                <Button onClick={calculadora} color="primary">
                  Go!
                </Button>
              </CardBody>
            </Card>
          </Col>{" "}
          <Col sm="12" md="3" lg="3">
            <Card className="card-bordered">
              <CardBody className="card-inner">
                <CardTitle tag="h5">Saved Quotations</CardTitle>
                <Row>
                  <Col sm="3">
                    <img
                      src="icons8-documento-512.svg"
                      weigth="80"
                      height="80"
                      alt=""
                    />{" "}
                  </Col>{" "}
                  <Col sm="9">
                    <CardText></CardText>{" "}
                  </Col>
                </Row>
                <Button onClick={cotizaciones} color="primary">
                  Go!
                </Button>
              </CardBody>
            </Card>
          </Col>{" "}
          <Col sm="12" md="3" lg="3">
            <Card className="card-bordered">
              <CardBody className="card-inner">
                <CardTitle tag="h5">Shipping in Progress</CardTitle>
                <Row>
                  <Col sm="3">
                    <img
                      src="icons8-enviado.svg"
                      weigth="80"
                      height="80"
                      alt=""
                    />{" "}
                  </Col>{" "}
                  <Col sm="9">
                    <CardText></CardText>{" "}
                  </Col>
                </Row>
                <Button onClick={booking} color="primary">
                  Go!
                </Button>
              </CardBody>
            </Card>
          </Col>{" "}
          <Col sm="12" md="3" lg="3">
            <PreviewAltCard className="h-100 bg-light"></PreviewAltCard>
          </Col>{" "}
        </Row>
        <br></br>
        <Row>
          <Col sm="12" md="12" lg="12">
            {hidden.calculadora ? <CalculatorB2C></CalculatorB2C> : null}
          </Col>
          <Col sm="12" md="12" lg="12">
            {hidden.cotizacion ? <QuotationB2C></QuotationB2C> : null}
          </Col>
          <Col sm="12" md="12" lg="12">
            {hidden.booking ? <B2Cbooked></B2Cbooked> : null}
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};
export default Principal;

import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap'
import { Block } from '../../components/Component'
import { UpdateInfo } from '../helpers/updateinfo';
import { useAuth0 } from "@auth0/auth0-react";

const ProductCategoriesSelector = ( seller ) => {
  const { user } = useAuth0();

  console.log('product', user.sub)
  const [categorias, setcategorias] = useState({
    ToysGames: seller ? seller.seller.categorias?.ToysGames : false,
    ChildrenApparels: seller ? seller.seller.categorias?.ChildrenApparels : false,
    Clothing: seller ? seller.seller.categorias?.Clothings : false,
    Sportswear: seller ? seller.seller.categorias?.Sportswear : false,
    Electronics: seller ? seller.seller.categorias?.Electronics : false,
    Furniture: seller ? seller.seller.categorias?.Furniture : false,
    Textiles: seller ? seller.seller.categorias?.Textiles : false,
    Pet: seller ? seller.seller.categorias?.Pet : false,
    HealthPersonalCare: seller
      ? seller.categorias?.HealthPersonalCare
      : false,
    KitchenProducts: seller ? seller.seller.categorias?.KitchenProducts : false,
    FoodandGroceries: seller ? seller.seller.categorias?.FoodandGroceries : false,
  });

  const [isChecked, setIsChecked] = useState({
    customCheck1: categorias.ToysGames !== undefined ? categorias?.ToysGames : false,
    customCheck2: categorias.ChildrenApparels !== undefined ? categorias?.ChildrenApparels: false,
    customCheck3: categorias.Clothings !== undefined ? categorias?.Clothings: false,
    customCheck4: categorias.Sportswear !== undefined ? categorias?.Sportswear: false,
    customCheck5: categorias.Electronics !== undefined ? categorias?.Electronics: false,
    customCheck6: categorias.Furniture !== undefined ? categorias?.Furniture: false,
    customCheck7: categorias.Textiles !== undefined ? categorias?.Textiles :  false,
    customCheck8: categorias.Pet !== undefined ? categorias?.Pet: false,
    customCheck9: categorias.HealthPersonalCare !== undefined ? categorias?.HealthPersonalCare: false,
    customCheck10: categorias.KitchenProducts !== undefined ? categorias?.KitchenProducts: false,
    customCheck11: categorias.FoodandGroceries !== undefined ? categorias?.FoodandGroceries: false,
  });
  console.log(isChecked)
  const [otro, setOtro] = useState();
  const [businesDesc, setBusinesDesc] = useState();

  const exchange = (e) => {
    setIsChecked({
      ...isChecked,
      [e.target.id]: !isChecked[e.target.id],
    });
  };
  const exchangeotros = (e) => {
    setOtro({
      [e.target.name]: e.target.value,
    });
  };
  const exchangeBusinesDesc = (e) => {
    setBusinesDesc({
      [e.target.name]: e.target.value,
    });
  };
  const handleOnChange = (event) => {
    console.log("Form::onChange", event);
  };

  const onSubmit = (body, otro, businesDesc) => {
    let values = {
      info: businesDesc === undefined ? seller?.seller?.categorias?.info: businesDesc?.info,
      ToysGames: body.customCheck1,
      ChildrenApparels: body.customCheck2,
      Clothing: body.customCheck3,
      Sportswear: body.customCheck4,
      Electronics: body.customCheck5,
      Furniture: body.customCheck6,
      Textiles: body.customCheck7,
      Pet: body.customCheck8,
      HealthPersonalCare: body.customCheck9,
      KitchenProducts: body.customCheck10,
      FoodandGroceries: body.customCheck11,
      otros: otro === undefined ? seller?.seller?.categorias?.otros : otro?.otros
    }
    console.log(values)
    console.log(user.sub)
    UpdateInfo(user.sub, values)
  }

  return (
    <Fragment>
        <Block>
            <Form
              onChange={handleOnChange}
              >
              <Row>
                <Col md="4" lg='4'>
                  <FormGroup>
                    <label className="form-label text-primary" htmlFor="pais">
                      Bussinnes Description
                    </label>
                    <textarea
                      type="text"
                      id="info"
                      className="form-control"
                      name="info"
                      onChange={exchangeBusinesDesc}
                      defaultValue={
                        seller ? seller.seller.categorias["info"] : null
                      }
                      placeholder="Bussines Information"
                    />
                  </FormGroup>{" "}
                </Col>
                <Col md='8' lg='8'>
                  <Row>
                    <label className="form-label text-primary" htmlFor="pais">
                      Products Categories
                    </label>
                  </Row>
                  <Row>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck1"
                            name="ToysGames"
                            defaultChecked={isChecked.customCheck1}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck1"
                          >
                            Toys & Games
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck2"
                            name="ChildrenApparels"
                            defaultChecked={isChecked.customCheck2}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Children's Apparels
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck3"
                            name="Clothings"
                            defaultChecked={isChecked.customCheck3}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck3"
                          >
                            Clothings
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>{" "}
                  <br></br>
                  <Row>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck4"
                            name="Sportswear"
                            defaultChecked={isChecked.customCheck4}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck4"
                          >
                            Sportswear
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck5"
                            name="Electronics"
                            defaultChecked={isChecked.customCheck5}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck5"
                          >
                            Electronics
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck6"
                            name="Furniture"
                            defaultChecked={isChecked.customCheck6}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck6"
                          >
                            Furniture
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck7"
                            name="Textiles"
                            defaultChecked={isChecked.customCheck7}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck7"
                          >
                            Home Textiles
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck8"
                            name="Pet"
                            defaultChecked={isChecked.customCheck8}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck8"
                          >
                            Pet Products
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck9"
                            name="HealthPersonalCare"
                            defaultChecked={isChecked.customCheck9}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck9"
                          >
                            Health & Personal Care
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck10"
                            name="KitchenProducts"
                            defaultChecked={isChecked.customCheck10}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck10"
                          >
                            Kitchen Products
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input form-control"
                            id="customCheck11"
                            name="FoodandGroceries"
                            defaultChecked={isChecked.customCheck11}
                            onChange={exchange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck11"
                          >
                            Food and Groceries
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4} lg='4'>
                      <FormGroup>
                        <label className="form-label" htmlFor="others">
                          Others Categories
                        </label>{" "}
                        <Input
                          type="text"
                          className="form-control"
                          id="otros"
                          name="otros"
                          onChange={exchangeotros}
                          defaultValue={seller ? seller?.categorias?.otros : ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md='12' lg='12' className='d-flex justify-content-center'>
                  <Button type='button' color='primary' onClick={() => {onSubmit(isChecked, otro, businesDesc)}}>Update</Button>
                </Col>
              </Row>
            </Form>
        </Block>
    </Fragment>
  )
}

export default ProductCategoriesSelector


const menu = [
  // {
  //   heading: "Use-case preview",
  // },
  // {
  //   icon: "bag",
  //   text: "E-Commerce Panel",
  //   link: "/ecommerce/index",
  //   panel: true,
  //   newTab: true,
  //   subPanel: [
  //     {
  //       icon: "dashboard-fill",
  //       text: "Dashboard",
  //       link: "/ecommerce/index",
  //     },
  //     {
  //       icon: "bag-fill",
  //       text: "Orders",
  //       link: "/ecommerce/orders",
  //     },
  //     {
  //       icon: "package-fill",
  //       text: "Products",
  //       link: "/ecommerce/products",
  //     },
  //     {
  //       icon: "users-fill",
  //       text: "Customers",
  //       link: "/ecommerce/customer",
  //     },
  //     {
  //       icon: "chat-fill",
  //       text: "Support",
  //       link: "/ecommerce/support",
  //     },
  //     {
  //       icon: "opt-alt-fill",
  //       text: "Settings",
  //       link: "/ecommerce/settings",
  //     },
  //     {
  //       icon: "server-fill",
  //       text: "Integration",
  //       link: "/ecommerce/integration",
  //     },
  //   ],
  // },
  // {
  //   heading: "Home",
  // },
  // {
  //   icon: "home-fill",
  //   text: "Home",
  //   link: "/",
  // },

  // {
  //   heading: "B2C SERVICES",
  // },
  // {
  //   icon: "box",
  //   text: "Shipments to FBA",
  //   link: "/b2cnew",
  // },


  // {
  //   heading: "B2B SERVICES",
  // },
  // {
  //   icon: "truck",
  //   text: "Door to Door Shipments",
  //   link: "/b2bnew",
  // },

  {
    heading: "Tools",
  },
  // {
  //   icon: "grid-box",
  //   text: "Documentos de Embarque",
  //   link: "/",
  //   active: "false",
  // },
  {
    icon: "layers-fill",
    text: "Requisitos de Productos",
    i18nKey: "productRequirements",
    link: "/",
    active: "false",
  },
  {
    icon: "coffee-fill",
    text: "Importador de Registro",
    i18nKey: "registryImporter",
    link: "/iorspec",
    active: "false",
  },
  {
    icon: "call-alt",
    text: "Solicite Asistencia",
    i18nKey: "requestSupport",
    link: "/contactospec",
    active: "false",
  },
];

export default menu;

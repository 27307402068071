/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import DataTable from "react-data-table-component";
import { Button, TooltipComponent, Icon } from "../../../components/Component";
import { GetOpsuno } from "../helpers/getopsuno";
import { GetOps } from "../helpers/getops";
import FilesBooking from "./filesbooking";
import { toast } from "react-toastify";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import BookingDetails from "./bookingdetails";
import UseGetseller from "../../../MxCalculadoraRapida/hooks/usegetseller";
import { useAuth0 } from "@auth0/auth0-react";
import { pusher_ecl } from "../../../lib";
export default function Book() {
  const { user } = useAuth0();
  const seller = UseGetseller(user.sub);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  const [data, setdata] = useState([]);

  useEffect(() => {
    GetOps(user.sub).then((resp) =>
      setdata(resp.filter((resp) => resp.booked === true))
    );
  }, [user.sub]);

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button onClick={clickHandler}>
        <>
          <div className={`card-tools text-primary`}>
            <TooltipComponent
              icon="unarchive-fill"
              direction="left"
              id={`toogle-${row}`}
              text="Upload Shipping Documents"
            />
          </div>
        </>
      </Button>
    );
  };
  const ActionComponent2 = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button onClick={clickHandler}>
        <>
          <div className={`card-tools text-primary`}>
            <TooltipComponent
              icon="package-fill"
              direction="left"
              id={`toogle-details`}
              text="Shipment Details"
            />
          </div>
        </>
      </Button>
    );
  };
  const ActionComponent1 = ({ row, row1, onClick }) => {
    const clickHandler = () => onClick(row, row1);
    return (
      <Badge color="outline-primary" onClick={clickHandler}>
        <span>
          <Icon name="mc" />
        </span>
        <span>
          <Icon name="visa" />
        </span>
        <Icon name="stripe" />
      </Badge>
    );
  };

  const [modal, setModal] = useState(false);
  const [modalTab, setModalTab] = useState("3");
  const [modald, setModald] = useState(false);
  const [modalTabd, setModalTabd] = useState("1");
  const [bookingvalue, setbookingvalue] = useState();

  const booking = (e) => {
    GetOpsuno(e).then((response) => {
      setbookingvalue(response);
      setModal(true);
    });
  };

  const bookingd = (e) => {
    GetOpsuno(e).then((response) => {
      setbookingvalue(response);
      setModald(true);
    });
  };
  var [hidden, sethidden] = useState(false);

  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };

  const CustomToast = () => {
    return (
      <div className="toastr-text">
        <h5>Payment Successfully</h5>
        <p>Thanks for your trust</p>
      </div>
    );
  };
  function openWin6(e, f) {
    var myWindow6;
    myWindow6 = window.open(e, "_blank", "width=600, height=600");
    var channel6 = pusher_ecl.subscribe("my-channel");
    channel6.bind(f, function (data) {
      myWindow6.close();
      sethidden(!hidden);
      toast.success(<CustomToast />, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        closeButton: <CloseButton />,
      });
    });
  }

  const columnas = [
    {
      name: "Reference",
      cell: (row) => row.ref,
    },
    {
      name: "Origin",
      cell: (row) => `${row.ior.pais}`,
    },

    {
      name: "Destination",
      cell: (row) => `${row.cnee.pais}`,
    },

    {
      name: "Service",

      cell: (row) => row.services,
    },
    {
      name: "Freight Rate",

      cell: (row) =>
        formatter.format(
          row.totalflete +
            row.seguro +
            row.handling +
            row.admin +
            row.custom +
            row.pickuprate
        ),
    },
    {
      name: "Documents",
      cell: (row) => (
        <>
          <ActionComponent
            row={row._id.$oid}
            onClick={booking}
          ></ActionComponent>
        </>
      ),
    },
    {
      name: "Pay Freight",
      cell: (row) =>
        row.pay1 ? (
          <a href={row.receipt_link}>
            <Badge pill color="secondary">
              Download Receipt
            </Badge>
          </a>
        ) : (
          <>
            <ActionComponent1
              row={row.link}
              row1={row.id_invoice}
              onClick={openWin6}
            ></ActionComponent1>
          </>
        ),
    },
    {
      name: "Pay Duties",
      cell: (row) =>
        row.duties === 0 ? (
          <Badge pill color="info">
            {row.dutiesstatus}
          </Badge>
        ) : null,
    },
    {
      name: "Pay Extra",
      cell: (row) =>
        row.othercost === 0 ? (
          <Badge pill color="info">
            {row.othercoststatus}
          </Badge>
        ) : null,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge pill color="danger">
          {row.status}
        </Badge>
      ),
    },
    {
      name: "Details",
      cell: (row) => (
        <>
          <ActionComponent2
            row={row._id.$oid}
            onClick={bookingd}
          ></ActionComponent2>
        </>
      ),
    },
  ];

  /*const actions = [
    {
      icon: () => (
        <RemoveRedEyeIcon style={{ color: "#e39d3b" }} fontSize="large" />
      ),
      tooltip: "View Details",
      onClick: (event, rowData) => details(rowData.sku),
    },
  ]; */

  return (
    <>
      {/* <Button onClick={crearsku} variant="contained" color="secondary">
        Agregar Producto
      </Button> */}

      <React.Fragment>
        <Content>
          <span className="text-right">
            {/* <Button outline onClick={() => setModal(true)} color="secondary">
              Make a Booking
            </Button>{" "} */}
          </span>

          {data ? (
            <DataTable
              dense
              striped
              pagination
              columns={columnas}
              data={data}
              title="Shipments in Progress"
            />
          ) : null}
        </Content>
        <FilesBooking
          modal={modal}
          setModal={setModal}
          modalTab={modalTab}
          setModalTab={setModalTab}
          bookingvalue={bookingvalue}
          setbookingvalue={setbookingvalue}
        ></FilesBooking>
        <BookingDetails
          modal={modald}
          setModal={setModald}
          modalTab={modalTabd}
          setModalTab={setModalTabd}
          bookingvalue={bookingvalue}
          setbookingvalue={setbookingvalue}
          seller={seller ? seller.seller : null}
        ></BookingDetails>
      </React.Fragment>
    </>
  );
}

import React from "react";
import Principal from "./components/principal";


const B2Bbooked= () => {
  return (
    <React.Fragment>
      <Principal></Principal>
    </React.Fragment>
  );
};

export default B2Bbooked;
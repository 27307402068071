/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
/* eslint-disable no-unused-vars */
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Icon from "../../components/icon/Icon";
import classnames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import DataTable from "react-data-table-component";
import moment from "moment";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
} from "../../components/block/Block";
import { PreviewCard, CodeBlock } from "../../components/preview/Preview";
import {
  Button,
  BlockBetween,
  TooltipComponent,
  OverlineTitle,
  RSelect,
  PreviewAltCard,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import Switch from "react-switch";
import Iframe from "react-iframe";
import Swal from "sweetalert2";
import Compliance from "./compliance";
import ModalGeneral from "./modalgeneral";

import { GetServicios } from "../helpers/getservices";
import { fromUnixTime } from "date-fns";
import { GetDocumentos } from "../helpers/getdocumentos";
import { BajarDocument } from "../helpers/bajardocumentos";
import { GetSeller } from "../../dashboard/helpers/getseller";
import ModalEspecifico from "./modalespecifico";
import ModalOrigen from "./modalorigen";
import { pusher_ecl } from "../../lib";
import { toast } from "react-toastify";
import ModalFior from "./modalfior";
// import ModalEspecifico from "./modalespecifico";

// import GetCitas from "../helpers/getcitas";
// import ModalAppoint from "./modalappoint";
// import ModalReprogramar from "./modalreprogramar";

const Principal = ({ ...props }) => {
  const { isAuthenticated, logout, user } = useAuth0();
  const [activeIconTab, setActiveIconTab] = useState("6");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  var [hidden, sethidden] = useState(false);
  const [data, setdata] = useState();
  const [data1, setdata1] = useState();
  // const [modalap, setModalap] = useState(false);
  // const [modalrep, setModalrep] = useState(false);
  const [modaldoc, setModaldoc] = useState(false);
  const [modalTabdoc, setModalTabdoc] = useState("1");
  const [modaldoc1, setModaldoc1] = useState(false);
  const [modalfior, setModalFior] = useState(false);
  const [modalTabdoc1, setModalTabdoc1] = useState("1");
  const [modalorigen, setModalorigen] = useState(false);
  const [modalTaborigen, setModalTaborigen] = useState("1");
  const [modalTabdoc2, setModalTabdoc2] = useState("1");

  const [invoice, setinvoice] = useState();
  const [seller, setseller] = useState();
  //Variable para controla lista de solicitudes pagadas
  const [pago, setpago] = useState();
  useEffect(() => {
    GetServicios("compliance", user.sub).then((resp) =>
      setdata(resp.filter((respuesta) => respuesta.paid === false))
    );
    GetSeller(user.sub).then((respuesta) => setseller(respuesta[0]));
  }, [user.email, hidden, sethidden, user.sub, pago]);

  useEffect(() => {
    GetServicios("compliance", user.sub).then((resp) =>
      setdata1(resp.filter((respuesta) => respuesta.paid === true))
    );
  }, [user.email, hidden, sethidden, user.sub, pago]);

  function openWin6(e, f) {
    var myWindow6;
    myWindow6 = window.open(e, "_blank", "width=600, height=600");

    var channel6 = pusher_ecl.subscribe("my-channel");
    channel6
      .bind(f, function (data) {
        myWindow6.close();
        sethidden(!hidden);
        Swal.fire("Good job!", "Your invoice has been paid!", "success");
      })
      .then(setpago(!pago));
  }

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button color="primary" onClick={clickHandler}>
        <Icon name="file-doc" />
        <span>Download</span>
      </Button>
    );
  };
  const ActionComponent1 = ({ row, row1, onClick }) => {
    const clickHandler = () => onClick(row, row1);
    return (
      <Button outline color="primary" onClick={clickHandler}>
        <span>
          <Icon name="mc" />
        </span>
        <span>
          <Icon name="visa" />
        </span> 
        <span>
          <Icon name="sign-dollar" />
        </span>
        <span>
        <Icon name="stripe" />
        </span>
       
       
      </Button>
    );
  };
  const ActionComponent2 = ({ row, row1, row2, onClick }) => {
    const clickHandler = () => onClick(row, row2, row1);
    return (
      <Button outline color="primary" onClick={clickHandler}>
        <span>Upload Info</span>
      </Button>
    );
  };

  //Abre el modal segun el tipo de servicio
  const abrirmodal = (invoice, codigo, signed) => {
    if (codigo === "GRR") {
      setinvoice({
        invoice: invoice,
        signed: signed,
        codigo: codigo,
      });
      setModaldoc(true);
    }

    if (codigo === "FIOR") {
      setinvoice({
        invoice: invoice,
        signed: signed,
        codigo: codigo,
      });
      setModalFior(true);
    }
    
    if (codigo === "PCR") {
      setinvoice({
        invoice: invoice,
        signed: signed,
        codigo: codigo,
      });
      setModaldoc1(true);
    }

    if (codigo === "POC") {
      setinvoice({
        invoice: invoice,
        signed: signed,
        codigo: codigo,
      });
      setModalorigen(true);
    }
  };

  //Función para buscar y bajar el reporte
  const bajar = (e) => {
    const clave = `${user.sub}/compliance/${e}`;
    GetDocumentos(clave).then((response) =>
      BajarDocument(response[1].name).then((resp) =>
        window.open(resp, "_blank")
      )
    );
    // BajarDocument(e).then((resp) => window.open(resp, "_blank"));
  };

  const columnas = [
    {
      name: "Service Name",
      cell: (row) => row.codigo,
    },

    {
      name: "Service Name",
      cell: (row) => row.name,
    },
    {
      name: "Seller Information",
      cell: (row) =>
          row.codigo === 'BOND' ?
            <Col className="d-flex text-align-center">
              <Badge pill color="warning" >
                {row.service_track}
              </Badge>
            </Col>
          : 
          row.paid ? (
            <ActionComponent2
              id="tooltip-2"
              text="Download File"
              color="success"
              icon="file-download"
              row={row.id_invoice}
              row1={row.signed}
              row2={row.codigo}
              onClick={abrirmodal}
            ></ActionComponent2>
          ) : (
            <Badge pill color="primary">
              Not Available
            </Badge>
          )
       
    },

    {
      name: "Service Status",
      cell: (row) => (
        <Badge pill color="warning">
          {row.service_track}
        </Badge>
      ),
    },
    {
      name: "Files",
      cell: (row) =>
        row.report ? (
          <ActionComponent
            id="tooltip-2"
            text="Download File"
            color="success"
            icon="file-download"
            row={row.id_invoice}
            onClick={bajar}
          ></ActionComponent>
        ) : (
          <Badge pill color="info">
            Not Available
          </Badge>
        ),
    },

    {
      name: "Payment Status",
      cell: (row) =>
        row.paid ? (
          <Badge pill color="success">
            Paid
          </Badge>
        ) : (
          <Badge pill color="danger">
            Unpaid
          </Badge>
        ),
    },
    {
      name: "Payment",
      cell: (row) =>
        row.paid ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={row.link} target="_blank">
            <Badge pill color="secondary">
              Download Receipt
            </Badge>
          </a>
        ) : (
          <>
            <ActionComponent1
              row={row.link}
              row1={row.id_invoice}
              onClick={openWin6}
            ></ActionComponent1>
          </>
        ),
    },
    {
      name: "Date",
      cell: (row) => moment(row.created * 1000).format("LL"),
    },
    // {
    //   name: "Invoice",
    //   cell: (row) => row.id_invoice
    // },
    // {
    //   name: "Invoice",
    //   cell: (row) => (
    //     <>
    //       <a href={row.pdf_link}>
    //       <Icon name="clip"/>
    //       </a>
    //     </>
    //   ),
    // },

    // {
    //   name: "Status",
    //   cell: (row) => moment(row.start_time).format("LL"),
    // },
    // {
    //   name: "Start Time",
    //   cell: (row) => moment(row.start_time).format("LT"),
    // },
    // {
    //   name: "End Time",
    //   cell: (row) => moment(row.end_time).format("LT"),
    // },
    // {
    //   name: "Time Zone",

    //   cell: (row) => row.time_zone,
    // },
    // {
    //   name: "Service",
    //   cell: (row) => row.service,
    // },
    // {
    //   name: "Status",
    //   cell: (row) => row.status,
    // },
    // {
    //   name: "Actions",
    //   right: true,
    //   cell: (row) => (
    //     <>
    //       <ActionComponent1
    //         row={row.res_link}
    //         onClick={openMyWindow1}
    //         id = {row.id_meeting}
    //       ></ActionComponent1>
    //       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //       <ActionComponent
    //         row1={row.id_meeting}
    //         row={row.cancel_link}
    //         onClick={modalcitas}
    //       ></ActionComponent>
    //     </>
    //   ),
    // },
  ];

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>
                Compliance request services by the sellers
              </BlockTitle>
              <BlockDes className="text-soft">
                GSA Seller Accelerator: Easy, Reliable and Fast
              </BlockDes>
            </BlockHeadContent>
            {/* <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                <li>
                  <Button color="light" outline className="btn-white">
                    <Icon name="download-cloud"></Icon>
                    <span>Back</span>
                  </Button>
                </li>
              </ul>
            </BlockHeadContent> */}
          </BlockBetween>
        </BlockHead>{" "}
        <PreviewCard>
          <div className="p-2">
            <h5 className="title">Preliminar General Risk Report</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTabdoc2 === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTabdoc2("1");
                  }}
                  href="#personal"
                >
                  1. Unpaid Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTabdoc2 === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTabdoc2("2");
                  }}
                  href="#address"
                >
                  2. Services in Progress
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTabdoc2 === "3" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTabdoc2("3");
                  }}
                  href="#address"
                >
                  3. Finished Services
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className={`nav-link ${modalTabdoc2 === "4" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTabdoc2("4");
                  }}
                  href="#address"
                >
                  4. Invoices
                </a>
              </li> */}
            </ul>
          </div>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTabdoc2 === "1" ? "active" : ""}`}
              id="personal"
            >
              <Content>
                <DataTable
                  striped
                  pagination
                  columns={columnas}
                  data={data}
                  title="Compliance Services Requested"
                  responsive
                />
              </Content>
            </div>
          </div>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTabdoc2 === "2" ? "active" : ""}`}
              id="personal"
            >
              <Content>
                <DataTable
                  striped
                  pagination
                  columns={columnas}
                  data={data1}
                  title="Compliance Services Requested"
                  responsive
                />
              </Content>
            </div>
          </div>
        </PreviewCard>
        {/* <iframe src="https://ecommerce-logistics-llc.whereby.com/gassellers159864fb-f171-4d27-84ac-b7cfea8d5075" allow="camera; microphone; fullscreen; speaker; display-capture"></iframe> */}
      </Content>
      
      <ModalGeneral
        seller={seller ? seller : null}
        setseller = {setseller}
        modaldoc={modaldoc}
        setModaldoc={setModaldoc}
        modalTabdoc={modalTabdoc}
        setModalTabdoc={setModalTabdoc}
        invoice={invoice?.invoice}
        sethidden={sethidden}
        hidden={hidden}
      ></ModalGeneral>
      <ModalEspecifico
        seller={seller ? seller : null}
        modaldoc1={modaldoc1}
        setModaldoc1={setModaldoc1}
        modalTabdoc1={modalTabdoc1}
        setModalTabdoc1={setModalTabdoc1}
        invoice={invoice}
        sethidden={sethidden}
        hidden={hidden}
      ></ModalEspecifico>
      <ModalFior
        seller={seller ? seller : null}
        setseller= {setseller}
        modaldoc={modalfior}
        setModaldoc={setModalFior}
        modalTabdoc={modalTabdoc}
        setModalTabdoc={setModalTabdoc}
        invoice={invoice?.invoice}
        sethidden={sethidden}
        hidden={hidden}
      ></ModalFior>
      <ModalOrigen
        seller={seller ? seller : null}
        modalorigen={modalorigen}
        setModalorigen={setModalorigen}
        modalTaborigen={modalTaborigen}
        setModalTaborigen={setModalTaborigen}
        invoice={invoice}
        sethidden={sethidden}
        hidden={hidden}
      ></ModalOrigen>
    </React.Fragment>
  );
};

export default Principal;

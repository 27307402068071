import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { CardTitle } from "reactstrap";
import {
  Icon,
  TooltipComponent,
  Button,
  Row,
  Block,
} from "../../../components/Component";
import DataTable from "react-data-table-component";
import { GetDocumentos } from "../helpers/getdocumentos";
import Subirfiles from "./subirarchivos";
import { DeleteDocument } from "../helpers/deletedocuments";
import Swal from "sweetalert2";
import { BajarDocument } from "../helpers/bajardocumentos";
import { useAuth0 } from "@auth0/auth0-react";

const DocumentsExport = ({ referencia }) => {
  const {user} = useAuth0();
  const [documents, setdocuments] = useState();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    GetDocumentos(referencia, user.sub).then((response) => setdocuments(response));
  }, [setModal, modal, referencia, user.sub]);

  
  const ActionComponent = ({ id, text, color, icon, row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button onClick={clickHandler}>
        <>
          <div className={`card-tools text-${color}`}>
            <TooltipComponent
              icon={icon}
              direction="left"
              id={id}
              text={text}
            />
          </div>
        </>
      </Button>
    );
  };

  const deleterow = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteDocument(e).then(() => {
          const newstate = documents.filter((item) => item.name !== e);
          setdocuments(newstate);
        });
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const bajar = (e) => {
    BajarDocument(e).then((resp) => window.open(resp, "_blank"));
  };

  const columnas = [
    {
      name: "File Name",
      selector: (row) => `${row.name}`,
      cell: (row) =>
        row
          ? row.name.split(
              `${user.sub}/booking/b2b/${referencia}/`
            )
          : null,
    },
    // {
    //   name: "Size",
    //   selector: (row) => row.size,
    // },
    {
      cell: (row) => (
        <>
          <ActionComponent
            id="tooltip-2"
            text="Download File"
            color="success"
            icon="file-download"
            row={row.name}
            onClick={bajar}
          ></ActionComponent>
          <ActionComponent
            id="tooltip-1"
            text="Delete File"
            color="danger"
            icon="trash"
            row={row.name}
            onClick={deleterow}
          ></ActionComponent>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <span className="title text-primary">
        Upload Export Documents for{" "}
        <strong className="text-danger">{referencia}</strong>
      </span>
      <br></br>
      <br></br>
      <Subirfiles
        modal={modal}
        setModal={setModal}
        referencia={referencia}
        idcliente={user.sub}
      ></Subirfiles>
      <DataTable
        responsive
        pointerOnHover
        dense
        striped
        pagination
        columns={columnas}
        data={documents}
      />
    </React.Fragment>
  );
};
export default DocumentsExport;

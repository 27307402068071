/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import DataTable from "react-data-table-component";
import { Button, TooltipComponent, Icon } from "../../../components/Component";
import ModalBooking from "./modalbooking";
import { GetOpsuno } from "../helpers/getopsuno";
import { GetOps } from "../helpers/getops";
import { useAuth0 } from "@auth0/auth0-react";
import UseGetseller from "../../../MxCalculadoraRapida/hooks/usegetseller";

export default function Ops() {
  const { user } = useAuth0();
  const seller = UseGetseller(user?.sub);
  const [data, setdata] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [bookingvalue, setbookingvalue] = useState();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    GetOps(user?.sub).then((resp) =>
      setdata(resp.filter((resp) => resp.booked === false))
    );
  }, [user?.sub]);

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button onClick={clickHandler}>
        <>
          <div className={`card-tools text-success`}>
            <TooltipComponent
              icon="truck"
              direction="left"
              id={`toogle-${row}`}
              text="Request Booking"
            />
          </div>
        </>
      </Button>
    );
  };

  const booking = (e) => {
    GetOpsuno(e).then((response) => {
      setbookingvalue(response);
      setModal(true);
    });
  };

  const columnas = [
    {
      name: "Date",
      cell: (row) => row.date,
    },

    {
      name: "Reference",
      cell: (row) => row.ref,
    },
    // {
    //   name: "Buyer/Cnee",
    //   cell: (row) => `${row.cnee.legalname}`,
    // },
    {
      name: "Import Country",
      cell: (row) => `USA`,
    },

    {
      name: "Total FOB",
      selector: (row) => formatter.format(row.fob),
    },
    {
      name: "Qty Pallets",

      cell: (row) => row.qty,
    },
    {
      name: "Total Kg",

      cell: (row) => row.kg,
    },
    {
      name: "Quotation",

      cell: (row) =>
        formatter.format(
          row.totalflete +
            row.seguro +
            row.handling +
            row.admin +
            row.custom +
            row.pickuprate
        ),
    },
    {
      name: "by Pallet",

      cell: (row) =>
        formatter.format(
          (row.totalflete +
            row.seguro +
            row.handling +
            row.admin +
            row.custom +
            row.pickuprate) /
            row.qty
        ),
    },
    {
      name: "by Kg",

      cell: (row) =>
        formatter.format(
          (row.totalflete +
            row.seguro +
            row.handling +
            row.admin +
            row.custom +
            row.pickuprate) /
            row.kg
        ),
    },
    {
      name: "Book Quotation",
      cell: (row) => (
        <>
          <ActionComponent
            row={row._id.$oid}
            onClick={booking}
          ></ActionComponent>
        </>
      ),
    },
  ];

  /*const actions = [
    {
      icon: () => (
        <RemoveRedEyeIcon style={{ color: "#e39d3b" }} fontSize="large" />
      ),
      tooltip: "View Details",
      onClick: (event, rowData) => details(rowData.sku),
    },
  ]; */

  return (
    <>
      {/* <Button onClick={crearsku} variant="contained" color="secondary">
        Agregar Producto
      </Button> */}

      <React.Fragment>
        <Content>
          {data ? (
            <DataTable
              dense
              striped
              pagination
              columns={columnas}
              data={data}
              responsive
              title="Saved Quotations"
              highlightOnHover
            />
          ) : null}
        </Content>
        <ModalBooking
          seller={seller ? seller.seller : null}
          modal={modal}
          setModal={setModal}
          modalTab={modalTab}
          setModalTab={setModalTab}
          bookingvalue={bookingvalue}
          setbookingvalue={setbookingvalue}
        ></ModalBooking>
      </React.Fragment>
    </>
  );
}

export const GetFba = async (cliente) => {
  
    var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const response = await fetch(
    `https://apiecl.com/getfba`,
    requestOptions
  );
  const respuesta = response.json();
  return respuesta;
};



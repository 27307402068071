/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import { codes, byAlpha2, byAlpha3, byNumeric } from "iso-country-codes";
import { RSelect } from "../../components/Component";

const validationSchema = yup.object({
  ccompanyname: yup.string().required("Company Name is Required"),
  ccontactname: yup.string().required("Name is Required"),
  cphone: yup.string().required("Phone is Required"),
  cemail: yup.string().email("Invalid email Format").required("Email is Required"),
  caddress: yup.string().required("Address is Required"),
  ccity: yup.string().required("City is Required"),
  cstate: yup.string().required("State is Required"),
  ccountry: yup.string().required("Country is Required"),
  czipcode: yup.string().required("Zip Code is Required"),
});

const Consignee = ({ pn, setpn, setActiveStep }) => {
  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );

  const formik = useFormik({
    initialValues: {
      ccompanyname: pn.consignee.ccompanyname,
      cfdaregistration: pn.consignee.cfdaregistration,
      ccontactname: pn.consignee.ccontactname,
      cphone: pn.consignee.cphone,
      cemail: pn.consignee.cemail,
      caddress: pn.consignee.caddress,
      ccity: pn.consignee.ccity,
      cstate: pn.consignee.cstate,
      ccountry: pn.consignee.ccountry,
      czipcode: pn.consignee.czipcode,
    },
    validationSchema,
    onSubmit: (values) => {
      setpn({
        ...pn,
        consignee: {
          ccompanyname: values.ccompanyname,
          cfdaregistration: values.cfdaregistration,
          ccontactname: values.ccontactname,
          cphone: values.cphone,
          cemail: values.cemail,
          caddress: values.caddress,
          ccity: values.ccity,
          cstate: values.cstate,
          ccountry: values.ccountry,
          czipcode: values.czipcode,
        },
      });

      setActiveStep(2);
    },
  });

  const volver = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <form noValidate onSubmit={formik.handleSubmit} id="validation-forms">
        <Row className="g-4">
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="companyname">
                Company Name *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="ccompanyname"
                  name="ccompanyname"
                  className="form-control"
                  defaultValue={formik.values.ccompanyname}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.ccompanyname && formik.errors.ccompanyname}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="fdaregistration">
                FDA Registration
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="cfdaregistration"
                  name="cfdaregistration"
                  className="form-control"
                  defaultValue={formik.values.cfdaregistration}
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="contactname">
                Contact Name *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="ccontactname"
                  name="ccontactname"
                  className="form-control"
                  defaultValue={formik.values.ccontactname}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.ccontactname && formik.errors.ccontactname}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="phone">
                Phone *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="cphone"
                  name="cphone"
                  className="form-control"
                  defaultValue={formik.values.cphone}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.cphone && formik.errors.cphone}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="email">
                Email *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="cemail"
                  name="cemail"
                  className="form-control"
                  defaultValue={formik.values.cemail}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.cemail && formik.errors.cemail}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="5">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="address">
                Address *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="caddress"
                  name="caddress"
                  className="form-control"
                  defaultValue={formik.values.caddress}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.caddress && formik.errors.caddress}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="city">
                City *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="ccity"
                  name="ccity"
                  className="form-control"
                  defaultValue={formik.values.ccity}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.ccity && formik.errors.ccity}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="state">
                State/Province *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="cstate"
                  name="cstate"
                  className="form-control"
                  defaultValue={formik.values.cstate}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.cstate && formik.errors.cstate}
                </span>
              </div>
            </FormGroup>
          </Col>
          {
            <Col lg="3">
              <FormGroup className="form-group">
                <label className="form-label" htmlFor="country">
                  Country *
                </label>
                <div className="form-control-wrap">
                  <RSelect
                    options={countries}
                    id="ccountry"
                    name="ccountry"
                    onChange={(selectedOption) =>
                      formik.setFieldValue("ccountry", selectedOption.value)
                    }
                    defaultValue={formik.values.ccountry}
                  />
                  <span className="text-danger">
                    {formik.touched.ccountry && formik.errors.ccountry}
                  </span>
                  <strong className="text-info">
                    {formik.values.ccountry
                      ? `Country Selected: ${formik.values.ccountry}`
                      : null}
                  </strong>
                </div>
              </FormGroup>
            </Col>
          }
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="czipcode">
                Zip Code
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="czipcode"
                  id="czipcode"
                  className="form-control"
                  defaultValue={formik.values.czipcode}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.czipcode && formik.errors.czipcode}
                </span>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col lg="1">
            <Button onClick={volver}>Back</Button>
          </Col>
          <Col lg="1">
            <Button type="submit">Next</Button>
          </Col>
        </Row>
      </form>

      {/*<ActionButtons {...props} nextStep={validate} /> */}
    </div>
  );
};

export default Consignee;

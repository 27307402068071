import React from "react";
import Principal from "./components/principal";


const QuotationB2B= () => {
  return (
    <React.Fragment>
      <Principal></Principal>
    </React.Fragment>
  );
};

export default QuotationB2B;
const SaveSku = async event => {
	
	
	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	const fecha = new Date();
	const fecha1 = fecha.toDateString();
	event.fecha_creacion = fecha1;
	const raw = JSON.stringify(event);
	
	const RequestInit  = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};
	fetch('https://apiecl.com/savesku', RequestInit)
		.then(response => response.text())
		.then(result => console.log(result))
		.catch(error => console.log('error', error));
	
	return "OK"

};

export default SaveSku;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../../components/Component";

const ListHtsTools = () => {
  const search = () => {};

  // <SimplePopover codigo = {id}/>
  return (
    <>
      <Link role="button" to="/htstax">
        <div className="text-center">
          <Button onClick={search} variant="contained" color="primary">
            + New Classification
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img src="icons8-customs-512.svg" alt="" height="60" />
        </div>
      </Link>
    </>
  );
};

export default ListHtsTools;

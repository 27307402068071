import React from "react";
import { useTranslation } from "react-i18next";
import ResponsiveEmbed from "react-responsive-embed";
import {
  Card,
  CardBody,
  CardText,
} from "reactstrap";
import {
  Block,
  Col,
  PreviewAltCard,
  Row,
} from "../../components/Component";

export default function DashSpec() {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <React.Fragment>
        <Block>
          <Row className="g-gs">
            <Col lg="12">
              <PreviewAltCard className="border-secondary">
                {" "}
                <span className="text-mute">
                  <h5>{t("description.bajadadash")}</h5>
                </span>
                <span>
                  {" "}
                  <br />
                  <h5>{t("description.bajadadash_sub")}</h5>
                </span>
                <br />
                <br />
                <Card className="card-bordered">
                  <CardBody className="card-inner">
                    <CardText>
                      {i18n.resolvedLanguage === "en" ? (
                        <ResponsiveEmbed
                          src="https://api.leadconnectorhq.com/widget/form/Jp0hxWNFCOiLWSQVtfrn"
                          ratio="1:1"
                        />
                      ) : i18n.resolvedLanguage === "es" ? (
                        <ResponsiveEmbed
                          src="https://api.leadconnectorhq.com/widget/form/HgUw6PwKvZkPi5jh96Aa"
                          ratio="1:1"
                        />
                      ) : (
                        <ResponsiveEmbed
                          src="https://api.leadconnectorhq.com/widget/form/ITKLOqixuFVxO2XxEGYj"
                          ratio="1:1"
                        />
                      )}
                    </CardText>
                  </CardBody>
                </Card>
              </PreviewAltCard>
            </Col>
          </Row>
        </Block>
      </React.Fragment>
    </div>
  );
}

export const BajarFirmado = async (id) => {

var requestOptions = {
  method: 'POST',

  redirect: 'follow'
};

const resp = await fetch(`https://apiecl.com/linkfirmado?id_doc=${id}`, requestOptions)
const sku = await resp.text();
return sku;

}
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Icon from "../../../components/icon/Icon";
import classnames from "classnames";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
} from "../../../components/block/Block";
import {
  Button,
  BlockBetween,
  TooltipComponent,
  OverlineTitle,
  RSelect,
} from "../../../components/Component";
import "react-data-table-component-extensions/dist/index.css";
import Book from "./book";

const Principal = () => {
  return (
    <>
      <React.Fragment>
        <Head title="GSA SELLER B2B Module"></Head>

        {/* <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>B2B System - Seller Module</BlockTitle>
                <BlockDes className="text-soft">
                  <p>Easy, Reliable and Fast</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <ul className="nk-block-tools g-3">
                  <li>
                    <Button color="light" outline className="btn-white">
                      <Icon name="download-cloud"></Icon>
                      <span>Back</span>
                    </Button>
                  </li>
                </ul>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead> */}
        <Book></Book>
      </React.Fragment>
    </>
  );
};
export default Principal;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";
import { RSelect } from "../../components/Component";
import DatePicker from "react-datepicker";
import states from "states-us";
import { codes, byAlpha2, byAlpha3, byNumeric } from "iso-country-codes";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  carriernamepostal: yup.string().required("Carrier Name is Required"),
  datepostal: yup.string().required("Date is Required"),
});

const Postal = ({ setActiveStep, pn, setpn, value, option }) => {
  const usestados = [];
  states.forEach((element) =>
    usestados.push({ value: element.name, label: element.name })
  );

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  const [startDate, setStartDate] = useState(new Date());

  const volver = () => {
    setActiveStep(1);
  };

  var num = Math.floor(Math.random() * ((10000+1) - 100) + 1000);
  var id = `PN${num}`;

  const formik = useFormik({
    initialValues: {
      carriernamepostal: pn.postal.carriernamepostal,
      datepostal: pn.postal.datepostal,
      timepostal: pn.postal.timepostal,
    },
    validationSchema,
    onSubmit: (values) => {
      setpn({
        ...pn,
        mode: {
          mode: value,
          purpose: option.proposito,
          other: option.otro,
        },
        idpn: id,
        postal: {
          carriernamepostal: values.carriernamepostal,
          datepostal: values.datepostal,
          timepostal: values.timepostal,
        },
      });
      setActiveStep(3);
    },
  });

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit} id="validation-forms">
        <Row className="g-4">
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="carriernameocean">
                Name of Carrier *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="carriernamepostal"
                  className="form-control"
                  defaultValue={formik.values.carriernamepostal}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.carriernamepostal &&
                    formik.errors.carriernamepostal}
                </span>
              </div>
            </FormGroup>{" "}
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="arrivalair">
                Date of Arrival *
              </label>
              <div className="form-control-wrap">
                <DatePicker
                  selected={formik.values.datepostal}
                  name="datepostal"
                  defaultValue={formik.values.datepostal}
                  onChange={(a) => formik.setFieldValue("datepostal", a)}
                  className="form-control date-picker"
                />
                <span className="text-danger">
                  {formik.touched.datepostal && formik.errors.datepostal}
                </span>{" "}
              </div>
              <div className="form-note">
                Date Format <code>mm/dd/yyyy</code>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="timeair">
                Time of Arrival
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="timepostal"
                  className="form-control"
                  defaultValue={formik.values.timepostal}
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        <Row className="g-4">
          <Col lg="1">
            <Button onClick={volver}>Back</Button>
          </Col>
          <Col lg="1">
            <Button type="submit">Next</Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Postal;

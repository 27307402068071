import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  FormGroup,
} from "reactstrap";
import {
  Button,
} from "../../components/Component";
export default function Htsbegin({ setencabezado }) {
  //const classes = useStyles();

  const [datos, setDatos] = useState({
    country: "",
    hts: "",
  });

  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (datos.hts.trim().length > 3) {
      setencabezado({
        ...datos,
        destination: "United States",
        hidden: true,
        hidden3: false,
      });

      setDatos({
        country: "",
        hts: "",
        destination: "United States",
      });
    } else {
      Swal.fire({
        title: "oops!",
        text: "Insert Information to Search HTS!!",
        icon: "warning",
      });
    }
  };

  return (
    <React.Fragment>
      {" "}
      <FormGroup className="form-group">
        <div className="form-control-wrap">
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              id="hts"
              name="hts"
              value={datos.hts}
              onChange={handleInputChange}
            />
            <div className="input-group-append">
              <Button onClick={handleSubmit} color="primary">
                Search Products
              </Button>
            </div>
          </div>
        </div>
      </FormGroup>
    </React.Fragment>
  );
}

import React, { useState } from "react";
import Swal from "sweetalert2";
import Content from "../../../layout/content/Content";
import { Icon, TooltipComponent, Button } from "../../../components/Component";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import FileProvider from "../providers/file.provider";
import {
  checkFileMIMEType,
  checkFileSize,
  getFormattedSize,
} from "../helpers/files.helper";
import DataTable from "react-data-table-component";
const Subirfiles = ({ idcliente, referencia, modal, setModal }) => {
  const { register, handleSubmit } = useForm();
  const MAXIMUM_FILES_LIMIT = null;
  /**
   * Size in MB
   */
  const MAXIMUM_FILE_SIZE = 20;
  const ALLOWED_FORMATS = ".pdf, .doc, .docx, .odt, image/*";

  //const [files, setFiles] = useState([]);
  const [filesname, setFilesname] = useState([]);

  const [escondido, setEscondido] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const columnas = [
    {
      name: "File Name",
      selector: (row) => row.nombre,
      cell: (row) => row.nombre,
    },
  ];

  const getCurrentFiles = (event) => {
    var rawFiles = event.target.files;

    const finalFileList = [];
    for (const file of rawFiles) {
      finalFileList.push(file.name);
    }
    setFilesname(finalFileList);
    setEscondido(true);
   
  };
  
  const onSubmit = async (data) => {
    const formData = new FormData();
    const ultimo = data.file.length;
    const key = `${idcliente}/booking/b2b/${referencia}`;
    for (let i = 0; i < ultimo; ++i) {
      formData.append("file", data.file[i]);
      const response = await fetch(
        `https://apiecl.com/uploaddocuments?id_seller=${key}`,
        {
          method: "POST",
          body: formData,
        }
      ).then((res) =>  setModal(!modal));
    }

    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Your work has been saved",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      setFilesname([]);
    });
    //alert(JSON.stringify(`${res.message}, status: ${res.status}`));
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-group mb-5 mt-n4">
          <div className="custom-file">
            <input
              type="file"
              {...register("file")}
              className="custom-file-input"
              onChange={getCurrentFiles}
              multiple
              id="file-uploader"
            />
            <label className="custom-file-label" htmlFor="file-uploader">
              Choose files
            </label>
          </div>
        </div>
        {filesname.length > 0 ? (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th className="text-info">File Name</th>
                </tr>
              </thead>
              <tbody>
                {filesname.map((img, index) => {
                  return (
                    <tr key={index}>
                      <td>{img}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <FormGroup>
              <Button color="primary" type="submit">
                Upload
              </Button>
            </FormGroup>
          </>
        ) : null}
      </form>{" "}
    </React.Fragment>
  );
};

export default Subirfiles;

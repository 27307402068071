/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Center from "react-center";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../../components/Component";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ChangingProgressProvider from "./ChangingProgressProvider";
import UseFetchClas from "../hooks/UseFetchClas";


const HtsGrid2 = ({ encabezado, setencabezado }) => {
  // const { data, loading, finales } = UseFetchHts(encabezado.hts)

  const ActionComponent = ({
    htsno,
    general,
    special,
    duties,
    dutiespecific,
    description1,
    onClick,
  }) => {
    const clickHandler = () =>
      onClick(htsno, general, special, duties, dutiespecific,description1);
    return <Button color="info" onClick={clickHandler}>Seleccionar</Button>;
  };

  const { data, loading, finales } = UseFetchClas(encabezado.hts);
  
  const detailhts = (e, f, g, h, i, j) => {
    setencabezado({
      ...encabezado,
      hidden1: true,
      hidden: false,
      hts: e,
      hts8: e,
      general: f,
      special: g,
      duties: h,
      dutiespecific: i,
      description1: j
    });
  };

  const columnas = [
    {
      name: "HTS Number",
      selector: (row) => row.hts8,
    },
    {
      name: "Brief Description",
      selector: (row) => row.brief_description,
    },
    {
      name: "Actions",
      cell: (row) => (
        <ActionComponent
          htsno={row.hts8}
          general={row.mfn_text_rate}
          special={row.col1_special_text}
          description1 ={row.brief_description}
          onClick={detailhts}
        ></ActionComponent>
      ),
    },
  ];

  

  return (
    <>
      {loading ? (
         <Center>
         <span style={{ width: 80, height: 80 }}>
           <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
             {(percentage) => (
               <CircularProgressbar
                 value={percentage}
                 text={`${percentage}%`}
                 styles={buildStyles({
                   pathTransitionDuration: 0.15,
                 })}
               />
             )}
           </ChangingProgressProvider>
         </span>{" "}
       </Center>
      ) : (
      
          <DataTable columns={columnas} data={data} striped  pagination/>
       
      )}
    </>
  );
};

export default HtsGrid2;

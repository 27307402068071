
const Getprices = async codigo => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "codigo": codigo
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const response = await fetch("https://apiecl.com/getprice", requestOptions)
  const respuesta = response.json();
  return respuesta;

}

export default Getprices

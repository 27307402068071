/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { CardTitle } from "reactstrap";
import {
  Icon,
  TooltipComponent,
  PreviewAltCard,
  Button,
} from "../../../components/Component";
import { Timeline, TimelineEvent, TimelineBlip } from "react-event-timeline";
// import GetEventos from "../helpers/geteventos";
import { fromUnixTime } from "date-fns";

const Bookingstep = ({ bookingvalue }) => {
  const [s1, sets1] = useState([]);
  const [s2, sets2] = useState([]);
  const [s3, sets3] = useState([]);

  //   useEffect(() => {
  //     GetEventos(seller.id_cliente).then((resp) => {
  //       sets1(resp.filter((resp) => resp.id_evento === "s1"));
  //       sets2(resp.filter((resp) => resp.id_evento === "s2"));
  //       sets3(resp.filter((resp) => resp.id_evento === "s3"));
  //     });
  //   }, [seller.id_cliente]);

  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title text-primary">Shipping Events</h6>
        </CardTitle>
      </div>
      <hr></hr>

      <Timeline>
        {bookingvalue[0].booking[0].eventos.length > 0 ? (
          bookingvalue[0].booking[0].eventos.map((resp) => (
            <div key={resp["descripcion"]}>
              <TimelineEvent
                title={<strong>{resp["descripcion"]}</strong>}
                createdAt={resp["fecha"]}
                icon={<Icon name="bullet-fill"></Icon>}
                iconColor="#5cb85c"
                bubbleStyle={{ backgroundColor: "#FFFFFF" }}
              >
                <strong>{resp.remarks} </strong>
              </TimelineEvent>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </Timeline>
    </React.Fragment>
  );
};
export default Bookingstep;

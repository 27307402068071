export const GetDocumentos = async (referencia,idcliente) => {
  const cliente = `${idcliente}/booking/b2b/${referencia}`;
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  const response = await fetch(
    `https://apiecl.com/getdocuments?id_seller=${cliente}`,
    requestOptions
  );
  const respuesta = response.json();
  
  return respuesta;
};

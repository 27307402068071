/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
} from "../../components/block/Block";
import Shipper from "./shipper";
import Content from "../../layout/content/Content";
import Consignee from "./consignee";
import Shipping from "./shipping";
import Products from "./products";
import Review from "./review";
import Stripe from "./stripe";

const Stepform = ({ setActiveIconTab }) => {
  //Para la activacion de los Step

  const [pn, setpn] = useState({
    shipper: {
      shcompanyname: "",
      shfdaregistration: "",
      shcontactname: "",
      shphone: "",
      shemail: "",
      shaddress: "",
      shcity: "",
      shstate: "",
      shcountry: "",
      shzipcode: "",
    },

    consignee: {
      ccompanyname: "",
      cfdaregistration: "",
      ccontactname: "",
      cphone: "",
      cemail: "",
      caddress: "",
      ccity: "",
      cstate: "",
      ccountry: "",
      czipcode: "",
    },

    mode: {
      mode: "",
      purpose: "",
      other: "",
    },
    courier: {
      carriernamecourier: "",
      trackingcourier: "",
      shimpentmodecourier: "",
      date: "",
    },

    air: {
      carriernameair: "",
      aircountry: "",
      flightair: "",
      airwaybillair: "",
      airstate: "",
      airport: "",
      dateair: "",
      timeair: "",
    },

    ocean: {
      carriernameocean: "",
      oceancountry: "",
      vesselocean: "",
      containerocean: "",
      voyageocean: "",
      blocean: "",
      oceanstate: "",
      portocean: "",
      oceantime: "",
      dateocean: "",
      wrname: "",
      wrocean: "",
    },

    truck: {
      carriernametruck: "",
      truckcountry: "",
      license: "",
      blnumber: "",
      truckstate: "",
      crosspoint: "",
      datetruck: "",
      timetruck: "",
    },

    postal: {
      carriernamepostal: "",
      datepostal: "",
      timepostal: "",
    },

    products: [],

    idpn: "",
    idcliente: "",
    pago: false,
  });

  const [activeStep, setActiveStep] = useState(0);

  return (
    <div>
      <Stepper
        styleConfig={{ completedBgColor: "#03a14a", activeBgColor: "#8510d8" }}
        activeStep={activeStep}
      >
        <Step label="Shipper" />
        <Step label="Consignee" />
        <Step label="Shipping" />
        <Step label="Products" />
        <Step label="Review" />
        <Step label="Payment and Send" />
      </Stepper>
      {activeStep === 0 ? (
        <Block className="rounded-pill border border-primary" size="lg">
          <Content>
            <BlockHead size="sm">
              <BlockHeadContent>
                <BlockTitle page>Shipper Information</BlockTitle>
                <BlockDes className="text-soft">
                  <p>Información del Enviador</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>
            <Shipper
              pn={pn}
              setpn={setpn}
              setActiveStep={setActiveStep}
            ></Shipper>
          </Content>
        </Block>
      ) : null}
      {activeStep === 1 ? (
        <Block className="rounded-pill border border-primary" size="lg">
          <Content>
            <BlockHead size="sm">
              <BlockHeadContent>
                <BlockTitle page>Consignee Information</BlockTitle>
                <BlockDes className="text-soft">
                  <p>Información del Importador</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>
            <Consignee
              pn={pn}
              setpn={setpn}
              setActiveStep={setActiveStep}
            ></Consignee>
          </Content>
        </Block>
      ) : null}
      {activeStep === 2 ? (
        <Block className="rounded-pill border border-primary" size="lg">
          <Content>
            <BlockHead size="sm">
              <BlockHeadContent>
                <BlockTitle page>Shipping Information</BlockTitle>
                <BlockDes className="text-soft">
                  <p>Información del Embarque</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>
            <Shipping
              pn={pn}
              setpn={setpn}
              setActiveStep={setActiveStep}
            ></Shipping>
          </Content>
        </Block>
      ) : null}
      {activeStep === 3 ? (
        <Block className="rounded-pill border border-primary" size="lg">
          <Content>
            <BlockHead size="sm">
              <BlockHeadContent>
                <BlockTitle page>Products Information</BlockTitle>
                <BlockDes className="text-soft">
                  <p>Información de Productos</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>
            <Products
              pn={pn}
              setpn={setpn}
              setActiveStep={setActiveStep}
            ></Products>
          </Content>
        </Block>
      ) : null}
      {activeStep === 4 ? (
        <Block className="rounded-pill border border-primary" size="lg">
          <Content>
            <Review
              pn={pn}
              setpn={setpn}
              setActiveStep={setActiveStep}
              setActiveIconTab={setActiveIconTab}
            ></Review>
          </Content>
        </Block>
      ) : null}
      {activeStep === 5 ? (
        <Block className="rounded-pill border border-primary" size="lg">
          <Content>
            <BlockHead size="sm">
              <BlockHeadContent>
                <BlockTitle page></BlockTitle>
                <BlockDes className="text-soft">
                  <p></p>
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>
            <Stripe pn={pn}></Stripe>
          </Content>
        </Block>
      ) : null}
    </div>
  );
};

export default Stepform;

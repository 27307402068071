/* eslint-disable import/prefer-default-export */

import { useState, useEffect } from 'react';
import { HtsGetCategories } from '../helpers/GetHtsCategory';

export const UseFetchHtsCategory = htscode => {
	const [state, setState] = useState({
		categorias: []
	});

	useEffect(() => {
		HtsGetCategories(htscode).then(imgs => {
			setState({
				categorias: imgs
			});
		});
	}, [htscode]);
	return state;
};

/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
// import { pusher_ecl } from "../../lib";
import {
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween,
  Button,
} from "../../components/Component";
// import Sellerinfo from "./sellerinfo";
// import { GetSeller } from "../helpers/getseller";
import { useAuth0 } from "@auth0/auth0-react";
import { monthNames, todaysDate } from "../../utils/Utils";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";
import PriorNotice from "../../priornotice";
import Compliance from "../../compliance";
import ComplianceR from "./compliance";
import InvoiceR from "./muestrainvoice";

const Principal = () => {
  const { user } = useAuth0();
  const [modaldoc, setModaldoc] = useState(false);

  const [factura, setfactura] = useState(false);

  //   var channel = pusher_ecl.subscribe("my-channel");
  //   channel.bind("my-event", function (data) {
  //     alert(JSON.stringify(data));
  //   });

  const [rates, setrates] = useState();

  const [hidden, sethidden] = useState({
    prionotice: false,
    compliancelist: true,
    compliancenew: false,
  });

  const priornotice = () => {
    sethidden({
      prionotice: true,
      compliancelist: false,
      compliancenew: false,
    });
  };
  const compliancelist = () => {
    sethidden({
      prionotice: false,
      compliancelist: true,
      compliancenew: false,
    });
  };

  const compliancenew = () => {
    sethidden({
      prionotice: false,
      compliancelist: false,
      compliancenew: true,
    });
  };

  return (
    <React.Fragment>
      <Head title="Dashboard"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Compliance Services for Sellers
              </BlockTitle>
              <BlockDes className="text-soft">
                <p></p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Row>
          <Col sm="12" md="3" lg="3">
            <PreviewAltCard className="h-100 bg-light">
              <Card className="card-bordered">
                <CardBody className="card-inner">
                  <CardTitle tag="h5">Compliance Robot</CardTitle>
                  <Row>
                    <Col sm="5">
                      <img src="icons8-robot-3-400.svg" alt="" />{" "}
                    </Col>{" "}
                    <Col sm="6">
                      <CardText>
                        Search Information the compliance to export to USA
                      </CardText>{" "}
                    </Col>
                  </Row>
                  <Button onClick={compliancelist} color="primary">
                    Comming soon!
                  </Button>
                </CardBody>
              </Card>
            </PreviewAltCard>
          </Col>{" "}
          <Col sm="12" md="3" lg="3">
            <Card className="card-bordered">
              <CardBody className="card-inner">
                <CardTitle tag="h5">Prior Notice (FDA)</CardTitle>
                <Row>
                  <Col sm="3">
                    <img
                      src="icons8-notice.svg"
                      weigth="80"
                      height="80"
                      alt=""
                    />{" "}
                  </Col>{" "}
                  <Col sm="9">
                    <CardText></CardText>{" "}
                  </Col>
                </Row>
                <Button onClick={priornotice} color="primary">
                  Access
                </Button>
              </CardBody>
            </Card>
          </Col>{" "}
          <Col sm="12" md="3" lg="3">
            <Card className="card-bordered">
              <CardBody className="card-inner">
                <CardTitle tag="h5">History Requested Services</CardTitle>
                <Row>
                  <Col sm="3">
                    <img
                      src="icons8-documento-512.svg"
                      weigth="80"
                      height="80"
                      alt=""
                    />{" "}
                  </Col>{" "}
                  <Col sm="9">
                    <CardText></CardText>{" "}
                  </Col>
                </Row>
                <Button onClick={compliancelist} color="primary">
                  Go!
                </Button>
              </CardBody>
            </Card>
          </Col>{" "}
          <Col sm="12" md="3" lg="3">
            <Card className="card-bordered">
              <CardBody className="card-inner">
                <CardTitle tag="h5">New Services</CardTitle>
                <Row>
                  <Col sm="3">
                    <img
                      src="icons8-enviado.svg"
                      weigth="80"
                      height="80"
                      alt=""
                    />{" "}
                  </Col>{" "}
                  <Col sm="9">
                    <CardText></CardText>{" "}
                  </Col>
                </Row>
                <Button onClick={compliancenew} color="primary">
                  Go!
                </Button>
              </CardBody>
            </Card>
          </Col>{" "}
        </Row>
        <br></br>
        <Row>
          <Col sm="12" md="12" lg="12">
            {hidden.prionotice ? <PriorNotice></PriorNotice> : null}
          </Col>
          <Col sm="12" md="12" lg="12">
            {hidden.compliancelist ? <Compliance ></Compliance> : null}
          </Col>
          <Col sm="12" md="12" lg="12">
            {hidden.compliancenew ? (
              <Content>
                <ComplianceR sethidden={sethidden}></ComplianceR>
              </Content>
            ) : null}
          </Col>
          <Col sm="12" md="12" lg="12"></Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};
export default Principal;

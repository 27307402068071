export const GetUno = async (value) => {
  
    const id = value
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
     const response = await fetch(`https://apiecl.com/getuno?id=${id}`, requestOptions)
     const respuesta =  response.json()
     return respuesta;
    
      };
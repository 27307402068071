/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween,
  TooltipComponent,
} from "../../components/Component";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import DataTable from "react-data-table-component";
import { GetSku } from "../helpers/GetSku";
import { CardTitle } from "reactstrap";
import ModalProduct from "../../dashboard/components/modalproduct";
import ModalEditProduct from "./modaleditsku";
import ModalDocProduct from "./modaldocsku";

export default function Principal() {
  const { isAuthenticated, logout, user } = useAuth0();
  const [data, setdata] = useState([]);
  const [modaledit, setModaledit] = useState(false);
  const [modalTabedit, setModalTabedit] = useState("1");
  const [modal, setModal] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [modaldoc, setModaldoc] = useState(false);
  const [modalTabdoc, setModalTabdoc] = useState("2");
  const [sk, setsk] = useState();

  useEffect(() => {
    GetSku(user.sub).then((respo) => {
      setdata(respo);
    });
  }, [modaledit, user.sub, modaldoc]);

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button size="xs" color="primary" onClick={clickHandler}>
        <Icon name="edit" />
        <span>Edit</span>
      </Button>
    );
  };
  const ActionComponent1 = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button size="xs" color="warning" onClick={clickHandler}>
        <Icon name="file-docs" />
        <span>Files</span>
      </Button>
    );
  };

  const openmodal = (skus) => {
    setModaledit(true);
    setsk(skus);
  };

  const openmodaldoc = (skus) => {
    setModaldoc(true);
    setsk(skus);
   
  };

  
  const columnas = [
    {
      name: "SKU",
      selector: (row) => row.sku,
    },
    {
      name: "Description",
      grow: 2,
      selector: (row) => row.shortdescription,
    },
    {
      name: "Origin",
      selector: (row) => row.country_origin,
    },
    {
      name: "HTS",
      selector: (row) => (row.htsclas ? row.htsclas.hts8 : null),
    },

    // {
    //   name: "Date Creation",
    //   selector: (row) => row.fecha_creacion,
    // },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <span>
            {" "}
            <ActionComponent
              row={row.sku}
              onClick={openmodal}
            ></ActionComponent>
          </span>
        </>
      ),
    },
    {
      name: "",
      selector: (row) => (
        <>
             <span>
          {" "}
            <ActionComponent1
              row={row.sku}
              onClick={openmodaldoc}
            ></ActionComponent1>
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      <React.Fragment>
        <Head title="Dashboard"></Head>
        <Content>
          <BlockHead size="sm"></BlockHead>
          <br></br>
          <BlockHead size="sm">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Product Management
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to GSA Seller Accelerator</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent></BlockHeadContent>
          </BlockHead>
          <Block>
            <Row className="g-gs">
              <Col lg="12" md="12">
                <PreviewAltCard>
                  <div className="card-title-group align-start mb-2">
                    <CardTitle>
                      <h6 className="title text-primary">Sellers Products</h6>
                    </CardTitle>
                    <div className="card-tools text-secondary">
                      <TooltipComponent
                        icon="info"
                        direction="right"
                        id="tooltip_documents"
                        text="Registers, Passports, Power Attorney, Others"
                      />
                      &nbsp; &nbsp; &nbsp;
                      <span className="text-right">
                        <Button
                          onClick={() => setModal(true)}
                          size="sm"
                          color="primary"
                        >
                          <Icon name="tag" />
                          <span>New Product</span>
                        </Button>{" "}
                      </span>
                    </div>
                  </div>
                  <Content>
                    <DataTable
                      striped
                      pagination
                      columns={columnas}
                      data={data}
                    />
                  </Content>
                  <ModalProduct
                    modal={modal}
                    setModal={setModal}
                    modalTab={modalTab}
                    setModalTab={setModalTab}
                  ></ModalProduct>
                  <ModalEditProduct
                    modaledit={modaledit}
                    setModaledit={setModaledit}
                    modalTabedit={modalTabedit}
                    setModalTabedit={setModalTabedit}
                    sku={sk}
                    datas={data.filter((data) => data.sku === sk)}
                  ></ModalEditProduct>
                  <ModalDocProduct
                  modaldoc={modaldoc}
                  setModaldoc={setModaldoc}
                  modalTabdoc={modalTabdoc}
                  setModalTabdoc={setModalTabdoc}
                  sku={sk}
                  datas={data.filter((data) => data.sku === sk)}
                  >
                  </ModalDocProduct>
                </PreviewAltCard>
              </Col>
              <br></br>
              <Col lg="12">
                <PreviewAltCard className="h-100 border-secondary"></PreviewAltCard>
              </Col>{" "}
            </Row>
          </Block>
        </Content>
      </React.Fragment>
    </div>
  );
}

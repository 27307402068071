/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, FormGroup, ModalFooter, Collapse } from "reactstrap";
import {
  Button,
  Icon,
  Col,
  Row,
  RSelect,
  Block,
} from "../../components/Component";
import { codes } from "iso-country-codes";
import Swal from "sweetalert2";
import { currentTime, monthNames, todaysDate } from "../../utils/Utils";
import { useAuth0 } from "@auth0/auth0-react";
import Select from "react-select";
import { SavePickup } from "../../B2B/helpers/savepickup";
import Content from "../../layout/content/Content";
import { Spinner } from "reactstrap";
import { Badge } from "reactstrap";
import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";

import { TooltipComponent } from "../../components/Component";

import DocumentsGenerales from "./docgenerales";

// import { GetSku } from "../../skus/helpers/GetSku";
// import SaveSku from "../../dashboard/helpers/SaveSku";
//import Lister from "../../dashboard/components/SellerListFiles";
// import DataTable from "react-data-table-component";
import Iframe from "react-iframe";
// import { InvoicePreview } from "../helpers/getpreview";
import { InvoiceSigned } from "../helpers/getsign";
import { BajarFirmado } from "../helpers/bajarfirmado";

const ModalOrigen = ({
  modalorigen,
  setModalorigen,
  modalTaborigen,
  setModalTaborigen,
  seller,
  invoice
}) => {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  const [reporteorigen, setreporteorigen] = useState({
    id_reporte: "",
  });

  const fecha = new Date().toLocaleDateString();
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  const { isAuthenticated, logout, user } = useAuth0();
  const clave = `${seller ? seller.id_cliente : ""}/origen/${
    reporteorigen.id_reporte
  }`;

  const [categorias, setcategorias] = useState({
    ToysGames: seller ? seller["categorias"]["ToysGames"] : false,
    ChildrenApparels: seller ? seller["categorias"]["ChildrenApparels"] : false,
    Clothing: seller ? seller["categorias"]["Clothings"] : false,
    Sportswear: seller ? seller["categorias"]["Sportswear"] : false,
    Electronics: seller ? seller["categorias"]["Electronics"] : false,
    Furniture: seller ? seller["categorias"]["Furniture"] : false,
    Textiles: seller ? seller["categorias"]["Textiles"] : false,
    Pet: seller ? seller["categorias"]["Pet"] : false,
    HealthPersonalCare: seller
      ? seller["categorias"]["HealthPersonalCare"]
      : false,
    KitchenProducts: seller ? seller["categorias"]["KitchenProducts"] : false,
    FoodandGroceries: seller ? seller["categorias"]["FoodandGroceries"] : false,
  });

  const [invoices, setInvoices] = useState([]);
  const [info, setinfo] = useState(seller ? seller["categorias"]["info"] : " ");
  const [isChecked, setIsChecked] = useState({});

  useEffect(() => {
    setcategorias({
      ToysGames: seller ? seller["categorias"]["ToysGames"] : false,
      ChildrenApparels: seller
        ? seller["categorias"]["ChildrenApparels"]
        : false,
      Clothing: seller ? seller["categorias"]["Clothings"] : false,
      Sportswear: seller ? seller["categorias"]["Sportswear"] : false,
      Electronics: seller ? seller["categorias"]["Electronics"] : false,
      Furniture: seller ? seller["categorias"]["Furniture"] : false,
      Textiles: seller ? seller["categorias"]["Textiles"] : false,
      Pet: seller ? seller["categorias"]["Pet"] : false,
      HealthPersonalCare: seller
        ? seller["categorias"]["HealthPersonalCare"]
        : false,
      KitchenProducts: seller ? seller["categorias"]["KitchenProducts"] : false,
      FoodandGroceries: seller
        ? seller["categorias"]["FoodandGroceries"]
        : false,
    });
    setIsChecked({
      customCheck1: categorias.ToysGames,
      customCheck2: categorias.ChildrenApparels,
      customCheck3: categorias.Clothings,
      customCheck4: categorias.Sportswear,
      customCheck5: categorias.Electronics,
      customCheck6: categorias.Furniture,
      customCheck7: categorias.Textiles,
      customCheck8: categorias.Pet,
      customCheck9: categorias.HealthPersonalCare,
      customCheck10: categorias.KitchenProducts,
      customCheck11: categorias.FoodandGroceries,
    });
  }, [seller, modalorigen]);

  const exchange = (e) => {
    setcategorias({
      ...categorias,
      [e.target.name]: !isChecked[e.target.id],
    });

    setIsChecked({
      ...isChecked,
      [e.target.id]: !isChecked[e.target.id],
    });
  };
  const exchangeotros = (e) => {
    setcategorias({
      ...categorias,
      [e.target.name]: e.target.value,
    });
  };

  const finish = () => {
    Swal.fire({
      icon: "success",
      title: "You have finished",
      text: "Remember Sign the Disclaimer or your process will not start",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setModalorigen(false);
      }
    });
  };

  const [escondido, setescondido] = useState(false);

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );

  const [pickup, setpickup] = useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps

  //Aparece on no aparece agente de aduanas en origne
  const [aparece, setaparece] = useState(true);
  const [aparece_1, setaparece_1] = useState(true);

  const finishbook = () => {};
  const [controlSelect, setControlSelect] = useState({
    label: "Select Product",
    value: "someobject",
  });

  // const onChangeSelecte = (e) => {
  //   setSelected(e);
  //   setControlSelect({
  //     label: e.label,
  //     value: e.label,
  //   });
  // };

  const [isOpen, setIsOpen] = useState(false);
  //control para el iframe
  const [preview, setpreview] = useState("");
  const [spinner, setspinner] = useState(false);

  // const prev = () => {
  //   setspinner(true)
  //   setpreview(false)
  //   InvoicePreview(invoice).then((resp) =>{setpreview(resp); setspinner(false)})
  // };

  const [sign, setsign] = useState("");
  const [spinnersign, setspinnersign] = useState(false);

  const signature = () => {
    setspinnersign(true);
    setsign(false);
    InvoiceSigned(user.sub, user.email,invoice.invoice, invoice.codigo).then((resp) => {
      setsign(resp);
      setspinnersign(false);
      //CONTROLO LA FIRMA DEL DISCLAIMER DEL REPORTE GENERAL DE COMPLIANCE
      var pusher = new Pusher("bc3e56fd2d6638db854d", {
        cluster: "mt1",
      });
      var channel = pusher.subscribe("my-channel");
      channel.bind(resp.id_doc, function (e) {
        setModalorigen(false);
        Swal.fire({
          icon: "success",
          title: "Congratulations",
          text: "You have completed your request.",
        });
      });
      //CIERRE DEL CONTROL DE LA FIRMA
    });
  };

  const [linkbajar, setlinkbajar] = useState();
  const Bajar = () => {
    BajarFirmado(seller.id_general).then((resp) => setlinkbajar(resp));
  };
  return (
    <>
      <Modal
        isOpen={modalorigen}
        className="modal-dialog-centered"
        size="xl"
        toggle={() => setModalorigen(false)}
      >
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModalorigen(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>

          <div className="p-2">
            <h5 className="title">
              Product Origin Report {reporteorigen.id_reporte}
            </h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTaborigen === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTaborigen("1");
                  }}
                  href="#personal"
                >
                  1. General Information
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTaborigen === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTaborigen("2");
                  }}
                  href="#address"
                >
                  2. Bussiness Details
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTaborigen === "3" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTaborigen("3");
                  }}
                  href="#address"
                >
                  3. Documents to Upload
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTaborigen === "4" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTaborigen("4");
                  }}
                  href="#address"
                >
                  4. Disclaimer
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTaborigen === "1" ? "active" : ""}`}
              id="personal"
            >
              <Content>
                <Row className="gy-4">
                  <Col md="3">
                    <FormGroup>
                      <label className="form-label text-primary" htmlFor="pais">
                        Export Company{" "}
                      </label>
                      <br></br>
                      <ul className="text-muted">
                        <li>{seller ? seller.legalname : ""}</li>
                        <li>
                          {seller ? seller.calle : ""}{" "}
                          {seller ? seller.numero : ""}{" "}
                          {seller ? seller.barrio : ""}
                        </li>
                        <li>
                          {seller ? seller.ciudad : ""}{" "}
                          {seller ? seller.estado : ""}{" "}
                          {seller ? seller.pais : ""}
                        </li>
                        <li>
                          {seller ? seller.zip : ""}, Phone:{" "}
                          {seller ? seller.telefono : ""}
                        </li>
                        <li>Tax Id: {seller ? seller.tax_id : ""}</li>
                      </ul>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <FormGroup>
                      <label className="form-label text-primary" htmlFor="pais">
                        Import Country{" "}
                      </label>
                      <br></br>
                      <RSelect
                        options={countries}
                        placeholder="United States"
                        // onChange={SelectChange}
                        defaultValue="United States"
                        //   onChange={(e) =>
                        //     setEvento({
                        //       ...evento,
                        //       legaladdress: {
                        //         ...evento.legaladdress,
                        //         pais: e.value,
                        //       },
                        //     })
                        //   }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label text-primary" htmlFor="pais">
                        Product Origin Certification Report
                      </label>
                      <br></br>
                      <ul className="text-justify">
                        <li>
                          definition and application of the Rules of Origin to
                          products in order to support the application of Free
                          Trade Agreements.
                        </li>
                        <li></li>
                      </ul>
                    </FormGroup>
                  </Col>
                </Row>
              </Content>
            </div>
            <div
              className={`tab-pane ${modalTaborigen === "2" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="4">
                  <FormGroup>
                    <label className="form-label text-primary" htmlFor="pais">
                      Bussinnes Description
                    </label>
                    <textarea
                      type="text"
                      id="tipo"
                      className="form-control"
                      name="tipo"
                      defaultValue={
                        seller ? seller["categorias"]["info"] : null
                      }
                      // onChange={(e) =>
                      //   setbookingfinal({
                      //     ...bookingfinal,
                      //     instrucciones: e.target.value,
                      //   })
                      // }
                      placeholder="Bussines Information"
                    />
                  </FormGroup>{" "}
                </Col>
                <Col md={8}>
                  <label className="form-label text-primary" htmlFor="pais">
                    Products Categories
                  </label>
                  <Block>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck1"
                              name="ToysGames"
                              checked={isChecked.customCheck1}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Toys & Games
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck2"
                              name="ChildrenApparels"
                              checked={isChecked.customCheck2}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck2"
                            >
                              Children's Apparels
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck3"
                              name="Clothings"
                              checked={isChecked.customCheck3}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck3"
                            >
                              Clothings
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>{" "}
                    <br></br>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck4"
                              name="Sportswear"
                              checked={isChecked.customCheck4}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck4"
                            >
                              Sportswear
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck5"
                              name="Electronics"
                              checked={isChecked.customCheck5}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck5"
                            >
                              Electronics
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck6"
                              name="Furniture"
                              checked={isChecked.customCheck6}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck6"
                            >
                              Furniture
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck7"
                              name="Textiles"
                              checked={isChecked.customCheck7}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck7"
                            >
                              Home Textiles
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck8"
                              name="Pet"
                              checked={isChecked.customCheck8}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck8"
                            >
                              Pet Products
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck9"
                              name="HealthPersonalCare"
                              checked={isChecked.customCheck9}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck9"
                            >
                              Health & Personal Care
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck10"
                              name="KitchenProducts"
                              checked={isChecked.customCheck10}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck10"
                            >
                              Kitchen Products
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              id="customCheck11"
                              name="FoodandGroceries"
                              checked={isChecked.customCheck11}
                              onChange={exchange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck11"
                            >
                              Food and Groceries
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <label className="form-label" htmlFor="others">
                            Others Categories
                          </label>{" "}
                          <input
                            type="text"
                            className="form-control"
                            id="otros"
                            name="otros"
                            value={seller ? seller["categorias"]["otros"] : ""}
                            //onChange={exchangeotros}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Block>
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTaborigen === "3" ? "active" : ""}`}
              id="personal"
            >
              <DocumentsGenerales clave={clave} />
            </div>
            <div
              className={`tab-pane ${modalTaborigen === "4" ? "active" : ""}`}
              id="personal"
            >
              {seller ? (
                seller.generalsigned === true ? (
                  <>
                    <Content>
                      {" "}
                      <Button color="danger" onClick={Bajar}>
                        Download Document
                      </Button>
                      <Iframe url={linkbajar} height="0px" width="0%" />
                    </Content>
                  </>
                ) : (
                  <Row className="gy-4">
                    {" "}
                    <Col size="12">
                      <Button
                        className="btn-dim"
                        onClick={signature}
                        color="danger"
                      >
                        Signing Disclaimer
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        className="btn-dim"
                        onClick={finish}
                        color="success"
                      >
                        Finish Process
                      </Button>
                      <div className="text-center">
                        {" "}
                        <br></br>
                        {spinnersign ? <Spinner /> : null}
                        {sign ? (
                          <>
                            <Iframe
                              url={sign ? sign.link : ""}
                              height="450px"
                              width="100%"
                              id="myId"
                              className="myClassname"
                              display="initial"
                              position="relative"
                            />
                          </>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                )
              ) : null}
              <br></br>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="bg-light">
          <img
            width="120"
            height="120"
            src="amazon-service_partners-badge (1).png"
            alt=""
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalOrigen;

/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, Component } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Col, FormGroup, Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import DataTable from "react-data-table-component";
import {
  Block,
  BlockContent,
  BlockHeadContent,
  PreviewAltCard,
  PreviewCard,
  Icon,
} from "../../../components/Component";
import { Spinner } from "reactstrap";
import { RSelect } from "../../../components/Component";
import { codes } from "iso-country-codes";
import { Row, Card, Button } from "reactstrap";
import Swal from "sweetalert2";
import { GetCnee } from "../helpers/getcnee";
import { useAuth0 } from "@auth0/auth0-react";
import { GetRateMx } from "../helpers/getratemx";
import { SaveOrders } from "../helpers/saveorders";
import ModalCnee from "./modalcnee";
import Select from "react-select";
import { GetUno } from "../helpers/getuno";
import { SavePickup } from "../helpers/savepickup";
import { GetPickups } from "../helpers/getpickup";
import GetZipMx from "../../../MxCalculadoraRapida/helpers/GetZipMx";
import ModalPickup from "./modalpickup";
import Coyote from "../helpers/coyote";

const CalculosMx = () => {
  const { user } = useAuth0();
  // crea un nuevo objeto `Date`
  var today = new Date();
  const [modal, setModal] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [modalp, setModalp] = useState(false);
  const [modalTabp, setModalTabp] = useState("1");
  const [update, setupdate] = useState({
    cliente: user.sub,
    fob: "",
    cnee: "",
    wh: "",
    ref: "",
    qty: "",
    seguro: "",
    totalflete: "",
    handling: "",
    admin: 0,
    lastmile: "",
    booked: false,
    pay1: false,
    status: "Unpaid",
    ior: "",
    custom: "",
    pickup: "",
    kg: "",
    whin: "",
    services: "LTL",
    date: today.toLocaleDateString("en-US"),
    duties: 0.0,
    dutiesstatus: "Not Info",
    othercost: 0.0,
    othercoststatus: "Not Info",
    picksel: ""
  });

  const formClass = classNames({
    "form-validate": true,
  });

  //Esconder Boton
  const [escondido, setescondido] = useState(true);
  var cnne = [];
  useEffect(() => {
    GetCnee(user.sub).then((response) =>
      response.forEach((element) =>
        cnne.push({value: element._id.$oid, label: element.legalname + ' ' + element.zip + ' ' + element.estado + ' ' + element.pais})
      )
    );
  }, [cnne, user.sub]);

  const [picki, setpicki] = useState([]);
  const [totalpicki, settotalpicki] = useState([]);
  const [selectpickup, setselectpickup] = useState();
  const [pickup, setpickup] = useState();


  useEffect(() => {
    var pick = [];
    GetPickups(user.sub).then((response) => {
      settotalpicki(response);
      response.pickup.forEach((element) => {
        pick.push({ value: element.zip, label: element.numero + ' ' + element.calle + ' ' + element.ciudad  + ' ' + element.estado + ' ' + element.pais + ' ' + element.zip});
      });
    });
    setpicki(pick);
  }, [user.sub, modalp, setModalp]);

  const selectpick = (e) => {
    GetZipMx(e.value).then((result) => {
      if (result !== null) {
        setupdate({
          ...update,
          pickuprate: 0.0,
          checkpick: true,
          picksel: totalpicki.pickup.filter((resp) => resp.zip === e.value),
        });
      } else {
        setupdate({
          ...update,
          pickuprate: 150.0,
          checkpick: false,
          picksel: totalpicki.pickup.filter((resp) => resp.zip === e.value),
        });
      }
    });
  };

  const newJson1 = [
    {
      value: "cdmx",
      label: "CDMX C.P. 02300",
    },
    {
      value: "gdl",
      label: "JALISCO C.P. 45679",
    },
    {
      value: "mty",
      label: "NUEVO LEON C.P. 66628",
    },
  ];

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );

  const exchange = (e) => {
    setupdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };

  const changeior = (e) => {
    GetUno(e.value).then((result) =>
      setupdate({
        ...update,
        ior: result,
      })
    );
  };

  const changecnee = (e) => {
    GetUno(e.value).then((result) =>
      setupdate({
        ...update,
        cnee: result,
      })
    );
  };

  const changewh = (e) => {
    setupdate({
      ...update,
      wh: e.value,
      whin: e.label,
    });
  };

  const handlefobChange = (e) => {
    setupdate({
      ...update,
      fob: parseInt(e.target.value),
    });
  };

  const [listaeventos, setlistaeventos] = useState({
    listaeventos: [],
  });
  const [eventos, seteventos] = useState({
    id_evento: "",
    kg: "",
    length: "",
    width: "",
    height: "",
  });
  const { errors, register, handleSubmit } = useForm();

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button size="sm" color="light" onClick={clickHandler}>
        <img
          src="icons8-delete-67.png"
          alt="edit"
          width="20"
          height="20"
        />
      </Button>
    );
  };

  const deleterow = (e) => {
   
    const newstate = listaeventos.listaeventos.filter(
      (item) => item.id_evento !== e
    );
    setlistaeventos({ listaeventos: newstate });
  };

  const columns = [
    {
      name: "Pallet Nro.",
      selector: (row) => `${row.id_evento}`,
      cell: (row) => `${row.id_evento}`,
    },
    {
      name: "Weight Pallet (Kg)",
      selector: (row) => `${row.kg}`,
      cell: (row) => `${row.kg}`,
    },
    {
      name: "Length (cm)",
      selector: (row) => `${row.length}`,
      cell: (row) => `${row.length}`,
    },
    {
      name: "Width (cm)",
      selector: (row) => `${row.width}`,
      cell: (row) => `${row.width}`,
    },
    {
      name: "Height (cm)",
      selector: (row) => `${row.height}`,
      cell: (row) => `${row.height}`,
    },
    {
      name: "Actions",
      center: true,
     
      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row) => (
        <ActionComponent
          row={row.id_evento}
          onClick={deleterow}
        ></ActionComponent>
      ),
    },
  ];

  let total = 0;
  listaeventos.listaeventos.forEach(function (a) {
    total += Number(a.kg);
  });

  const onFormSubmit = (e) => {
    if (
      eventos.id_evento === "" ||
      eventos.kg === "" ||
      eventos.length === "" ||
      eventos.width === "" ||
      eventos.height === ""

    ) {
      alert("Please Input Information");
    } else if (eventos.kg > 400) {
      alert("maximum pallet weight should be 400Kg");
    } else {
      setlistaeventos({
        listaeventos: [...listaeventos.listaeventos, eventos],
      });
      setupdate({
        ...update,
        qty: listaeventos.listaeventos.length,
        pallets: listaeventos.listaeventos,
      });
      seteventos({ id_evento: "", kg: "", length: "", width: "", height: "" });
    }
  };

  const handleChange = (e) => {
    const idelemento = listaeventos.listaeventos.length + 1;
    seteventos({
      ...eventos,
      [e.target.name]: e.target.value,
      id_evento: idelemento,
    });
  };

  const calcular = () => {
    if (
      update.cnee === "" ||
      listaeventos.listaeventos.length > 12 ||
      listaeventos.listaeventos.length === 0 ||
      listaeventos.listaeventos.length === "" ||
      update.wh === "" ||
      update.fob === "" ||
      update.ior === [] ||
      update.ior === "" ||
      update.ref === "" ||
      update.cnee === "" ||
      update.cnee === [] ||
      update.picksel === "" ||
      update.picksel === []
    ) {
      Swal.fire({
        title: "oops!",
        text: "Fill in all Information; or Input Max 12 Pallets !!!",
        icon: "warning",
      });
    } else {
      let totalporembarque = 6.50 * update.qty;
      if (totalporembarque < 40) {
        totalporembarque = 40;
      } else {
        totalporembarque = 6.5 * update.qty;
      }
      let totalseguro = update.fob * 0.005;
      if (totalseguro < 55) {
        totalseguro = 55;
      } else {
        totalseguro = update.fob * 0.005;
      }
     
      Coyote(update,listaeventos).then((result) => {
        setupdate({
          ...update,
          seguro: totalseguro*1.06,
          handling: (totalporembarque * 2)*1.36 + 20*1.36 + 15*1.36,
          totalflete: result * 1.36,
          custom: 175.0*1.06,
          admin: 60.0*1.06,
          qty: listaeventos.listaeventos.length,
          kg: total,
          pallets: listaeventos.listaeventos,
        });
      });
      setescondido(false);
    }
  };

  const grabar = () => {
    SaveOrders(update).then(() => {
      Swal.fire({
        title: "You quotation was saved",
        confirmButtonText: "OK",
        icon: "success",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setupdate({
            cliente: user.sub,
            fob: "",
            cnee: "",
            wh: "",
            ref: "",
            qty: "",
            seguro: "",
            totalflete: "",
            handling: "",
            admin: "",
            lastmile: "",
            kg: "",
            whin: "",
            date: "",
          });
          location.replace("/b2bnew");
          setescondido(true);
        }
      });
    });
  };

  return (
    <React.Fragment>
      <Head title="B2B GSA"></Head>
      <Content>
        <Block>
          <Card className="card-custom-s1 card-bordered">
            <Row className="no-gutters">
              <Col lg="4">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <h5 className="text-primary">B2B Logistics Request</h5>
                    <p className="text-primary">
                      Only few minutes required to complete your Request.
                    </p>
                  </div>
                  <div className="card-inner">
                    <ul className="list list-step">
                      <li className="list-step-done">Insert Your Reference</li>
                      <li className="list-step-done">
                        Insert You Total FOB Value
                      </li>
                      <li className="list-step-done">Select Consignee</li>
                      <li className="list-step-done">Insert Total Value FOB</li>
                      <li className="list-step-done">
                        Select Warehouse to Consolidate
                      </li>
                      <li className="list-step-done">
                        Insert Pallets Qty and Total KG
                      </li>
                      <li className="list-step-done">GO !</li>
                    </ul>
                  </div>
                  <div className="card-inner">
                    <Row className="g-gs">
                      <Col lg="12">
                        <PreviewCard className="h-100 text-center">
                          <FormGroup className="form-group">
                            {escondido ? (
                              <Button onClick={calcular}>
                                <Spinner color="danger" size="sm" type="grow" />
                                <span>GET QUOTATION</span>
                              </Button>
                            ) :
                            update.totalflete ?  (
                              <Button onClick={grabar} color="danger">
                                <Spinner size="sm" type="grow" />
                                <span>GO! and Save</span>
                              </Button>
                            ): <Spinner color="primary" /> 
                            }
                            </FormGroup>
                        </PreviewCard>
                      </Col>
                    </Row>
                    <PreviewAltCard>
                      <BlockContent>
                        <BlockHeadContent className="px-lg-12">
                          <h6>Rates Summary</h6>
                          <Row>
                            <Col lg="12">
                              <table className="table table-striped is-compact table-tranx ">
                                <tbody>
                                  <tr>
                                    <td className="text-primary">
                                      Rate MX to Destination
                                    </td>
                                    <td className="text-primary text-right">
                                    {escondido ? null : 
                                      update.totalflete
                                        ? new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          }).format(
                                            update.totalflete +
                                              update.handling +
                                              update.custom +
                                              update.admin +
                                              update.pickuprate
                                              
                                          )
                                        : <Spinner color="primary" />
                                         }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-primary">Insurance</td>
                                    <td className="text-info text-right">
                                      {update.seguro
                                        ? formatter.format(update.seguro)
                                        : null}
                                        
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="w-bold">
                                      <span className="text-primary">
                                        {" "}
                                        <strong>Total Shipping Cost </strong>
                                      </span>
                                    </td>
                                    <td className="text-right fw-bold">
                                      <span className="text-primary">
                                        {update.totalflete
                                          ? formatter.format(
                                            update.totalflete +
                                            update.handling +
                                            update.custom +
                                            update.admin +
                                            update.pickuprate +
                                            update.seguro 

                                                
                                            )
                                          : null}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </BlockHeadContent>
                      </BlockContent>
                    </PreviewAltCard>
                    <div className="align-center gx-3">
                      <Col lg="12">
                        <br></br>
                        <table className="table is-compact table-tranx">
                          <tbody>
                            <tr>
                              <td>
                                Note:
                                <ul>
                                  <li>
                                    {" "}
                                    - Rate not included Taxes and other Over
                                    Cost
                                  </li>
                                  <li> - Rate Include Customs Brokers.</li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br></br>
                      </Col>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <div className="card-inner card-inner-lg h-100">
                  <BlockContent>
                    <BlockHeadContent>
                      <h5 className="text-primary">B2B Platform</h5>
                    </BlockHeadContent>
                    <label
                      className="form-label text-primary"
                      htmlFor="fdaregistration"
                    >
                      Select and Complete Your Preferences *
                    </label>
                    <Col lg="12">
                      <br></br>
                      <FormGroup className="form-group">
                        <Row>
                          <Col lg="3">
                            <label className="form-label" htmlFor="country">
                              Date
                            </label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="text"
                                id="ref"
                                name="ref"
                                placeholder="Reference"
                                value={update.date}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <label className="form-label" htmlFor="country">
                              Your Reference *
                            </label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="text"
                                id="ref"
                                name="ref"
                                placeholder="Reference"
                                onChange={exchange}
                                value={update.ref}
                              />
                            </div>
                          </Col>
                          <Col lg="3">
                            <label className="form-label" htmlFor="country">
                              FOB Value *
                            </label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="number"
                                id="fob"
                                name="fob"
                                placeholder="FOB VALUE"
                                value={update.fob}
                                onChange={handlefobChange}
                              />
                            </div>
                          </Col>{" "}
                          <Col lg="3">
                            <FormGroup className="form-group">
                              <label
                                className="form-label"
                                htmlFor="email-address"
                              >
                                Warehouse MX *
                              </label>
                              <RSelect
                                options={newJson1}
                                id="wh"
                                name="wh"
                                onChange={changewh}
                                placeholder={update ? update.whin : null}
                                defaultValue={update ? update.whin : null}
                              />
                            </FormGroup>
                          </Col>{" "}
                        </Row>
                      </FormGroup>
                      <FormGroup className="form-group">
                        <Row>
                          <Col lg="4">
                            <label className="form-label" htmlFor="country">
                              Select Import (FIOR/IOR) *
                            </label>
                            <div className="form-control-wrap">
                              {" "}
                              <Select
                                options={cnne}
                                id="ior"
                                name="ior"
                                onChange={changeior}
                                //ref={selectInputRef}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <label className="form-label" htmlFor="country">
                              Select Cnee (Buyer) *
                            </label>
                            <div className="form-control-wrap">
                              {" "}
                              <Select
                                options={cnne}
                                id="cnee"
                                name="cnee"
                                onChange={changecnee}
                                //ref={selectInputRef}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <label className="form-label" htmlFor="country">
                              {"       "}
                              <br></br>
                            </label>
                            <div className="form-control-wrap">
                              <Button
                                onClick={() => setModal(true)}
                                color="secondary"
                              >
                                <Icon name="arrow-left"></Icon>
                                <span>Create New Customer</span>
                              </Button>
                            </div>
                          </Col>
                          <ModalCnee
                            modal={modal}
                            setModal={setModal}
                            modalTab={modalTab}
                            setModalTab={setModalTab}
                          ></ModalCnee>
                        </Row>
                      </FormGroup>
                      <FormGroup className="form-group">
                        <Row className="gy-4">
                          <Col lg="8">
                            <label className="form-label" htmlFor="country">
                              Select Pickup Address *
                            </label>
                            <div className="form-control-wrap">
                              {" "}
                              <Select
                                options={picki}
                                id="pickup"
                                name="pickup"
                                onChange={selectpick}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <label className="form-label" htmlFor="country">
                              {"       "}
                              <br></br>
                            </label>
                            <div className="form-control-wrap">
                              <Button
                                onClick={() => setModalp(true)}
                                color="secondary"
                              >
                                <Icon name="arrow-left"></Icon>
                                <span>New Pickup Address</span>
                              </Button>
                            </div>
                          </Col>
                          <ModalPickup
                           modalp={modalp}
                           setModalp={setModalp}
                           modalTabp={modalTabp}
                           setModalTabp={setModalTabp}
                           picki = {picki} 
                           setpicki = {setpicki}
                          ></ModalPickup>
                        </Row>
                      </FormGroup>
                      <h6 className="text-primary">Pallets to Shipping</h6>
                      <Row>
                        <Col lg="4">
                          <label className="form-label" htmlFor="qty">
                            Total Pallets
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            id="qty"
                            name="qty"
                            value={listaeventos.listaeventos.length}
                            disabled
                          />{" "}
                        </Col>
                        <Col lg="4">
                          <label className="form-label" htmlFor="qty">
                            Total Shipment Weight (Kg)
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            id="kg_total"
                            name="kg_total"
                            value={total}
                            disabled
                          />{" "}
                        </Col>
                      </Row>
                      <hr></hr>
                      <Form
                        className={formClass}
                        onSubmit={handleSubmit(onFormSubmit)}
                      >
                        <Row className="g-gs">
                          <Col lg="3">
                            <label className="form-label" htmlFor="qty">
                              Pallet Weight (Kg)
                            </label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="number"
                                id="kg"
                                name="kg"
                                value={eventos.kg}
                                onChange={handleChange}
                              />
                            </div>
                          </Col>
                          <Col lg="2">
                            <label className="form-label" htmlFor="qty">
                              Length (cm)
                            </label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="number"
                                id="length"
                                name="length"
                                value={eventos.length}
                                onChange={handleChange}
                              />
                            </div>
                          </Col>{" "}
                          <Col lg="2">
                            <label className="form-label" htmlFor="qty">
                              Width (cm)
                            </label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="number"
                                id="width"
                                name="width"
                                value={eventos.width}
                                onChange={handleChange}
                              />
                            </div>
                          </Col>{" "}
                          <Col lg="2">
                            <label className="form-label" htmlFor="qty">
                              Height (cm)
                            </label>
                            <div className="form-control-wrap">
                              <input
                                className="form-control"
                                type="number"
                                id="height"
                                name="height"
                                value={eventos.height}
                                onChange={handleChange}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <br></br>
                              <Button color="secondary" size="md">
                                <Icon name="arrow-left"></Icon>
                                <Spinner size="sm" type="grow" />
                                <span>Add Pallet</span>
                              </Button>
                            </FormGroup>
                          </Col>
                          <DataTable
                            dense
                            striped
                            columns={columns}
                            data={listaeventos.listaeventos}
                          />
                        </Row>
                      </Form>
                    </Col>
                  </BlockContent>
                </div>
              </Col>
            </Row>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CalculosMx;


import { useState, useEffect } from 'react';
import Getprices from '../helpers/getprices';


const Usegetprecios = codigo => {
	const [state, setState] = useState({
		price: []
	});

	useEffect(() => {
		Getprices(codigo).then(imgs => {
			setState({
				price: imgs
			});
		});
	}, [codigo]);

	return state;
};

export default Usegetprecios;
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import { Button, Icon, Col, Row, RSelect } from "../../../components/Component";
import { codes } from "iso-country-codes";
import Swal from "sweetalert2";
import { useAuth0 } from "@auth0/auth0-react";
import { SavePickup } from "../helpers/savepickup";
const ModalPickup = ({
  modalp,
  setModalp,
  modalTabp,
  setModalTabp,
  picki,
  setpicki,
}) => {
  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  const { user } = useAuth0();
  const [pickup, setpickup] = useState();
  const exchange = (e) => {
    setpickup({
      ...pickup,
      [e.target.name]: e.target.value,
    });
  };
  const changepais = (e) => {
    setpickup({
      ...pickup,
      pais: e.value,
    });
  };

  const spickup = () => {
    SavePickup(pickup, user.sub).then((response) => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Pickup address was save successfully",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        setModalp(false);
        // setpicki([
        //   ...picki,
        //   {
        //     value: pickup.zip,
        //     label:
        //       pickup.numero +
        //       " " +
        //       pickup.calle +
        //       " " +
        //       pickup.ciudad +
        //       " " +
        //       pickup.estado +
        //       " " +
        //       pickup.pais +
        //       " " +
        //       pickup.zip,
        //   },
        // ]);
      });
    });
  };

  return (
    <Modal
      isOpen={modalp}
      className="modal-dialog-centered"
      size="lg"
      toggle={() => setModalp(false)}
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            setModalp(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Seller Pickup Address</h5>
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTabp === "1" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTabp("1");
                }}
                href="#personal"
              >
                Puckup Address Information
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTabp === "1" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="12">
                  {" "}
                  <Row className="gy-4">
                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="numero"
                        >
                          Number
                        </label>
                        <input
                          type="text"
                          id="numero"
                          name="numero"
                          onChange={exchange}
                          // //  defaultValue={update ? update.numero : null}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="calle"
                        >
                          Street
                        </label>
                        <input
                          type="text"
                          id="calle"
                          name="calle"
                          onChange={exchange}
                          // //  defaultValue={update ? update.calle : null}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="barrio"
                        >
                          District
                        </label>
                        <input
                          type="text"
                          id="barrio"
                          name="barrio"
                          onChange={exchange}
                          //    defaultValue={update ? update.barrio : null}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="gy-4">
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="ciudad"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          id="ciudad"
                          name="ciudad"
                          onChange={exchange}
                          //    defaultValue={update ? update.ciudad : null}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="estado"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          id="estado"
                          name="estado"
                          onChange={exchange}
                          //    defaultValue={update ? update.estado : null}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="pais"
                        >
                          Country
                        </label>
                        <RSelect
                          options={countries}
                          //placeholder={update ? update.pais : null}
                          //defaultValue={update ? update.pais : null}
                          onChange={changepais}
                          name="pais"
                          //   //   defaultValue={evento.legaladdress.pais}
                          //   onChange={(e) =>
                          //     setEvento({
                          //       ...evento,
                          //       legaladdress: {
                          //         ...evento.legaladdress,
                          //         pais: e.value,
                          //       },
                          //     })
                          //   }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="zip"
                        >
                          Zip Code
                        </label>
                        <input
                          type="text"
                          id="zip"
                          name="zip"
                          onChange={exchange}
                          //    defaultValue={update ? update.zip : null}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="gy-4">
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="zip"
                        >
                          Pickup Contact Name
                        </label>
                        <input
                          type="text"
                          id="contactname"
                          name="contactname"
                          onChange={exchange}
                          //    defaultValue={update ? update.zip : null}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="zip"
                        >
                          Phone
                        </label>
                        <input
                          type="text"
                          id="telefono"
                          name="telefono"
                          onChange={exchange}
                          //    defaultValue={update ? update.zip : null}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="zip"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          onChange={exchange}
                          //    defaultValue={update ? update.zip : null}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <label
                        className="form-label text-primary"
                        htmlFor="country"
                      >
                        {"       "}
                        <br></br>
                      </label>
                      <div className="form-control-wrap">
                        <Button onClick={() => spickup()} color="secondary">
                          Save Address
                        </Button>
                      </div>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalPickup;

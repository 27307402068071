/* eslint-disable no-unused-vars */
import { useState } from "react";
import {
	Block,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Icon,
	Button,
	Row,
	Col,
	PreviewAltCard,
  } from "../../components/Component";
import DataTable from "react-data-table-component";
import HtsDialogEnd from './HtsDialogEnd';


export default function HtsGetListHts({
  htschino,
  eventos,
  categorias,
  encabezado,
}) {
  const [value, setValue] = useState(null);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("Choose wisely");
  const [descripcion, setDescription] = useState("");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setHelperText(" ");
    setError(false);
    eventos.forEach((htsCode) => {
      if (htsCode.htsno === event.target.value) {
        setDescription(htsCode);
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const ActionComponent = ({
    htsno,
    onClick,
  }) => {
    const clickHandler = () =>
      onClick(htsno);
    return <Button color="warning" onClick={clickHandler}>Select</Button>;
  };

  const finaldialogo = (event) => {

      setValue(event)

  }


  const columnas = [
    {
      name: "HTS Number",
      selector: (row) => row.htsno,
    },
    {
      name: "Actions",
      cell: (row) => (
        <ActionComponent
          htsno={row.htsno}
          onClick={finaldialogo}
        ></ActionComponent>
      ),
    },
   
  ];

  return (

    <form onSubmit={handleSubmit}>
     
     {  value ? null : <DataTable columns={columnas} data={eventos} striped  />}  
     
      {/* <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
					{eventos.map(img => (
						<FormControlLabel key={img.htsno} value={eventos.map(img => ( control={<Radio />} label={img.htsno} />
					))}
				</RadioGroup>
				<br />
				<FormHelperText>{helperText}</FormHelperText> <br /> */}
       {  value && (
					<HtsDialogEnd
						evento1={value}
						evento2={categorias}
						evento3={descripcion.description}
						evento4={encabezado}
						evento5={htschino}
					/>
				) } 
      {/* value && <HtsDialogFix evento1={value} evento2={categorias} evento3={descripcion.description} evento4={encabezado} /> */}
    </form>
  );
}

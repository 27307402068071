export const GetCnee = async (cliente) => {
  

var requestOptions = {
  method: 'GET',
  redirect: 'follow'
};

 const response = await fetch(`https://apiecl.com/getbuyer?id_seller=${cliente}`, requestOptions)
 const respuesta =  response.json()
 return respuesta;

  };

 
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween,
} from "../../components/Component";
//import { useAuth0 } from "@auth0/auth0-react";
//import ListHtsTools from "./ListHtsTools";
//import ListHtsTable from "./ListHtsTable";
import {useTranslation} from "react-i18next";
import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";
import Iframe from "react-iframe";
import ResponsiveEmbed from "react-responsive-embed";
export default function IorSpec() {
  //const { isAuthenticated, logout, user } = useAuth0();
  const {t} = useTranslation('registro');
  return (
    <React.Fragment>
      <Content>
        <Head title="Compliance Robot"></Head>

        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
              {t('titulo')} 
              </BlockTitle>
              <BlockDes className="text-soft">
              {t('subtitulo')} 
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col lg="12">
              {" "}

              <PreviewAltCard>
                <Card className="card-bordered">
                  <CardBody className="card-inner">
                    <CardTitle className="text-primary" tag="h6">
                      {t('Informacion_General')} 
                    </CardTitle>
                    <CardText>
                    {t('bajada_info_general')} 
                      
                      <li>
                      {t('info_genera_a')} 
                      </li>
                      <li>
                      {t('info_genera_b')} 
                      </li>
                    </CardText>
                  </CardBody>
                </Card>
                <br></br>

                <Card className="card-bordered">
                  <CardBody className="card-inner">
                    <CardTitle tag="h6" className="text-primary">
                    {t('condiciones')} 
                    </CardTitle>
                    <CardText>
                    {t('condiciones_a')} 
                    </CardText>
                  </CardBody>
                </Card>
                <br></br>

                <Card className="card-bordered">
                  <CardBody className="card-inner">
                    <CardTitle tag="h6" className="text-primary">
                    {t('formas')} 
                    </CardTitle>
                    <CardText>
                      <span className="text-mute">
                        <li>
                        {t('formas_a')} 
                        </li>
                        <li>
                        {t('formas_b')} 
                        </li>
                        <br></br>
                        <li>
                        {t('formas_c')} 
                        </li>
                        <li>
                        {t('formas_d')} 
                        </li>
                      </span>
                    </CardText>
                  </CardBody>
                </Card>
                <br></br>
                <Card className="card-bordered">
                  <CardBody className="card-inner">
                    <CardTitle tag="h6" className="text-primary">
                     Enlaces de referencia:
                    </CardTitle>
                    <CardText>
                      <span className="text-mute">
                        
                        <li>
                        <a href="https://cbpcomplaints.cbp.gov/s/article/Article-215?language=en_US&name=Article-215#:~:text=En%20general%2C%20una%20entrada%20informal,tanto%20importaciones%20personales%20como%20comerciales" _blank>CBP Compliants</a>
                        </li>
                        <li>
                          {" "}
                          <a href=" https://www.cbp.gov/sites/default/files/documents/3530-002a_3.pdf" _blank>CBP Documents Info</a>
                         
                        </li>
                        <li>
                          {" "}
                          <a href="https://www.cbp.gov/trade/basic-import-export/importer-exporter-tips" _blank>CBP General Info</a>
                       
                        </li>
                      </span>
                    </CardText>
                  </CardBody>
                </Card>
              </PreviewAltCard>{" "}
              {/* <br></br>
                    <Button
                      color="primary"
                      onClick={() =>
                        sethidden({ dashboard: false, categories: true })
                      }
                    >
                      Comenzar
                    </Button> */}
            </Col>{" "}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
}

/* eslint-disable no-unused-vars */
import React, {useState} from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockImage,
  BlockTitle,
  PreviewAltCard,
} from "../../../components/Component";
import { codes } from "iso-country-codes";
import {
  Row,
  Col,
  FormGroup,
  Card,
  Button,
} from "reactstrap";


const MxTipoTRansporte = ({hidden,sethidden}) => {
  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );

  const [tipo, settipo] = useState()


  const optionchange = (e) => {

    settipo(e.target.value)

  }

  const next = () => {
    
    if (tipo === 'ltl') { 
         sethidden({
           ...hidden,
           ltl:true,
           tipo: false
         })

    }
  }
  
  return (
    <React.Fragment>
      <Head title="Welcome"></Head>
      <Content size="lg">
       
        <Block>
          <Card className="card-custom-s1 card-bordered">
            <Row className="no-gutters">
              <Col lg="4">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <h5>Quickly Logistics Calculator</h5>
                    <br></br>Only few minutes required to complete your quotation.
                  </div>
                  <div className="card-inner">
                    <ul className="list list-step">
                    <li className="list-step-done">Start your Quotation</li>
                      <li className="list-step-done">Select Origin</li>
                     
                      <li>Select Your Shipping Services</li>
                    
                    </ul>
                  </div>
                  <div className="card-inner">
                    <div className="align-center gx-3">
                      <div className="flex-item">
                        <div className="progress progress-sm progress-pill w-80px">
                          <div
                            className="progress-bar"
                            style={{ width: "50%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="flex-item">
                        <span className="sub-text sub-text-sm text-soft">
                          2/4 Completed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <div className="card-inner card-inner-lg h-100">
                  <div className="align-center flex-wrap flex-md-nowrap g-3 h-100">
                    <div className="block-image w-200px flex-shrink-0 order-first order-md-last">
                      <img
                        src="icons8-calculadora-500.svg"
                        alt="calculator"
                      ></img>
                    </div>
                    <BlockContent>
                      <BlockHeadContent>
                        <h4>Select Your Shipping Services</h4>
                      </BlockHeadContent>
                      <ul>
                        <li>
                          <Row className="g-4">
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-label"
                                  htmlFor="fdaregistration"
                                >
                                  Select Packaging to Export *
                                </label>
                                <ul className="custom-control-group g-3 align-center flex-wrap">
                                  <li>
                                    <div className="custom-control custom-radio">
                                      <input
                                        type="radio"
                                        className="custom-control-input form-control"
                                       
                                        name="purpose"
                                        id="purpose1"
                                        value="ltl"
                                        onChange={optionchange}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="purpose1"
                                      >
                                        LTL Services by Pallet (Max 12 Pallets)
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="custom-control custom-radio">
                                      <input
                                        type="radio"
                                        className="custom-control-input form-control"
                                        name="purpose"
                                        id="purpose2"
                                        value="Analysis"
                                        disabled="disabled"
                                        //onChange={optionchange}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="purpose2"
                                      >
                                        Full Truck by Pallets (Full Truck Services 13 to 22
                                        Pallets)
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </FormGroup>
                            </Col>
                          </Row>
                          <br></br>
                        </li>
                        <li>
                          {" "}
                          <Button onClick={next} color="primary">
                            Next Step
                          </Button>
                        </li>
                      </ul>
                    </BlockContent>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Block>

     
      </Content>
    </React.Fragment>
  );
};

export default MxTipoTRansporte;

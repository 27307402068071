/* eslint-disable no-unused-vars */
import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Button, Card, Col, Row } from "reactstrap";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockImage,
  BlockTitle,
  PreviewAltCard,
} from "../../../components/Component";
import { RSelect } from "../../../components/Component";
import { codes } from "iso-country-codes";

const Welcome = ({ hidden, sethidden }) => {
  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.alpha2, label: element.name })
  );

  const next = () => {
    sethidden({
      ...hidden,
      welcome: false,
      tipo: true,
    });
  };
  return (
    <React.Fragment>
      <Head title="Welcome"></Head>
      <Content size="lg">
        <Block>
          <Card className="card-custom-s1 card-bordered">
            <Row className="no-gutters">
              <Col lg="4">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <h5>Delivery FBA Inventory</h5>
                    <br></br>
                    <span className="text-primary">
                      Only few minutes required to complete your quotation.
                    </span>
                  </div>
                  <div className="card-inner">
                    <ul className="list list-step">
                      <li className="list-step-done">Start your Quotation</li>
                      <li className="list-step-current">Select Origin</li>
                    </ul>
                  </div>
                  <div className="card-inner">
                    <div className="align-center gx-3">
                      <div className="flex-item">
                        <div className="progress progress-sm progress-pill w-80px">
                          <div
                            className="progress-bar"
                            style={{ width: "25%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="flex-item">
                        <span className="sub-text sub-text-sm text-soft">
                          1/4 Completed
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <div className="card-inner card-inner-lg h-100">
                  <div className="align-center flex-wrap flex-md-nowrap g-3 h-100">
                    <div className="block-image w-200px flex-shrink-0 order-first order-md-last">
                      <img
                        src="icons8-calculadora-500.svg"
                        alt="calculator"
                      ></img>
                    </div>
                    <BlockContent>
                      <BlockHeadContent>
                        <h4>Select your origin country</h4>
                      </BlockHeadContent>
                      <br></br>
                      Only 5 Minutes to Obtain Rates
                      <ul>
                        <li>
                          <RSelect
                            options={countries}
                            id="shcountry"
                            name="shcountry"
                            placeholder="Mexico"
                            isDisabled={true}
                            // onChange={(selectedOption) =>
                            //   formik.setFieldValue("shcountry", selectedOption.value)
                            // }
                            // defaultValue={formik.values.shcountry}
                            defaultValue="MX"
                          />
                          <br></br>
                        </li>
                        <li>
                          {" "}
                          <Button onClick={next} color="primary">
                            Get Started
                          </Button>
                        </li>
                      </ul>
                    </BlockContent>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Welcome;

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import { Button, Icon, Col, Row, RSelect } from "../../components/Component";
import { codes } from "iso-country-codes";
//import SellerFiles from "./SellerFiles";
import { useAuth0 } from "@auth0/auth0-react";
import Swal from "sweetalert2";
import SaveSku from "../helpers/SaveSku";
import DocumentsGenerales from "./docgenerales";
import Content from "../../layout/content/Content";

const ModalProduct = ({ modal, setModal, modalTab, setModalTab }) => {
  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  const { isAuthenticated, logout, user } = useAuth0();
  
  const [guardarsku, setguardarsku] = useState({
    /* user:{ _id:user.sub}, */
    id_cliente: user.sub,
    sku: "",
    shortdescription: "",
    fob: "",
    country_origin: "",
    upc_number: "",
    weight: "",
    mark: "",
  });

  const handlingChange = (event) => {
    setguardarsku({
      ...guardarsku,
      [event.target.name]: event.target.value,
    });
  };

  const SelectChange = (event) => {
    setguardarsku({
      ...guardarsku,
      country_origin: event.value,
    });
  };

  const Save = async () => {
    if (
      guardarsku.sku !== "" &&
      guardarsku.shortdescription !== "" &&
      guardarsku.country_origin !== "" &&
      guardarsku.weight !== ""
    ) {
      SaveSku(guardarsku).then(
        setguardarsku({
          id_cliente: user.sub,
          sku: "",
          shortdescription: "",
          fob: "",
          country_origin: "",
          upc_number: "",
          weight: "",
          mark: "",
        }),
        await Swal.fire({
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        }).then(setModal(false))
      );
    } else {
      Swal.fire({
        title: "oops!",
        text: "Please complete all fields!!",
        icon: "warning",
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [hidden, sethidden] = useState({
    escondido: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [clas, useclas] = useState({
    datos: "",
  });
  /* const buscarhts = () => {
		sethidden({ escondido: true });
		useclas({ datos: guardarsku.shortdescription });
	}; */
  const clave = `${user.sub}/skus/${guardarsku.sku}`;

  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered"
      size="lg"
      toggle={() => setModal(false)}
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            setModal(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">New Product</h5>
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "1" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("1");
                }}
                href="#personal"
              >
                General Information
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "2" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("2");
                }}
                href="#personal"
              >
                Documents
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTab === "1" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="3">
                  <FormGroup>
                    <label className="form-label" htmlFor="contact-name">
                      SKU Number
                    </label>
                    <input
                      type="text"
                      id="sku"
                      className="form-control"
                      name="sku"
                      value={guardarsku.sku}
                      onChange={handlingChange}
                      //onChange={(e) => onInputChange(e)}
                      //defaultValue={evento.contactname}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label className="form-label" htmlFor="cargo">
                      UPC Number
                    </label>
                    <input
                      id="upc_number"
                      name="upc_number"
                      className="form-control"
                      value={guardarsku.upc_number}
                      onChange={handlingChange}
                      //onChange={(e) => onInputChange(e)}
                      //defaultValue={evento.cargo}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="cargo">
                      Mark
                    </label>
                    <input
                      id="mark"
                      name="mark"
                      className="form-control"
                      value={guardarsku.mark}
                      onChange={handlingChange}
                      //onChange={(e) => onInputChange(e)}
                      //defaultValue={evento.cargo}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="pais">
                      Country Origin
                    </label>
                    <RSelect
                      options={countries}
                      placeholder="Select a country"
                      onChange={SelectChange}
                      //   defaultValue={evento.legaladdress.pais}
                      //   onChange={(e) =>
                      //     setEvento({
                      //       ...evento,
                      //       legaladdress: {
                      //         ...evento.legaladdress,
                      //         pais: e.value,
                      //       },
                      //     })
                      //   }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="telefono">
                      FOB Value (Unit)
                    </label>
                    <input
                      type="text"
                      id="fob"
                      name="fob"
                      className="form-control"
                      value={guardarsku.fob}
                      onChange={handlingChange}
                      // onChange={(e) => onInputChange(e)}
                      //defaultValue={evento.telefono}
                      placeholder="FOB Value"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="legalname">
                      Short Description
                    </label>
                    <input
                      type="text"
                      id="shortdescription"
                      name="shortdescription"
                      className="form-control"
                      value={guardarsku.shortdescription}
                      onChange={handlingChange}
                      // onChange={(e) => onInputChange(e)}
                      //defaultValue={evento.legalname}
                      placeholder="Enter Short Description"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="telefono">
                      Weight (Kg)
                    </label>
                    <input
                      type="text"
                      id="wight"
                      name="weight"
                      className="form-control"
                      value={guardarsku.weight}
                      onChange={handlingChange}
                      // onChange={(e) => onInputChange(e)}
                      //defaultValue={evento.telefono}
                      placeholder="FOB Value"
                    />
                  </FormGroup>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={Save}
                        /* onClick={(ev) => {
                            ev.preventDefault();
                            submitForm();
                          }} */
                      >
                        Save Product
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#dropdownitem"
                         onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }} 
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTab === "2" ? "active" : ""}`}
              id="personal"
            >
              {guardarsku.sku ? 
              <Row className="gy-4">
                <Col md="12">
                  <FormGroup>
                    <label className="form-label" htmlFor="legalname">
                      Upload Documents (Technical Sheets, Photos)
                     
                    </label>
                    <DocumentsGenerales clave={clave}></DocumentsGenerales>
                  </FormGroup>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="lg"
                        /* onClick={(ev) => {
                            ev.preventDefault();
                            submitForm();
                          }} */
                      >
                        Save Product
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#dropdownitem"
                         onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }} 
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row> : <Content><Row className="gy-4"><span className="text-primary"><strong>You must enter a valid SKU to upload documents.</strong></span></Row></Content>}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalProduct;

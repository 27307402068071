export const GetServicios = async (centro, id_seller) => {
 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "centro_costo": centro,
      "id_seller": id_seller
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    const response = await fetch(
      `https://apiecl.com/getservicios`,
      requestOptions
    );
    const respuesta = response.json();
    return respuesta;
  };
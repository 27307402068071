
export const InvoiceSigned = async (id,email,invoice,tipo) => {
  
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
    
    const resp = await fetch(`https://apiecl.com/firmargeneral?email=${email}&cliente=${id}&invoice=${invoice}&tipo=${tipo}`, requestOptions)
        const sku = await resp.json();
        return sku;
    
    }
    
import React, { useEffect, useState } from 'react';
import FileProvider from '../helpers/provider';
import {
  checkFileMIMEType,
  checkFileSize,
  getFormattedSize,
} from '../helpers/files.helper';
import Swal from 'sweetalert2';
import {nanoid} from 'nanoid';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { GetDocumentos } from '../helpers/getdocumentos';
import DataTable from 'react-data-table-component';
import { Icon, TooltipComponent } from '../../components/Component';
import { useAuth0 } from '@auth0/auth0-react';
import { DeleteDocument } from '../helpers/deletedocuments';
import { BajarDocument } from '../helpers/bajardocumentos';

const blobToBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const SubidorDocumentosFior = (clave) => {
  const { user } = useAuth0()
  const [documents, setdocuments] = useState();

  const MAXIMUM_FILES_LIMIT = null;
  const MAXIMUM_FILE_SIZE = 20; // Size in MB
  const ALLOWED_FORMATS = '.pdf, .doc, .docx, .odt, image/*';

  const [files, setFiles] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const getCurrentFiles = async (event) => {
    const rawFiles = event.target.files;
    const fileList = []; // WIll contain an array of type File[]
    if (!rawFiles) {
      return;
    }
    if (rawFiles instanceof Array) {
      rawFiles.forEach((file) => fileList.push(file));
    } else {
      const rawFilesArray = Array.from(rawFiles);
      rawFilesArray.forEach((file) => fileList.push(file));
    }
    const finalFileList = [];
    for (const file of fileList) {
      const extensionSeparatorIndex = file.name.lastIndexOf('.');
      const formattedName = file.name.substring(
        0,
        extensionSeparatorIndex
      );
      const extension = file.name.substr(extensionSeparatorIndex);
      const encodedData = await blobToBase64(file);
      const formattedFile = {
        name: formattedName,
        extension,
        file: encodedData?.toString() ?? '',
        type: 'append',
        size: file.size,
      };
      if (
        files.find(
          (appendedFile) => appendedFile.name === formattedFile.name
        )
      ) {
        Swal.fire({
          icon: 'info',
          title: `The file ${
            formattedFile.name + formattedFile.extension
          } is already added`,
        });
        break;
      }
      if (!checkFileMIMEType(file.type, ALLOWED_FORMATS)) {
        Swal.fire({
          icon: 'warning',
          title:
            'Please select another file (Supported formats: images and PDF)',
          html: `<small class="text-muted">The file ${file.name} is not supported</small>`,
        });
        break;
      }
      if (!checkFileSize(file.size, MAXIMUM_FILE_SIZE)) {
        Swal.fire({
          icon: 'warning',
          title: `The maximum file size is ${MAXIMUM_FILE_SIZE}MB`,
          html: `<small class="text-muted">The file ${
            file.name
          } have a size of ${getFormattedSize(file.size, 2)}</small>`,
        });
        break;
      }
      if (MAXIMUM_FILES_LIMIT) {
        if (files.length < MAXIMUM_FILES_LIMIT) {
          finalFileList.push(file);
        } else {
          Swal.fire({
            icon: 'info',
            title: `The maximum file quantity to upload is ${MAXIMUM_FILES_LIMIT}`,
          });
          break;
        }
      } else {
        finalFileList.push(file);
      }
    }
    if (finalFileList.length > 0) {
      setFiles([...files, ...finalFileList]);
    }
    event.target.value = event.target.defaultValue; // Reset input cached value
  };
  // 
  const handleFilesSubmit = async (event) => {
    event.preventDefault();
    for (const file of files) {
      const response = await new FileProvider().SubirExcel(file, clave)
      if (response.data === 'ok'){
        Swal.fire({
          icon: 'success',
          title: 'Successfuly upload',
          text: 'all right!',
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'You must complete the filter data',
          text: 'Something went wrong!',
        });
      }
    }
  };

  // remove file
  const removeFile = (event, index) => {
    if (event.isTrusted) {
      const currentFiles = Object.create(files);
      currentFiles.splice(index, 1);
      setFiles([...currentFiles]);
    }
  };

  const ActionComponent = ({ id, text, color, icon, row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <>
        <h5 className={`card-tools text-${color}`}>
        <Icon
          onClick={clickHandler}
          name={icon}
          id={id}
        />
        </h5>
      </>
    );
  };

  const deleterow = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteDocument(e).then(() => {
          const newstate = documents.filter((item) => item.name !== e);
          setdocuments(newstate);
        });
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const bajar = (e) => {
    BajarDocument(e).then((resp) => window.open(resp, "_blank"));
  };


  console.log(clave)
  const columnas = [
    {
      name: "File Name",
      selector: (row) => `${row.name}`,
      cell: (row) => (row ? row.name.split(`${clave.clave}/`) : null),
    },
    {
      name: "Size",
      selector: (row) => row.size,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className='d-flex justify-content-end'>
          <ActionComponent
            id="tooltip-2"
            text="Download File"
            color="success"
            icon="file-download"
            row={row.name}
            onClick={bajar}
          ></ActionComponent>
          <ActionComponent
            id="tooltip-1"
            text="Delete File"
            color="danger"
            icon="trash"
            row={row.name}
            onClick={deleterow}
          ></ActionComponent>
        </div>
      ),
    },
  ];

  useEffect(() => {
    GetDocumentos(clave.clave).then((response) => setdocuments(response));
  }, [clave]);

  return (
    <React.Fragment>
      {/* {!isReadOnly && ( */}
      <Container>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Documents Requested</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Company Registration (Tax authorities concerned)</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Passport of Legal Representative</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Passport of the second representative</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Power Attorney for INLT USA (When you initiate registration with the customs broker, you will receive an email with the document for electronic signature).</td>
            </tr>
          </tbody>
        </Table>
      </Container>
      <Container>
        <form onSubmit={handleFilesSubmit}>
          <div className="input-group">
            <div className="custom-file">
              <label
                className="custom-file-label"
                htmlFor="file-uploader"
                // style={{backgroundColor: '#000000'}}
                style={{ border: '2px solid #136C7E', }}
              >
                <input
                  type="file"
                  className="custom-file-input"
                  onChange={getCurrentFiles}
                  multiple
                  id="file-uploader"
                />
                Choose files
              </label>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              {files.length > 0 && (
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  type="submit"
                >
                  Upload listed files
                </Button>
              )}
              <span
                className={[
                  'text-primary',
                  files.length > 0 && 'float-right',
                ]
                  .filter(Boolean)
                  .join(' ')}
              >
                {files.length > 0 &&
                  `Files in queue (${files.length}):`}
              </span>
            </div>
          </div>
          {files.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th className="text-info">File Name</th>
                  <th className="text-info">File Size</th>
                  <th className="text-info">Actions</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => (
                  <tr key={nanoid()}>
                    <td>{file.name}</td>
                    <td>{getFormattedSize(file.size, 2)}</td>
                    <td>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={(event) => removeFile(event, index)}
                        disabled={submitting}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </form>
      </Container>
      <Container>
        <Row>
          <Col lg='12'>
            <DataTable
              responsive
              pointerOnHover
              dense
              striped
              pagination
              columns={columnas}
              data={documents}
            />
          </Col>
        </Row>
      </Container>
      {/* )} */}
    </React.Fragment>
  );
};

export default SubidorDocumentosFior;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Center from "react-center";
import { useAuth0 } from "@auth0/auth0-react";
import Swal from "sweetalert2";

import { Button, Icon, Col, Row, RSelect } from "../../components/Component";
import ModalProduct from "../../dashboard/components/modalproduct";
import SaveClasHts from "../helpers/SaveClasHts";
import { useGetSku } from "../hooks/useGetSku";
import { GetSku } from "../../dashboard/helpers/GetSku";

export default function HtsDialogEnd({
  evento1,
  evento2,
  evento3,
  evento4,
  evento5,
}) {
  const { isAuthenticated, logout, user } = useAuth0();
  const [skus, setskus] = useState();
  // opciones que estaran dentro del select
  const [select, setSelect] = useState([]);

  var arr = [];
  useEffect(() => {
    GetSku(user.sub).then((resp) => {
      resp?.forEach(({ shortdescription, sku }) => {
        arr.push({ label: sku + " " + shortdescription, value: sku });
      });
      setSelect(arr);
    });
  }, [user.sub]);

  // useEffect(() => {
  //   new FbaProvider().getfbas().then(result=>setFba(result.data))
  //   const arr = []
  //   Array.from(event).forEach((element) => {
  //     if (element.qty > 0) {
  //       const id_select = arr.length
  //       arr.push({
  //         id: id_select,
  //         _id: element._id,
  //         sku: element.sku,
  //         label: element.label,
  //         qty: element.qty,
  //         weight: element.weight
  //       })
  //     }
  //   })
  //   if(arr.length===0){
  //     setNoProduct(true)
  //   }
  //   setSelect(arr)
  // }, [event,setNoProduct])
  // //const sku = useGetSku(user.sub);
  // const skufinal = sku.data;

  const [modal, setModal] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  //console.log("chinos de la gran puta", evento5.chinos.type);
  // para manejar los eventos de las dos cajas de texto

  const [datos, setDatos] = useState({
    sku: "",
    shortdescription: "",
  });

  const fecha = new Date();
  const date = fecha.toLocaleDateString();

  const [saveclashts, setSaveclashts] = useState({
    categories: evento2,
    hts8: evento4.hts8,
    duties: evento4.general,
    special: evento4.special,
    country: evento4.country,
    destination: evento4.destination,
    description: evento3,
    hts: evento1,
    dutiesrate: evento4.duties,
    dutiespecific: evento4.dutiespecific,
    list301: evento5.chinos.type,
    duties301: evento5.chinos.rate,
    date,
  });

  const handleInputChange = (event) => {
    setDatos({
      sku: event.value,
    });
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (datos.sku === "") {
      Swal.fire({
        title: "oops!",
        text: "Please select SKU!",
        icon: "warning",
      });
    } else {
      SaveClasHts(saveclashts, datos.sku, user.sub).then(
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 2000,
        })
      );
    }
  };

  return (
    <>
      <ul>
        <li>
          <strong>Select your sku to assign it HTS</strong>{" "}
        </li>
        <li>
          <RSelect
            className="text-primary"
            options={select}
            onChange={handleInputChange}
          />
          <div className="inline">
            <br></br>
            <Button color="primary" onClick={handleClickOpen}>
              Save and Exit
            </Button>
            &nbsp; &nbsp;
            <Button color="primary" onClick={() => setModal(true)}>
              + Add Product
            </Button>
          </div>

          <li>
            <hr></hr>
          </li>
        </li>
        <li>{evento1}</li>
        <li>{evento4.description1}</li>
        <li>{evento2.L2}</li>
        <li>{evento2.L3}</li>
        <li>{evento4.description}</li>
        <li>{evento3}</li>
        <li> Duties: {evento4.general}</li>
        <li> {evento4.special}</li>
        <li> {evento4.country}</li>
        <li>
          <hr></hr>
        </li>
        <li> China (301b)</li>
        <li>List: {evento5.chinos.type ? evento5.chinos.type : "N/A"}</li>
        <li>Rate: {evento5.chinos.rate ? evento5.chinos.rate : "N/A"}</li>
        <li>
          <hr></hr>
        </li>
      </ul>

      <ModalProduct
        modal={modal}
        setModal={setModal}
        modalTab={modalTab}
        setModalTab={setModalTab}
      ></ModalProduct>

      {/* <Typography>Input Products References</Typography>
      <FormControl>
       
        <Typography style={{ color: red[400] }} variant="caption" gutterBottom>
          <strong>Search Your Saved SKU Code</strong>
        </Typography>
      </FormControl>
      <FormControl>
        <Button
          type="submit"
          variant="outlined"
          color="secondary"
          onClick={handleClickOpen}
        >
          Save Classification
        </Button>
      </FormControl>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
          <CardHeader
            sx={{
              bgcolor: theme.palette.info.main,
              color: theme.palette.background.paper,
              borderBottom: `1px solid ${theme.palette.info.main}`,
            }}
            title={
              <Typography variant="h5" color="inherit">
                HTS : {evento1}
              </Typography>
            }
          />
          <Divider />
          <CardContent
            sx={{
              bgcolor: theme.palette.info.main,
              color: theme.palette.background.paper,
            }}
          >
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="subtitle2" color="inherit">
                  Disclaimer: La clasificación arancelaria del producto es
                  sugerida, condicionada a la correcta selección del SKU, su
                  naturaleza, origen, por parte del usuario. No es
                  responsablidad de Ecommerce Logistics LLC y su aplicación en
                  la incorrecta clasificación.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" color="inherit">
                  Referencia del Producto
                </Typography>
                <Typography gutterBottom>
                  <ListItem>
                    <span>SKU:</span>&nbsp;{datos.sku}
                  </ListItem>
                  <ListItem>
                    <span>Descripción Corta:</span>&nbsp;
                    {datos.shortdescription}
                  </ListItem>
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  Categoria
                </Typography>
                <Typography gutterBottom>
                  <ListItem>{evento2.L2}</ListItem>
                  <ListItem>{evento2.L3}</ListItem>
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  Descripción (Customs)
                </Typography>
                <Typography gutterBottom>
                  <ListItem>{evento3} </ListItem>
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  Tasa de Importacion General (Duties)
                </Typography>
                <Typography gutterBottom>
                  <ListItem>{evento4.general}</ListItem>
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  Tasa de Importacion General (Duties)
                </Typography>
                <Typography gutterBottom>
                  <ListItem>{evento4.general}</ListItem>
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  Sección 301 (Origen China)
                </Typography>
                <Typography gutterBottom>
                  <ListItem>List: {evento5.chinos.type}</ListItem>
                  <ListItem>Rate: {evento5.chinos.rate}</ListItem>
                </Typography>
				
              </Grid>
			  
            </Grid>
			<Center>
                <Button variant ="outlined" autoFocus onClick={handleClose} color="inherit">
                  Close Details
                </Button>
				</Center>
          </CardContent>
       
      </Dialog> */}
    </>
  );
}

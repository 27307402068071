const GetCitas = async cliente => {

var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  const resp = await fetch(`https://apiecl.com/getcallcenter?email=${cliente}`, requestOptions)
  const sku = await resp.json();

  return sku;

}

export default GetCitas
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
/* eslint-disable no-unused-vars */

import DataTable from "react-data-table-component";
import Center from "react-center";
import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../../components/Component";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ChangingProgressProvider from "./ChangingProgressProvider";
import UseFetchHts from "../hooks/UseFetchHts";
import { UseFetchHtsCategory } from "../hooks/UseFetchHtsCategory";
import HtsGetListHts from "./HtsGetListHts";
import UseFetchChina from "../hooks/USeFetchChina";

const HtsGrid = ({ encabezado, setencabezado }) => {
const { data, loading, finales } = UseFetchHts(encabezado.hts);
const { categorias } = UseFetchHtsCategory(encabezado.hts);
const htschinos = UseFetchChina(encabezado.hts);
 
const columnas = [
    {
      name: "HTS Number",
      selector: (row) => row.htsno,
    },
    {
      name: "CBP Description",
      cell: (row) => `${row.description}`,
    },
    {
      name: "General Duties",
      cell: (row) => `${row.general}`,
    },
    {
      name: "FTA",
      selector: (row) => `${row.special}`,
    },
  ];

  return (
    <>
      <Row>
        <Col size={6}>
          <Card className="card-bordered" inverse color="secondary">
            <CardBody className="card-inner">
              <CardTitle tag="h5">HTS Categories</CardTitle>
              <hr></hr>
              <CardText>
               <ul>
                  <li>
                    <Icon name="forward-arrow-fill" /> {categorias.L0}
                  </li>
                  <li>
                    <Icon name="forward-arrow-fill" /> {categorias.L1}
                  </li>
                  <li>
                    <Icon name="forward-arrow-fill" /> {categorias.L2}
                  </li>
                  <li>
                    <Icon name="forward-arrow-fill" /> {categorias.L3}
                  </li>
                </ul> 
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col size={6}>
          <Card className="card-bordered" inverse color="secondary">
            <CardBody className="card-inner">
              <CardTitle tag="h5">Select and Save Suggested  HTS</CardTitle> <hr></hr>
            
              <CardText>
                {
                  <HtsGetListHts
                    htschino={htschinos}
                    eventos={finales}
                    categorias={categorias}
                    encabezado={encabezado}
                  />
                }
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br></br>
      <DataTable columns={columnas} data={data} striped pagination />

      {/* {  value && (
					<HtsDialogEnd
						evento1={value}
						evento2={categorias}
						evento3={data.description}
						evento4={encabezado}
						evento5={htschino}
					/>
				) } */}
    </>
    // Aqui tengo las dos columnas que vamos a renderizar //
    // Primera Columna Resumen de la Solicitud y Muestra los TAXES
    // <>
    //   <Grid item lg={4} md={4} sm={12} xs={12}>
    //     <HtsHeader event={encabezado} />
    //   </Grid>
    //   <Grid item lg={4} md={4} sm={12} xs={12}>
    //     <TableContainer component={Paper}>
    //       <Table aria-label="simple table">
    //         <TableHead>
    //           <TableRow>
    //             <TableCell>Product Category</TableCell>
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>{<HtsGetListCategories event={categorias} />}</TableBody>
    //       </Table>
    //     </TableContainer>
    //   </Grid>
    //   <Grid item lg={4} md={4} sm={12} xs={12}>
    //    <Paper sx={{padding: 3}}>
    //       { <HtsGetListHts
    // 				htschino={htschinos}
    // 				eventos={finales}
    // 				categorias={categorias}
    // 				encabezado={encabezado}
    // 			/> }
    //     </Paper>
    //   </Grid>
    //   <Grid item lg={12} md={12} sm={12} xs={12}>
    //     <Paper>
    //       <TableContainer>
    //         <Table aria-label="simple table">
    //           <TableHead>
    //             <TableRow>
    //               <TableCell>
    //                 <h4>Suggested HTS Code</h4>
    //               </TableCell>
    //               <TableCell />
    //               <TableCell />
    //               <TableCell />
    //             </TableRow>
    //           </TableHead>
    //           <TableBody>
    //             {data.map((img) => (
    //               <HtsResultList key={img.htsno} {...img} />
    //             ))}
    //           </TableBody>
    //         </Table>
    //       </TableContainer>
    //     </Paper>
    //   </Grid>
    // </>
  );
};

export default HtsGrid;

/*
{
    data.map(img => (
        <HtsResultList
            key={img.htsno}
            {...img}
        />
    ))
} */
/*
   <TableBody>

                        {
                            data.map(img => (
                                <HtsResultList
                                    key={img.hts}
                                    {...img}
                                />
                            ))
                        }
                    </TableBody> */

/*              <TableBody>

              {
                  data.map(img => (
                      <HtsResultList
                          key={img.htsno}
                          {...img}
                      />
                  ))
              }
          </TableBody> */

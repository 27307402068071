import React from "react";
import Principal from "./components/principal";


const CalculatorB2B= () => {
  return (
    <React.Fragment>
      <Principal></Principal>
    </React.Fragment>
  );
};

export default CalculatorB2B;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween,
} from "../../components/Component";
//import { useAuth0 } from "@auth0/auth0-react";
//import ListHtsTools from "./ListHtsTools";
//import ListHtsTable from "./ListHtsTable";
import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";
import Iframe from "react-iframe";
import ResponsiveEmbed from "react-responsive-embed";
export default function ContactoSpec() {
  //const { isAuthenticated, logout, user } = useAuth0();
  return (
    <React.Fragment>
      <Content>
        <Head title="Compliance Robot"></Head>

        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Contáctenos
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Bienvenido a GSA Sellers Compliance</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col lg="12">
              {" "}
              <span className="text-mute">
                <h5>
                  Actualmente ofrecemos asistencia sobre Documentación de
                  productos, documentación de envío, y los requisitos para
                  exportar a los Estados Unidos de América (EE.UU.).
                </h5>{" "}
                <p></p> <p></p>
                <p></p>
                EE.UU. tiene un conjunto de normas de importación con
                certificaciones y requisitos de etiquetado para productos, que
                usted debe cumplir.
              </span>
              <br></br>
              <br></br>
              <ResponsiveEmbed
                src="https://api.leadconnectorhq.com/widget/form/JpBDtRYBu4fY1XjC5Rxp"
                allowfullscreen
                ratio="1:1"
              />
              {/* <br></br>
                    <Button
                      color="primary"
                      onClick={() =>
                        sethidden({ dashboard: false, categories: true })
                      }
                    >
                      Comenzar
                    </Button> */}
            </Col>{" "}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
}

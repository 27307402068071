import React from "react";
import Principal from "./components/principal";



const CallCenter = ({ ...props }) => {
  return (
    <React.Fragment>
      <Principal></Principal>
    </React.Fragment>
  );
};

export default CallCenter;
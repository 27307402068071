import React from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";

import Layout from "./layout/Index";

import Error404Classic from "./pages/error/404-classic";
import Error404Modern from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";

import Faq from "./pages/others/Faq";
import Terms from "./pages/others/Terms";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Success from "./pages/auth/Success";
import InvoicePrint from "./pages/pre-built/invoice/InvoicePrint";
import PrivateRoute from "./route/PrivateRoute";

const App = () => {
  return (
    <Switch>
      {/* Auth Pages */}
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auth-success`}
        component={Success}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auth-reset`}
        component={ForgotPassword}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auth-register`}
        component={Register}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auth-login`}
        component={Login}
      />

      {/* Print Pages */}
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/invoice-print/:id`}
        component={InvoicePrint}
      />

      {/* Helper pages */}
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auths/terms`}
        component={Terms}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auths/faq`}
        component={Faq}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/invoice-print`}
        component={InvoicePrint}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/calculatorb2c`}
        component={Layout}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/quoatationb2c`}
        component={Layout}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/bookedb2c`}
        component={Layout}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/spec`}
        component={Layout}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/contactospec`}
        component={Layout}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/iorspec`}
        component={Layout}
      />

      {/*Error Pages*/}
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/errors/404-classic`}
        component={Error404Classic}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/errors/504-modern`}
        component={Error504Modern}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/errors/404-modern`}
        component={Error404Modern}
      />

      <Route
        exact
        path={`${process.env.PUBLIC_URL}/errors/504-classic`}
        component={Error504Classic}
      />

      {/*Main Routes*/}
      <PrivateRoute exact path="" component={Layout} />

      <Route component={RedirectAs404}></Route>
    </Switch>
  );
};
export default App;

/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, FormGroup, Collapse } from "reactstrap";
import DataTable from "react-data-table-component";
import { Label, Form } from "reactstrap";
import classNames from "classnames";
import {
  Button,
  Icon,
  Col,
  Row,
  RSelect,
  Block,
} from "../../../components/Component";
import { TooltipComponent } from "../../../components/Component";
import { codes } from "iso-country-codes";
//import { Updateseller } from "../helpers/updateseller";
import Swal from "sweetalert2";
import { currentTime, monthNames, todaysDate } from "../../../utils/Utils";
//import { useAuth0 } from "@auth0/auth0-react";
//import { SaveCnee } from "../helpers/savecnee";
//import GetZipMx from "../../MxCalculadoraRapida/helpers/GetZipMx";
import DocumentsExport from "./documents";
import Content from "../../../layout/content/Content";
//import Documents from "../../dashboard/components/documents";
import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";
import { toast } from "react-toastify";
import UpdateB2B from "../helpers/savebookingb2b";
import { useAuth0 } from "@auth0/auth0-react";
import Fbas from "./ajustestarifa";

//import Lister from "../../dashboard/components/SellerListFiles";
const ModalBooking = ({
  modal,
  setModal,
  modalTab,
  setModalTab,
  bookingvalue,
  setbookingvalue,
  seller,
  setflag,
  setActiveIconTab,
  setflag1,
}) => {
  const { isAuthenticated, logout, user } = useAuth0();
  const [escondido, setescondido] = useState(false);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  var today = new Date();
  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };
  const CustomToast = () => {
    return (
      <div className="toastr-text">
        <h5>Save Successfully</h5>
        <p>Customs Broker was added</p>
      </div>
    );
  };

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  //const { isAuthenticated, logout, user } = useAuth0();
  const [cbporigen, setcbporigen] = useState();
  const [cbpdestino, setcbpdestino] = useState();
  const [bookingfinal, setbookingfinal] = useState({
    eventos: [],
  });

  //Aparece on no aparece agente de aduanas en origne
  const [aparece, setaparece] = useState(true);
  const [aparece_1, setaparece_1] = useState(true);
  const [boton, setboton] = useState(false);
  const formClass = classNames({
    "form-validate": true,
  });
  // const savefinal = () => {

  //   Swal.fire({
  //     position: "top-end",
  //     icon: "success",
  //     title: "Your Booking has been Sent to Our Operator",
  //     showConfirmButton: false,
  //     timer: 5000,
  //   }).then(() => setModal(false));
  // };

  const savefinal = () => {
    if (
      bookingfinal.fechapickup &&
      bookingfinal.instrucciones &&
      aparece &&
      cbporigen
    ) {
      UpdateB2B(bookingvalue[0]._id.$oid, bookingfinal).then(() => {
        Swal.fire({
          title: "You quotation was Booked",
          text: "Information was send to our operator",
          confirmButtonText: "OK",
          icon: "success",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            location.replace("/bookedb2c");
            setescondido(true);
            setModal(false);
          }
        });
      });
    } else if (
      bookingfinal.fechapickup &&
      bookingfinal.instrucciones &&
      aparece_1 &&
      cbpdestino
    ) {
      UpdateB2B(bookingvalue[0]._id.$oid, bookingfinal).then(() => {
        Swal.fire({
          title: "You quotation was Booked",
          text: "Information was send to our operator",
          confirmButtonText: "OK",
          icon: "success",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            location.replace("/bookedb2c");
            setescondido(true);
            setModal(false);
          }
        });
      });
    } else if (
      bookingfinal.fechapickup &&
      bookingfinal.instrucciones &&
      !aparece_1 &&
      cbpdestino
    ) {
      UpdateB2B(bookingvalue[0]._id.$oid, bookingfinal).then(() => {
        Swal.fire({
          title: "You quotation was Booked",
          text: "Information was send to our operator",
          confirmButtonText: "OK",
          icon: "success",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            location.replace("/bookedb2c");
            setescondido(true);
            setModal(false);
          }
        });
      });
    } else if (
      bookingfinal.fechapickup &&
      bookingfinal.instrucciones &&
      !aparece &&
      cbporigen &&
      !aparece_1 &&
      cbpdestino
    ) {
      UpdateB2B(bookingvalue[0]._id.$oid, bookingfinal).then(() => {
        Swal.fire({
          title: "You quotation was Booked",
          text: "Information was send to our operator",
          confirmButtonText: "OK",
          icon: "success",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            location.replace("/bookedb2c");
            setescondido(true);
            setModal(false);
          }
        });
      });
    } else if (
      bookingfinal.fechapickup &&
      bookingfinal.instrucciones &&
      aparece &&
      aparece_1
    ) {
      UpdateB2B(bookingvalue[0]._id.$oid, bookingfinal).then(() => {
        Swal.fire({
          title: "You quotation was Booked",
          text: "Information was send to our operator",
          confirmButtonText: "OK",
          icon: "success",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            location.replace("/bookedb2c");
            setescondido(true);
            setModal(false);
          }
        });
      });
    } else if (
      bookingfinal.fechapickup &&
      bookingfinal.instrucciones &&
      !aparece_1 &&
      cbpdestino &&
      aparece
    ) {
      UpdateB2B(bookingvalue[0]._id.$oid, bookingfinal).then(() => {
        Swal.fire({
          title: "You B2C quotation was Booked",
          text: "Information was send to our operator",
          confirmButtonText: "OK",
          icon: "success",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            location.replace("/bookedb2c");
            setescondido(true);
            setModal(false);
          }
        });
      });
    } else if (
      bookingfinal.fechapickup &&
      bookingfinal.instrucciones &&
      !aparece &&
      cbporigen &&
      aparece_1
    ) {
      UpdateB2B(bookingvalue[0]._id.$oid, bookingfinal).then(() => {
        Swal.fire({
          title: "You quotation was saved",
          confirmButtonText: "OK",
          icon: "success",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            location.replace("/bookedb2c");
            setescondido(true);
            setModal(false);
          }
        });
      });
    } else {
      alert("Please correct the warning to continue");
    }
  };
  const cbporigenbook = () => {
    setbookingfinal({ ...bookingfinal, cbporigenbook: cbporigen });
    toast.success(<CustomToast />, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: <CloseButton />,
    });
  };

  const cbpdestinobook = () => {
    setbookingfinal({ ...bookingfinal, cbpdestinobook: cbpdestino });
    toast.success(<CustomToast />, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: <CloseButton />,
    });
  };
  const [isOpen, setIsOpen] = useState(false);

  const columns = [
    {
      name: "Pallet Nro.",
      selector: (row) => `${row.id_evento}`,
      cell: (row) => `${row.id_evento}`,
    },
    {
      name: "Weight (Kg)",
      selector: (row) => `${row.kg}`,
      cell: (row) => `${row.kg}`,
    },
    {
      name: "Length (cm)",
      selector: (row) => `${row.length}`,
      cell: (row) => `${row.length}`,
    },
    {
      name: "Width (cm)",
      selector: (row) => `${row.width}`,
      cell: (row) => `${row.width}`,
    },
    {
      name: "Height (cm)",
      selector: (row) => `${row.height}`,
      cell: (row) => `${row.height}`,
    },
  ];

  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered"
      size="xl"
      toggle={() => setModal(false)}
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            setModal(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">B2C Booking Request</h5>
          <h6 className="text-primary">Delivery Inventory to FBA</h6>
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "1" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("1");
                }}
                href="#personal"
              >
                1. General
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "2" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("2");
                }}
                href="#address"
              >
                2. Adittional Info
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "3" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("3");
                }}
                href="#address"
              >
                3. Documents
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "4" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("4");
                }}
                href="#address"
              >
                4. Customs Broker
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "6" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("6");
                }}
                href="#address"
              >
                5. FBA Distribution
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "5" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("5");
                }}
                href="#address"
              >
              6. Finish Booking
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTab === "1" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="12">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="pais">
                          Reference
                        </label>
                        <input
                          //placeholder={update ? update.pais : null}
                          defaultValue={
                            bookingvalue ? bookingvalue[0].ref : null
                          }
                          className="form-control"
                          name="referencia"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="pais">
                          Recolection Wharehouse
                        </label>
                        <input
                          //placeholder={update ? update.pais : null}
                          defaultValue={
                            bookingvalue ? bookingvalue[0].whin : null
                          }
                          className="form-control"
                          name="whin"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md="12">
                  <div className="align-center gx-3">
                    <Col md="6">
                      <div className="flex-item">
                        <span className="sub-text sub-text-sm text-soft">
                          <label className="form-label" htmlFor="contactname">
                            {seller[0] ? (
                              <>
                                <span className="text-primary">
                                  Export From: (Seller):
                                </span>
                                <hr></hr>
                                {seller[0].legalname}
                                <br></br>
                                {seller[0].numero} {seller[0].calle}
                                <br></br>
                                {seller[0].ciudad} {seller[0].estado}
                                <br></br>
                                {seller[0].pais} {seller[0].zip}
                                <br></br>
                                <span className="glyphicon glyphicon-refresh text-primary">
                                  <Icon name="user-alt-fill" />
                                </span>{" "}
                                {seller[0].contactname}
                                <br></br>
                                <span className="glyphicon glyphicon-refresh text-primary">
                                  <Icon name="call-alt-fill" />
                                </span>{" "}
                                {seller[0].telefono}
                                <br></br>
                                <span className="glyphicon glyphicon-refresh text-primary">
                                  <Icon name="mail-fill" />
                                </span>{" "}
                                {seller[0].email}
                                <br></br>
                                <span className="glyphicon glyphicon-refresh text-primary">
                                  <Icon name="whatsapp" />
                                </span>{" "}
                                {seller[0].ws}
                              </>
                            ) : null}
                          </label>
                        </span>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="flex-item">
                        <span className="sub-text sub-text-sm text-soft">
                          <label className="form-label" htmlFor="contactname">
                            {seller[0] ? (
                              <>
                                <span className="text-primary">
                                  Foreing Import of Record: (FIOR):
                                </span>
                                <hr></hr>
                                {seller[0].legalname}
                                <br></br>
                                {seller[0].numero} {seller[0].calle}
                                <br></br>
                                {seller[0].ciudad} {seller[0].estado}
                                <br></br>
                                {seller[0].pais} {seller[0].zip}
                                <br></br>
                                <span className="glyphicon glyphicon-refresh text-primary">
                                  <Icon name="user-alt-fill" />
                                </span>{" "}
                                {seller[0].contactname}
                                <br></br>
                                <span className="glyphicon glyphicon-refresh text-primary">
                                  <Icon name="call-alt-fill" />
                                </span>{" "}
                                {seller[0].telefono}
                                <br></br>
                                <span className="glyphicon glyphicon-refresh text-primary">
                                  <Icon name="mail-fill" />
                                </span>{" "}
                                {seller[0].email}
                                <br></br>
                                <span className="glyphicon glyphicon-refresh text-primary">
                                  <Icon name="whatsapp" />
                                </span>{" "}
                                {seller[0].ws}
                              </>
                            ) : null}
                          </label>
                        </span>
                      </div>
                    </Col>
                  </div>
                </Col>
                <Col lg="4">
                  <label className="form-label" htmlFor="qty">
                    Total FOB
                  </label>
                  <br></br>
                  <strong>
                    {" "}
                    {bookingvalue
                      ? formatter.format(bookingvalue[0].fob)
                      : null}
                  </strong>
                </Col>
                <Col lg="4">
                  <label className="form-label" htmlFor="qty">
                    Total Pallets
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    id="qty"
                    name="qty"
                    value={bookingvalue ? bookingvalue[0].qty : null}
                    disabled
                  />{" "}
                </Col>
                <Col lg="4">
                  <label className="form-label" htmlFor="qty">
                    Total Weight (Kg)
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    id="kg_total"
                    name="kg_total"
                    value={bookingvalue ? bookingvalue[0].kg : null}
                    disabled
                  />{" "}
                </Col>
                <Col md="12">
                  <DataTable
                    dense
                    striped
                    columns={columns}
                    data={bookingvalue ? bookingvalue[0].pallets : null}
                  />
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTab === "2" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="legalname">
                      Services
                    </label>
                    <input
                      type="text"
                      id="services"
                      className="form-control"
                      name="services"
                      disabled
                      defaultValue={
                        bookingvalue ? bookingvalue[0].services : null
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="dbaname">
                      Pickup Date
                    </label>
                    <input
                      type="date"
                      id="date"
                      className="form-control"
                      name="date"
                      onChange={(e) =>
                        setbookingfinal({
                          ...bookingfinal,
                          id_seller: user.sub,
                          id_stripe: seller ? seller[0].id_stripe : false,
                          fechapickup: e.target.value,
                          bookingdate: today.toLocaleDateString("en-US"),
                          fleteinternacional: bookingvalue
                            ? bookingvalue[0].totalflete +
                              bookingvalue[0].seguro +
                              bookingvalue[0].handling +
                              bookingvalue[0].admin +
                              bookingvalue[0].custom +
                              bookingvalue[0].pickuprate
                            : null,
                          eventos: [
                            {
                              descripcion: "Booked Created",
                              fecha: today.toLocaleDateString("en-US"),
                              remarks: "Booked Created",
                            },
                            {
                              descripcion: "Waiting Payment",
                              fecha: today.toLocaleDateString("en-US"),
                              remarks: "Unpaid",
                            },
                          ],
                        })
                      }
                      // //  defaultValue={update ? update.dbaname : null}
                      placeholder="Pickup Date"
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-label" htmlFor="country">
                      Pickup Address
                    </label>
                    <div className="form-control-wrap">
                      {" "}
                      <input
                        id="pickup"
                        name="pickup"
                        className="form-control"
                        disabled
                        defaultValue={
                          bookingvalue
                            ? bookingvalue[0].picksel[0].numero +
                              " " +
                              bookingvalue[0].picksel[0].calle +
                              " " +
                              bookingvalue[0].picksel[0].ciudad +
                              " " +
                              bookingvalue[0].picksel[0].estado +
                              " " +
                              bookingvalue[0].picksel[0].calle +
                              " " +
                              bookingvalue[0].picksel[0].pais +
                              " " +
                              bookingvalue[0].picksel[0].zip
                            : null
                        }
                      />
                    </div>
                  </FormGroup>
                </Col>

                <Col md="8">
                  <FormGroup>
                    <textarea
                      type="text"
                      id="instrucciones"
                      className="form-control"
                      name="instrucciones"
                      onChange={(e) =>
                        setbookingfinal({
                          ...bookingfinal,
                          instrucciones: e.target.value,
                        })
                      }
                      placeholder="Special Instructions"
                    />
                  </FormGroup>
                </Col>
                {/* <Col md="4">
                  <FormGroup>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        id="customCheck9"
                        name="customCheck9"
                        checked={selectpickup ? selectpickup.checkpick : null}
                        //onChange={exchange}
                        disabled
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck9"
                      >
                        Include Pickup in Origin
                      </label>
                    </div>
                  </FormGroup>
                </Col> */}
              </Row>
            </div>

            <div
              className={`tab-pane ${modalTab === "3" ? "active" : ""}`}
              id="address"
            >
              <Row className="gy-4">
                {" "}
                <Col size="12">
                  <Content>
                    <div className="text-muted">
                      <span className="glyphicon glyphicon-refresh text-warning">
                        <Icon name="info-i" />
                      </span>{" "}
                      {"   "}
                      Do not worry if you do not have your export documents at
                      this time. You can upload your shipments documents later
                      in the booked shipments list.
                    </div>
                    <br></br>
                    <DocumentsExport
                      referencia={bookingvalue ? bookingvalue[0].ref : null}
                    ></DocumentsExport>
                  </Content>
                </Col>
              </Row>
            </div>

            <div
              className={`tab-pane ${modalTab === "6" ? "active" : ""}`}
              id="personal"
            >
              <Fbas setbookingfinal = {setbookingfinal} bookingfinal = {bookingfinal}></Fbas>
            </div>
            <div
              className={`tab-pane ${modalTab === "5" ? "active" : ""}`}
              id="personal"
            >
              <h6>
                {" "}
                <span className="text-warning">Warnings</span>
              </h6>
              <hr></hr>
              <ul className="flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  {" "}
                  {bookingfinal.fechapickup ? null : (
                    <>
                      - Pickup Date is missing{" "}
                      <span className="glyphicon glyphicon-refresh text-danger">
                        <Icon name="alert-fill" />
                      </span>
                    </>
                  )}
                </li>
                <li>
                  {" "}
                  {bookingfinal.instrucciones ? null : (
                    <>
                      - Special instructions is missing{" "}
                      <span className="glyphicon glyphicon-refresh text-danger">
                        <Icon name="alert-fill" />
                      </span>
                    </>
                  )}
                </li>
                <li>
                  {" "}
                  {!aparece && !bookingfinal.cbporigenbook ? (
                    <>
                      - You Selected your own customs broker at origin, no
                      changes were save{" "}
                      <span className="glyphicon glyphicon-refresh text-danger">
                        <Icon name="alert-fill" />
                      </span>
                    </>
                  ) : null}
                </li>
                <li>
                  {" "}
                  {!aparece_1 && !bookingfinal.cbpdestinobook ? (
                    <>
                      - You selected your own customs broker at destination, no
                      changes were save{" "}
                      <span className="glyphicon glyphicon-refresh text-danger">
                        <Icon name="alert-fill" />
                      </span>
                    </>
                  ) : null}
                </li>
              </ul>
              <br></br>
              <h6>
                {" "}
                <span className="text-info">Info</span>
              </h6>
              <hr></hr>

              <ul className="flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  {" "}
                  {aparece_1 ? (
                    <>
                      - You selected INLT Customs Brokers in USA{" "}
                      <span className="glyphicon glyphicon-refresh text-info">
                        <Icon name="help-fill" />
                      </span>
                    </>
                  ) : null}
                </li>
                <li>
                  {" "}
                  {aparece ? (
                    <>
                      - You selected Transplace Customs Broker in Origin{" "}
                      <span className="glyphicon glyphicon-refresh text-info">
                        <Icon name="help-fill" />
                      </span>
                    </>
                  ) : null}
                </li>
              </ul>
              <br></br>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="lg" onClick={savefinal}>
                      Send Booking
                    </Button>

                    <Button
                      onClick={() => {
                        setModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </div>
            <div
              className={`tab-pane ${modalTab === "4" ? "active" : ""}`}
              id="address"
            >
              <Row className="gy-4">
                <Col md="6">
                  <Card className="card-bordered">
                    {aparece ? (
                      <div className="card-content p-3">
                        <CardImg
                          height={70}
                          src="Transplace_Uber-Freight-Logo.png"
                          alt=""
                        />
                      </div>
                    ) : null}
                    <CardBody className="card-inner">
                      {/* <CardTitle tag="h5">Card with stretched link</CardTitle> */}

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={aparece}
                          onClick={() => setaparece(!aparece)}
                          onChange={() => {
                            setcbporigen("");
                            setbookingfinal({
                              ...bookingfinal,
                              cbporigenbook: "",
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor="com-email">
                          Origin Customs Broker
                        </label>
                        <TooltipComponent
                          icon="alert-fill"
                          direction="top"
                          id="Tooltip2"
                          text="You can deselect and to input your origin customs broker information."
                        />
                      </div>

                      <br></br>
                      {aparece ? (
                        <div className="accordion accordian-s3">
                          <div className="accordion-item ">
                            <div
                              className="accordion-head"
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              <h6 className="title text-primary">
                                Required Documents
                              </h6>
                              <span className="accordion-icon"></span>
                            </div>
                            <Collapse
                              className="accordion-body"
                              isOpen={isOpen === true ? true : false}
                            >
                              <div className="accordion-inner">
                                <ul>
                                  <li>
                                    Constancia de Situación Fiscal mes en curso
                                  </li>
                                  <li>
                                    Comprobante de domicilio actual (agua, luz,
                                    teléfono) antigüedad no mayor a 3 meses.
                                  </li>
                                  <li>Carta de Encomienda 2022</li>
                                  <li>Opinión Positiva mes en curso</li>
                                  <li>Carta Compromiso OEA</li>
                                  <li>
                                    Identificacion oficial del representante
                                    legal
                                  </li>
                                  <li>Acta Constitutiva de la empresa</li>
                                  <li>
                                    Poder Notarial del representante legal.
                                  </li>
                                  <li>
                                    Fotografía de la fachada donde se vea el
                                    numero exterior, calle o nombre de la
                                    empresa.
                                  </li>
                                </ul>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      ) : null}

                      {/* <Button color="primary">Go somewhere</Button> */}
                    </CardBody>
                    {aparece ? null : (
                      <CardBody className="card-inner">
                        <CardTitle tag="h6">
                          Your Customs Broker in Origin
                        </CardTitle>

                        <FormGroup>
                          <label
                            className="form-label text-primary"
                            htmlFor="estado"
                          >
                            Customs Broker Name
                          </label>
                          <input
                            type="text"
                            id="namecbporigen"
                            name="namecbporigen"
                            value={cbporigen ? cbporigen.name : null}
                            onChange={(e) => {
                              setcbporigen({
                                ...cbporigen,
                                name: e.target.value,
                              });
                            }}
                            //    defaultValue={update ? update.estado : null}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-label text-primary"
                            htmlFor="estado"
                          >
                            Contact Name
                          </label>
                          <input
                            type="text"
                            id="estado"
                            name="estado"
                            onChange={(e) => {
                              setcbporigen({
                                ...cbporigen,
                                contactname: e.target.value,
                              });
                            }}
                            value={cbporigen ? cbporigen.contactname : null}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-label text-primary"
                            htmlFor="estado"
                          >
                            Contact Phone
                          </label>
                          <input
                            type="text"
                            id="estado"
                            name="estado"
                            value={cbporigen ? cbporigen.contactphone : null}
                            onChange={(e) => {
                              setcbporigen({
                                ...cbporigen,
                                contactphone: e.target.value,
                              });
                            }}
                            //    defaultValue={update ? update.estado : null}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-label text-primary"
                            htmlFor="estado"
                          >
                            Contact Email
                          </label>
                          <input
                            type="text"
                            id="estado"
                            name="estado"
                            onChange={(e) => {
                              setcbporigen({
                                ...cbporigen,
                                contactemail: e.target.value,
                              });
                            }}
                            value={cbporigen ? cbporigen.contactemail : null}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Button color="danger" onClick={cbporigenbook}>
                            Save Information
                          </Button>
                        </FormGroup>
                      </CardBody>
                    )}
                  </Card>
                </Col>
                <Col md="6">
                  <Card className="card-bordered">
                    {aparece_1 ? (
                      <div className="card-content p-3">
                        <CardImg
                          height={70}
                          width="10"
                          src="logo-black.11958aba.svg"
                          alt=""
                        />
                      </div>
                    ) : null}
                    <CardBody className="card-inner">
                      {/* <CardTitle tag="h5">Card with stretched link</CardTitle> */}

                      <FormGroup>
                        <div className="custom-control custom-control-sm custom-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            checked={aparece_1}
                            onClick={() => setaparece_1(!aparece_1)}
                            onChange={() => {
                              setcbpdestino("");
                              setbookingfinal({
                                ...bookingfinal,
                                cbpdestinobook: "",
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="com-email"
                          >
                            Customs Brokers in USA
                          </label>
                        </div>{" "}
                        <TooltipComponent
                          icon="alert-fill"
                          direction="top"
                          id="Tooltip3"
                          text="You can deselect and to input the importer's customs broker information."
                        />
                      </FormGroup>

                      {aparece_1 ? (
                        <div className="accordion accordian-s3">
                          <div className="accordion-item ">
                            <div
                              className="accordion-head"
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              <h6 className="title text-primary">
                                Required Documents
                              </h6>
                              <span className="accordion-icon"></span>
                            </div>
                            <Collapse
                              className="accordion-body"
                              isOpen={isOpen === true ? true : false}
                            >
                              <div className="accordion-inner">
                                <ul>
                                  <li>Company Articles of Incorporation</li>
                                  <li>
                                    Company Registration (SAT in case of Mexico)
                                  </li>
                                  <li>
                                    Copy of the Legal Representative's Passport
                                    (Foreign) or Identity Card{" "}
                                  </li>
                                  <li>
                                    Copy of Second Representative's Passport
                                    (Foreing) or Identity Card
                                  </li>
                                </ul>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      ) : null}
                    </CardBody>
                    {aparece_1 ? null : (
                      <CardBody className="card-inner">
                        <CardTitle tag="h6">
                          Your Customs Broker in USA
                        </CardTitle>

                        <FormGroup>
                          <label
                            className="form-label text-primary"
                            htmlFor="estado"
                          >
                            Customs Broker Name
                          </label>
                          <input
                            type="text"
                            id="estado"
                            name="estado"
                            onChange={(e) => {
                              setcbpdestino({
                                ...cbpdestino,
                                name: e.target.value,
                              });
                            }}
                            //    defaultValue={update ? update.estado : null}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-label text-primary"
                            htmlFor="estado"
                          >
                            Contact Name
                          </label>
                          <input
                            type="text"
                            id="estado"
                            name="estado"
                            onChange={(e) => {
                              setcbpdestino({
                                ...cbpdestino,
                                contactname: e.target.value,
                              });
                            }}
                            //    defaultValue={update ? update.estado : null}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-label text-primary"
                            htmlFor="estado"
                          >
                            Contact Phone
                          </label>
                          <input
                            type="text"
                            id="estado"
                            name="estado"
                            onChange={(e) => {
                              setcbpdestino({
                                ...cbpdestino,
                                contactphone: e.target.value,
                              });
                            }}
                            //    defaultValue={update ? update.estado : null}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-label text-primary"
                            htmlFor="estado"
                          >
                            Contact Email
                          </label>
                          <input
                            type="text"
                            id="estado"
                            name="estado"
                            onChange={(e) => {
                              setcbpdestino({
                                ...cbpdestino,
                                contactemail: e.target.value,
                              });
                            }}
                            //    defaultValue={update ? update.estado : null}
                            className="form-control"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Button color="danger" onClick={cbpdestinobook}>
                            Save Information
                          </Button>
                        </FormGroup>
                      </CardBody>
                    )}
                  </Card>
                </Col>

                <Col size="12">
                  <div className="text-muted">
                    Note: Do not worry if you do not have your legal documents
                    at this time. You can upload your legal documents later in
                    the legal documents section of the dashobard
                  </div>
                  <br></br>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalBooking;

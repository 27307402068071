const PostTag = async (id, tagspec) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "contact_id": id,
      "tag": tagspec
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    const response = await fetch(
		`https://apiecl.com/tagcrm`,
		requestOptions
	  );
	  const respuesta = response.text();
	  console.log(respuesta);
	  return respuesta;
   
    }
export default PostTag;

/* eslint-disable import/prefer-default-export */
export const GetSku = async idcliente => {
	
	
	const requestOptions = {
		method: 'GET',
		redirect: 'follow'
		
	};

	/* const resp = await fetch(`http://localhost:3002/api/product/${idcliente}`, requestOptions); */
	const resp = await fetch(`https://apiecl.com/getsku?id_seller=${idcliente}`, requestOptions);
	//const resp = await fetch(`https://vwat0hqjt0.execute-api.us-east-1.amazonaws.com/dev/getsku`, requestOptions);
	console.log(resp)
	const sku = await resp.json();
	return sku;
};

export const GetOpsuno = async (id) => {
    
    var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const response = await fetch(
    `https://apiecl.com/getopb2buno?id=${id}`,
    requestOptions
  );
  const respuesta = response.json();
  return respuesta;
};
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { RSelect } from "../../components/Component";
import { codes, byAlpha2, byAlpha3, byNumeric } from "iso-country-codes";
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import * as yup from "yup";
import Center from "react-center";

const validationSchema = yup.object({
  description: yup.string().required("Description is Required"),
  units: yup.string().required("Qty is Required"),
  unitunits: yup.string().required("Unit is Required"),
  w: yup.string().required("Weight/Unit is Required"),
  wunits: yup.string().required("Unit is Required"),
  manufacturer: yup.string().required("Manufacturer is Required"),
  maddress: yup.string().required("Address is Required"),
  mcity: yup.string().required("City is Required"),
  mcountry: yup.string().required("Country is Required"),
});

const Products = ({ pn, setpn, setActiveStep }) => {
  const [eventos, seteventos] = useState({
    lista: [],
  });

  const [option, setoption] = useState({
    
    refused: "NO",
    donde: "",

  });

  const formik = useFormik({
    initialValues: {
      description: "",
      units: "",
      unitunits: "",
      w: "",
      wunits: "",
      manufacturer: "",
      maddress: "",
      mcity: "",
      mstate: "",
      mcountry: "",
      mzipcode: "",
      mfda: "",
      mcomments: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const id_eventos = eventos.lista.length + 1;
      seteventos({
        lista: [
          ...eventos.lista,
          {
            id: id_eventos,
            description: values.description,
            units: values.units,
            unitunits: values.unitunits,
            w: values.w,
            wunits: values.w,
            manufacturer: values.manufacturer,
            maddress: values.maddress,
            mcity: values.mcity,
            mstate: values.mstate,
            mcountry: values.mcountry,
            mzipcode: values.mzipcode,
            mfda: values.mfda,
            mcomments: values.mcomments,
            refused: option.refused,
            donde: option.donde
          },
        ],
      });

      resetForm({ values: "" });
    },
  });


  const optionchange = (e) => {
    setoption({
      ...option,
      refused: e.target.value,
    });
  };

  const sichange = (e) => {
    setoption({
      ...option,
      donde: e.target.value,
    });
  };

  const resumen = () => {
    setActiveStep(4);
    setpn({ ...pn, products: eventos.lista });
  };

  const volver = () => {
    setActiveStep(2);
    setpn({ ...pn, products: eventos.lista });
  };

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button onClick={clickHandler}>
        <img
          src="https://fotos-ecl.s3.amazonaws.com/icons8-eliminar-64.png"
          alt="edit"
          width="20"
          height="20"
        />
      </Button>
    );
  };

  const deleterow = (e) => {
    const newstate = eventos.lista.filter((item) => item.id !== e);
    seteventos({ eventos: newstate });
  };
  const units = [
    { value: "Bolsas", label: "Bolsas" },
    { value: "Botella", label: "Botella" },
    { value: "Caja", label: "Caja" },
    { value: "Canasta", label: "Canasta" },
    { value: "Bulto", label: "Bulto" },
    { value: "Lata", label: "Lata" },
    { value: "Cartones", label: "Cartones" },
    { value: "Tambores", label: "Tambores" },
  ];

  const unidades = [
    { value: "Gallons", label: "Gallons" },
    { value: "Grams", label: "Grams" },
    { value: "Kg", label: "Kg" },
    { value: "Lbs", label: "Lbs" },
    { value: "Liters", label: "Liters" },
    { value: "Ounces", label: "Ounces" },
  ];

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Units",
      selector: (row) => row.units,
    },
    {
      name: "Weight/unit",
      selector: (row) => row.w,
    },
    {
      name: "Manufacturer",
      selector: (row) => row.manufacturer,
    },
    {
      name: "Address",
      selector: (row) => row.maddress,
    },
    {
      name: "City",
      selector: (row) => row.mcity,
    },
    {
      name: "State/Province",
      selector: (row) => row.mstate,
    },
    {
      name: "Country",
      selector: (row) => row.mcountry,
    },
    {
      name: "Zip Code",
      selector: (row) => row.mzipcode,
    },
    {
      name: "Refused",
      selector: (row) => row.refused,
    },


    {
      name: "Actions",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row) => (
        <ActionComponent row={row.id} onClick={deleterow}></ActionComponent>
      ),
    },
  ];

  const data = [];

  return (
    <div>
      <form noValidate onSubmit={formik.handleSubmit} id="validation-forms">
        <Row className="g-4">
          <Col lg="6">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="companyname">
                Detailed product description *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="description"
                  name="description"
                  className="form-control"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.description && formik.errors.description}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="fdaregistration">
                Number Units *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="units"
                  name="units"
                  className="form-control"
                  value={formik.values.units}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.units && formik.errors.units}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="fdaregistration">
                Unit
              </label>
              <RSelect
                options={units}
                id="unitunits"
                name="unitunits"
                onChange={(selectedOption) =>
                  formik.setFieldValue("unitunits", selectedOption.value)
                }
                defaultValue={formik.values.unitunits}
              />
              <span className="text-danger">
                {formik.touched.unitunits && formik.errors.unitunits}
              </span>
              <strong className="text-info">
                {formik.values.unitunits
                  ? `Selected: ${formik.values.unitunits}`
                  : null}
              </strong>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="fdaregistration">
                Weight/Unit *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="w"
                  name="w"
                  className="form-control"
                  value={formik.values.w}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.w && formik.errors.w}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="fdaregistration">
                Unit
              </label>
              <RSelect
                options={unidades}
                id="wunits"
                name="wunits"
                onChange={(selectedOption) =>
                  formik.setFieldValue("wunits", selectedOption.value)
                }
                defaultValue={formik.values.wunits}
              />
              <span className="text-danger">
                {formik.touched.wunits && formik.errors.wunits}
              </span>
              <strong className="text-info">
                {formik.values.wunits
                  ? `Selected: ${formik.values.wunits}`
                  : null}
              </strong>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="contactname">
                Manufacturer *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="manufacturer"
                  name="manufacturer"
                  className="form-control"
                  value={formik.values.manufacturer}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.manufacturer && formik.errors.manufacturer}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="5">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="address">
                Address *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="maddress"
                  name="maddress"
                  className="form-control"
                  value={formik.values.maddress}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.maddress && formik.errors.maddress}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="city">
                City *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="mcity"
                  name="mcity"
                  className="form-control"
                  value={formik.values.mcity}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.mcity && formik.errors.mcity}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="state">
                State/Province
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="mstate"
                  name="mstate"
                  className="form-control"
                  value={formik.values.mstate}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.mstate && formik.errors.mstate}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="country">
                Country *
              </label>
              <div className="form-control-wrap">
                <RSelect
                  options={countries}
                  id="mcountry"
                  name="mcountry"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("mcountry", selectedOption.value)
                  }
                  defaultValue={formik.values.mcountry}
                />
                <span className="text-danger">
                  {formik.touched.mcountry && formik.errors.mcountry}
                </span>
                <strong className="text-info">
                  {formik.values.mcountry
                    ? `Selected: ${formik.values.mcountry}`
                    : null}
                </strong>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="zipcode">
                Zip Code
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="mzipcode"
                  name="mzipcode"
                  className="form-control"
                  value={formik.values.mzipcode}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.mzipcode && formik.errors.mzipcode}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="zipcode">
                FDA Register Number
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="mfda"
                  name="mfda"
                  className="form-control"
                  value={formik.values.mfda}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.mfda && formik.errors.mfda}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-label" htmlFor="fdaregistration">
                Has the detailed product been refused entry into another
                country? *
              </label>
              <ul className="custom-control-group g-3 align-center flex-wrap">
                <li>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input form-control"
                      defaultChecked
                      name="purpose"
                      id="purpose1"
                      value="NO"
                      onChange={optionchange}
                    />
                    <label className="custom-control-label" htmlFor="purpose1">
                      No, this product has never been rejected at the entrance
                      of another country.
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input form-control"
                      name="purpose"
                      id="purpose6"
                      value="SI"
                      onChange={optionchange}
                    />
                    <label className="custom-control-label" htmlFor="purpose6">
                      Yes, this product has been rejected in:
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-control-wrap">
                    <input 
                    type="text" 
                    id="other" 
                    className="form-control"
                    value={option.donde}
                    onChange={sichange}
                    />
                  </div>
                </li>
              </ul>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="zipcode">
                Comments
              </label>
              <div className="form-control-wrap">
                <textarea
                  type="text"
                  id="mcomments"
                  name="mcomments"
                  className="form-control"
                  value={formik.values.mcomments}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.mcomments && formik.errors.mcomments}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="12">
            <Center>
              <FormGroup>
                <br></br>
                <Button  type="submit" color="primary" size="lg">
                  Add Product to List
                </Button>
              </FormGroup>
            </Center>
          </Col>
          <Col lg="12">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="zipcode">
                Products List
              </label>
              <div className="form-control-wrap">
                <DataTable columns={columns} data={eventos.lista}></DataTable>
              </div>
            </FormGroup>
          </Col>
          <Row className="g-4">
            <Col lg="12">
              <Button onClick={volver}>Back</Button>&nbsp;&nbsp;&nbsp;&nbsp;
              <Button onClick={resumen}>Next</Button>
            </Col>
          </Row>
        </Row>
      </form>

      {/*<ActionButtons {...props} nextStep={validate} /> */}
    </div>
  );
};

export default Products;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Icon from "../../components/icon/Icon";
import classnames from "classnames";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
} from "../../components/block/Block";
import { PreviewCard, CodeBlock } from "../../components/preview/Preview";
import {
  Button,
  BlockBetween,
  TooltipComponent,
  OverlineTitle,
  RSelect,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Switch from "react-switch";
import Stepform from "./stepform";
import History from "./history";



const Principal = () => {
  const [activeIconTab, setActiveIconTab] = useState("6");

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  const [active, setActive] = useState(1);

  const handleNextPrevClick = (a) => {
    setActive(a);
  };

  const handleSubmission = () => {
    alert("Form submitted!");
  };

  //

  return (
    <>
      <React.Fragment>
        <Head title="GSA SELLER Prior Notice Support"></Head>
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>FDA Prior Notice US</BlockTitle>
                <BlockDes className="text-soft">
                  <p>Easy, Reliable and Fast</p>
                </BlockDes>
              </BlockHeadContent>
              {/* <BlockHeadContent>
                <ul className="nk-block-tools g-3">
                  <li>
                    <Button color="light" outline className="btn-white">
                      <Icon name="download-cloud"></Icon>
                      <span>Back</span>
                    </Button>
                  </li>
                </ul>
              </BlockHeadContent> */}
            </BlockBetween>
          </BlockHead>
          <div className="card-stretch">
            <div className="card-inner"></div>
            <Block size="lg">
              <PreviewCard>
                <Nav tabs>
                <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "6" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("6");
                      }}
                    >
                      <Icon name="histroy" /> <span>History</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "5" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("5");
                      }}
                    >
                      <Icon name="notes-alt" /> <span>New Request</span>
                    </NavLink>
                  </NavItem>
          
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "7" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("7");
                      }}
                    >
                      <Icon name="coin-alt" /> <span>Purchase</span>
                    </NavLink>
                  </NavItem>
                 { <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeIconTab === "8" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("8");
                      }}
                    >
                      <Icon name="setting-alt" /> <span>Preferences</span>
                    </NavLink>
                  </NavItem>}
                    </Nav> 
                <TabContent activeTab={activeIconTab}>
                  <TabPane tabId="5">
                    <p>
                      The U.S. Food and Drug Administration (U.S. FDA) requires
                      the filing of Prior Notice for all food, beverage, and
                      dietary supplements shipments entering the United States.
                      Upon receipt of Prior Notice, FDA will issue a
                      confirmation number in the form of a bar code that must
                      accompany most food shipments.
                    </p>
                    <Stepform setActiveIconTab={setActiveIconTab}></Stepform>
                  </TabPane>
                  <TabPane tabId="6">
                
                   { <History></History> }
                   
                  </TabPane>
                  <TabPane tabId="7">
                    <p>
                      
                    </p>
                  </TabPane>
                  <TabPane tabId="8">
                    <p>
                   
                    </p>
                  </TabPane>
                </TabContent>
              </PreviewCard>
            </Block>
          </div>
        </Content>
      </React.Fragment>
    </>
  );
};
export default Principal;

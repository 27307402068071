/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
} from "../../components/Component";

import { useAuth0 } from "@auth0/auth0-react";
import ListHtsTools from "./ListHtsTools";
import Htsbegin from "./htsbegin";
import HtsGrid2 from "./HtsGrid2";
import HtsGrid from "./HtsGrid";

export default function Principal() {
  // const { isAuthenticated, logout, user } = useAuth0();
  const [encabezado, setencabezado] = useState([
    {
      country: "",
      hts: "",
      hidden: false,
      hidden1: false,
      destination: "",
      hts8: "",
      general: "",
      special: "",
      duties: "",
      dutiespecific: "",
      list301: "",
      duties301: "",
    },
  ]);
  return (
    <div>
      <React.Fragment>
        <Head title="GSA Dashboard" />
        <Content>
          <BlockHead size="sm">
            <div className="nk-block-between">
              <BlockHeadContent>
                <BlockTitle page tag="h3">
                  HTS Classification
                </BlockTitle>
                <BlockDes className="text-soft">
                  <p>Welcome to HTS Tools</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent></BlockHeadContent>
            </div>
          </BlockHead>
          <Block>
            <Row className="g-gs">
              <Col lg="4" md="4">
                <PreviewAltCard className="border-secondary h-10">
                <ListHtsTools
                    encabezado={encabezado}
                    setencabezado={setencabezado}
                  ></ListHtsTools> 
                </PreviewAltCard>
              </Col>
              <br></br>
              <Col lg="8" md="8">
                <PreviewAltCard className="h-100 border-secondary">
               <Htsbegin setencabezado={setencabezado}></Htsbegin> 
                </PreviewAltCard>
              </Col>{" "}
              <Col lg="12">
                <PreviewAltCard className="h-100 border-secondary">
               {encabezado.hidden && (
                    <HtsGrid2
                      encabezado={encabezado}
                      setencabezado={setencabezado}
                    />
                  )} 
              {encabezado.hidden1 && (
                    <HtsGrid
                      encabezado={encabezado}
                      setencabezado={setencabezado}
                    />
                  )} 
                </PreviewAltCard>
              </Col>{" "}
            </Row>
          </Block>
        </Content>
      </React.Fragment>
    </div>
  );
}

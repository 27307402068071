/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, FormGroup, Collapse } from "reactstrap";
import {
  Button,
  Icon,
  Col,
  Row,
  RSelect,
  Block,
} from "../../../components/Component";
import { TooltipComponent } from "../../../components/Component";
import { codes } from "iso-country-codes";
//import { Updateseller } from "../helpers/updateseller";
import Swal from "sweetalert2";

import { useAuth0 } from "@auth0/auth0-react";

import DocumentsExport from "./documents";
import Content from "../../../layout/content/Content";

import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";
import { toast } from "react-toastify";


//import Lister from "../../dashboard/components/SellerListFiles";
const FilesBooking = ({
  modal,
  setModal,
  modalTab,
  setModalTab,
  bookingvalue,
  setbookingvalue,
}) => {
  var today = new Date();
  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };
  const CustomToast = () => {
    return (
      <div className="toastr-text">
        <h5>Save Successfully</h5>
        <p>Customs Broker was added</p>
      </div>
    );
  };

  const { user } = useAuth0();
  const [escondido, setescondido] = useState(false);
  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  //const { isAuthenticated, logout, user } = useAuth0();
  const [pickup, setpickup] = useState();
  const [cbporigen, setcbporigen] = useState();
  const [cbpdestino, setcbpdestino] = useState();
  const [bookingfinal, setbookingfinal] = useState({});
  //Aparece on no aparece agente de aduanas en origne
  const [aparece, setaparece] = useState(true);
  const [aparece_1, setaparece_1] = useState(true);

  const [selectpickup, setselectpickup] = useState();

  const [isOpen, setIsOpen] = useState(false);
  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered"
      size="lg"
      toggle={() => setModal(false)}
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            setModal(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Shipping Documents</h5>
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "3" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("3");
                }}
                href="#address"
              >
                Documents
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTab === "3" ? "active" : ""}`}
              id="address"
            >
              <Row className="gy-4">
                {" "}
                <Col size="12">
                  <Content>
                    <DocumentsExport
                      referencia={bookingvalue ? bookingvalue[0].ref : null}
                    ></DocumentsExport>
                  </Content>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        Close
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FilesBooking;

import React from "react";
import Principal from "./components/principal";


const Compliance = ({ ...props }) => {
  return (
    <React.Fragment>
      <Principal></Principal>
    </React.Fragment>
  );
};

export default Compliance;
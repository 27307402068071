/* eslint-disable no-undef */
import React, { useState } from "react";
import { Modal, ModalBody, FormGroup, ModalFooter } from "reactstrap";
import { Button, Icon, Col, Row, RSelect } from "../../components/Component";
import { codes } from "iso-country-codes";
import { useAuth0 } from "@auth0/auth0-react";
import Swal from "sweetalert2";
import Content from "../../layout/content/Content";
const ModalAppoint = ({ modalap, setModalap, link, id_m }) => {
  const { isAuthenticated, logout, user } = useAuth0();

  var pusher = new Pusher("3cd30ced34ecff7cc5dc", {
    cluster: "us2",
  });

  var channel = pusher.subscribe("my-channel");
  channel.bind(id_m, function (data) {
    Swal.fire({
      icon: "success",
      title: "Congratulations",
      text: "Congratulations your appointment has been Cancelled.",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setModalap(false);
      }
    });
  });

  return (
    <Modal
      isOpen={modalap}
      className="modal-centered"
      size="lg"
      toggle={() => setModalap(false)}
    >
      <ModalBody>
        <Content>
          <img
            width="140"
            height="80"
            src="logo_transparent_background.png"
            alt=""
          />
        </Content>
        <iframe
          src={link}
          width="100%"
          height="300"
          title="Cancel Appointment"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </ModalBody>{" "}
      <ModalFooter className="bg-light">
        <img
          width="100"
          height="100"
          src="amazon-service_partners-badge (1).png"
          alt=""
        />
      </ModalFooter>
    </Modal>
  );
};

export default ModalAppoint;

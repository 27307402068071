export const GetPickups = async (cliente) => {
  
   
    var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const response = await fetch(
    `https://apiecl.com/getpickup?id_seller=${cliente}`,
    requestOptions
  );
  const respuesta = response.json();
  return respuesta;
};
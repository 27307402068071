/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { CardTitle } from "reactstrap";
import { Icon, TooltipComponent, Button } from "../../components/Component";
//import ModalSeller from "./modalseller";

import DataTable from "react-data-table-component";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween,
  BlockContent,
  BlockImage,
  OrderTable,
} from "../../components/Component";
import { Card, Badge } from "reactstrap";
//import { BarChart } from "../../charts/default/Charts";
import Swal from "sweetalert2";
import classNames from "classnames";
//import Documents from "./documents";
import { useAuth0 } from "@auth0/auth0-react";
import { GetDocumentos } from "../helpers/getdocumentos";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Usegetprecios from "../hooks/usegetprecios";
import { CrearInvoiceStripe } from "../../invoicing/helpers/crearinvoicestripe";
import { Link } from "react-router-dom";
import PaypalDiagnostico from "../../paypal/components/pagodiagnostico";
import PaypalFior from "../../paypal/components/pagofior";
import PaypalBond from "../../paypal/components/pagobond";
import PaypalOrigen from "../../paypal/components/pagoorigen";
import Paypaldetalle from "../../paypal/components/pagodetalle";
import { GetSeller } from "../../inicio/helpers/getseller";

const ComplianceR = ({ sethidden }) => {
  const { isAuthenticated, logout, user } = useAuth0();
  // const clave = `${user.sub}/compliance`;
  const [modalLg, setModalLg] = useState(false);
  const toggleLg = () => setModalLg(!modalLg);
  const [archivos, setarchivos] = useState();
  const [documents, setdocuments] = useState();
  const [seller, setseller] = useState();
  const pcr = Usegetprecios("PCR"); //Obtiene el precio del reporte de productos
  const pcrfinal = pcr ? pcr.price[0] : null;
  const poc = Usegetprecios("POC"); //Obtiene el precio del reporte de productos
  const pocfinal = poc ? poc.price[0] : null;
  const grr = Usegetprecios("GRR");
  const grrfinal = grr ? grr.price[0] : null;
  const fior = Usegetprecios("FIOR");
  const fiorfinal = fior ? fior.price[0] : null;
  const bond = Usegetprecios("BOND");
  const bondfinal = bond ? bond.price[0] : null;
  const cmex = Usegetprecios("CMEX");
  const cmexfinal = cmex ? cmex.price[0] : null;
  const ior = Usegetprecios("IOR");
  const iorfinal = ior ? ior.price[0] : null;
  const apc = Usegetprecios("APC");
  const apcfinal = apc ? apc.price[0] : null;
  const cbl = Usegetprecios("CBL");
  const cblfinal = cbl ? cbl.price[0] : null;
  const dns = Usegetprecios("DNS");
  const dnsfinal = dns ? dns.price[0] : null;
  const frn = Usegetprecios("FRN");
  const frnfinal = frn ? frn.price[0] : null;
  const fsvp = Usegetprecios("FSVP");
  const fsvpfinal = fsvp ? fsvp.price[0] : null;
  const lbi = Usegetprecios("LBI");
  const lbifinal = lbi ? lbi.price[0] : null;

  useEffect(() => {
    GetSeller(user?.sub).then((response) => {
      setseller(response[0]);
    });
  }, [setseller, user]);


  
  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };

  // Crear factura para pago y crea en la lista de historicos
  function crearfactura(
    id_precio,
    centro_costo,
    service_name,
    codigo,
    id_evento
  ) {
    Swal.fire({
      icon: "warning",
      title: "Do you want request the Service?",
      text: "We will generate an invoice for your request!",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: "I Agree",
      denyButtonText: `Cancel Request`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire({
          didOpen: () => {
            Swal.showLoading();
          },
          icon: "success",
          title: "Wait Please... Invoice being created",
          showConfirmButton: false,
          timer: 2000,
        });
        CrearInvoiceStripe(
          seller.id_stripe,
          id_precio,
          centro_costo,
          user.sub,
          service_name,
          codigo,
          id_evento
        ).then((response) => {
          sethidden({
            prionotice: false,
            compliancelist: true,
            compliancenew: false,
          });
          alert(response.id_invoice)
        });
      } else if (result.isDenied) {
        Swal.fire("Services Request Cancelled", "", "info");
      }
    });
  }

  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h5 className="title text-primary">Compliance Services</h5>
        </CardTitle>
        <div className="card-tools text-secondary">
          {/* <TooltipComponent
            icon="help-fill"
            iconClass="card-hint"
            direction="top"
            id="tooltip_compliance"
            text="Request Compliance Services"
          />{" "} */}
          &nbsp; &nbsp; &nbsp;
          {/* <Link to={"/compliance"}>
            <Button size="sm" color="primary">
              <Icon name="archived" />
              <span>Services Requested</span>&nbsp;&nbsp;
            </Button>
          </Link> */}
        </div>
      </div>
      <br></br>
      {/* <div className="text-center">
        <Badge color="secondary">Go to Details</Badge> &nbsp; &nbsp; &nbsp;
        <Badge color="secondary">Go to Details</Badge> &nbsp; &nbsp; &nbsp;
        <Badge color="secondary">Go to Details</Badge>
      </div> */}
      <Row>
        <Col lg="6">
          <CardTitle>
            <span className="text-muted">
              <strong>
                Stage 1: Initial Diagnostic, Preliminary Assessment
              </strong>
            </span>
            <hr></hr>
          </CardTitle>
          <Card className="card-bordered card-preview">
            <div className="table-responsive">
              <table className="table table-tranx is-compact">
                <thead>
                  <tr>
                    <th className="text-primary">Services</th>
                    <th className="text-primary">Cost</th>
                    <th className="text-primary text-center">Request</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-tnx-info">
                      {grrfinal ? grrfinal.name : null}
                      <TooltipComponent
                        icon="help-fill"
                        direction="right"
                        id="tooltip_grr"
                        text="This diagnosis is an evaluation of the risks and the feasibility of compliance with the regulations imposed by government agencies in the USA for imported products"
                      />{" "}
                    </td>

                    <td>US {grrfinal ? grrfinal.price : null}</td>

                    <td className="text-center">
                      {/* <PaypalDiagnostico></PaypalDiagnostico> */}
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() =>
                          crearfactura(
                            grrfinal ? grrfinal.id_precio : null,
                            grrfinal ? grrfinal.centro_costo : null,
                            grrfinal ? grrfinal.name : null,
                            grrfinal ? grrfinal.codigo : null,
                            grrfinal ? grrfinal.id_evento : null
                          )
                        }
                      >
                        Buy Now
                      </Button>{" "}
                    </td>
                  </tr>
                  {/* <tr>
                  <td>
                    Compliance Call (30 Minutes)
                    <TooltipComponent
                      icon="help-fill"
                      direction="right"
                      id="tooltip_seller_2"
                      text="Definición y aplicación de las Reglas de Origen a los productos con el fin de apoyar en la aplicación de los Tratados de Libre Comercio"
                    />{" "}
                  </td>
                  <td>USD 79</td>
                  <td className="text-center">
                    <Button
                      onClick={openWin}
                      className="btn-round btn-icon"
                      color="danger"
                      size="xs"
                    >
                      <Icon name="play" />
                    </Button>{" "}
                  </td>
                </tr> */}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
        <Col lg="6">
          <CardTitle>
            <span className="text-muted">
              <strong>Stage 2: Preparing the Seller</strong>
            </span>
            <hr></hr>
          </CardTitle>
          <Card className="card-bordered card-preview">
            <div className="table-responsive">
              <table className="table table-tranx is-compact">
                <thead>
                  <tr>
                    <th width="250" className="text-primary">
                      Services
                    </th>
                    <th className="text-primary">Cost</th>
                    <th className="text-primary text-center">Request</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb-tnx-info">
                      {fiorfinal ? fiorfinal.name : null}
                      <TooltipComponent
                        icon="help-fill"
                        direction="right"
                        id="tooltip_fior"
                        text={fiorfinal ? fiorfinal.details_1 : null}
                      />{" "}
                    </td>

                    <td>US {fiorfinal ? fiorfinal.price : null}</td>
                    <td className="text-center">
                      {/* <PaypalFior></PaypalFior> */}
                      {/* <PaypalDiagnostico></PaypalDiagnostico> */}
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() =>
                          crearfactura(
                            fiorfinal ? fiorfinal.id_precio : null,
                            fiorfinal ? fiorfinal.centro_costo : null,
                            fiorfinal ? fiorfinal.name : null,
                            fiorfinal ? fiorfinal.codigo : null,
                            fiorfinal ? fiorfinal.id_evento : null
                          )
                        }
                      >
                        Buy Now
                      </Button>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {bondfinal ? bondfinal.name : null}
                      <TooltipComponent
                        icon="help-fill"
                        direction="right"
                        id="tooltip_bond"
                        text={bondfinal ? bondfinal.details_1 : null}
                      />{" "}
                    </td>

                    <td>US {bondfinal ? bondfinal.price : null}</td>
                    <td className="text-center">
                      {/* <PaypalBond></PaypalBond> */}
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() =>
                          crearfactura(
                            bondfinal ? bondfinal.id_precio : null,
                            bondfinal ? bondfinal.centro_costo : null,
                            bondfinal ? bondfinal.name : null,
                            bondfinal ? bondfinal.codigo : null,
                            bondfinal ? bondfinal.id_evento : null
                          )
                        }
                      >
                        Buy Now
                      </Button>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {cmexfinal ? cmexfinal.name : null}
                      <TooltipComponent
                        icon="help-fill"
                        direction="right"
                        id="tooltip_cmex"
                        text={cmexfinal ? cmexfinal.details_1 : null}
                      />{" "}
                    </td>

                    <td>US {cmexfinal ? cmexfinal.price : null}</td>
                    <td className="text-center">
                      {/* <PaypalBond></PaypalBond> */}
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() =>
                          crearfactura(
                            cmexfinal ? cmexfinal.id_precio : null,
                            cmexfinal ? cmexfinal.centro_costo : null,
                            cmexfinal ? cmexfinal.name : null,
                            cmexfinal ? cmexfinal.codigo : null,
                            cmexfinal ? cmexfinal.id_evento : null
                          )
                        }
                      >
                        Buy Now
                      </Button>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {iorfinal ? iorfinal.name : null}
                      <TooltipComponent
                        icon="help-fill"
                        direction="right"
                        id="tooltip_ior"
                        text={iorfinal ? iorfinal.details_1 : null}
                      />{" "}
                    </td>

                    <td>US {iorfinal ? iorfinal.price : null}</td>
                    <td className="text-center">
                      {/* <PaypalBond></PaypalBond> */}
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() =>
                          crearfactura(
                            iorfinal ? iorfinal.id_precio : null,
                            iorfinal ? iorfinal.centro_costo : null,
                            iorfinal ? iorfinal.name : null,
                            iorfinal ? iorfinal.codigo : null,
                            iorfinal ? iorfinal.id_evento : null
                          )
                        }
                      >
                        Buy Now
                      </Button>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col lg="6">
          <Block size="lg">
            <CardTitle>
              <span className="text-muted">
                <strong>Stage 3: Preparing the Product</strong>
              </span>
              <hr></hr>
            </CardTitle>
            <Card className="card-bordered card-preview">
              <div className="table-responsive">
                <table className="table table-tranx is-compact">
                  <thead>
                    <tr>
                      <th width="250" className="text-primary">
                        Services
                      </th>
                      <th className="text-primary">Cost</th>
                      <th className="text-primary text-center">Request</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                  <td>
                    Compliance Call (30 Minutes)
                    <TooltipComponent
                      icon="help-fill"
                      direction="right"
                      id="tooltip_seller_2"
                      text="Definición y aplicación de las Reglas de Origen a los productos con el fin de apoyar en la aplicación de los Tratados de Libre Comercio"
                    />{" "}
                  </td>
                  <td>USD 79</td>
                  <td className="text-center">
                    <Button
                      onClick={openWin}
                      className="btn-round btn-icon"
                      color="danger"
                      size="xs"
                    >
                      <Icon name="play" />
                    </Button>{" "}
                  </td>
                </tr> */}
                    <tr>
                      <td>
                        {pocfinal ? pocfinal.name : null}
                        <TooltipComponent
                          icon="help-fill"
                          direction="right"
                          id="tooltip_poc"
                          text={pocfinal ? pocfinal.details_1 : null}
                        />{" "}
                      </td>

                      <td>US {pocfinal ? pocfinal.price : null}</td>
                      <td className="text-center">
                        {/* <PaypalOrigen></PaypalOrigen> */}
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() =>
                            crearfactura(
                              pocfinal ? pocfinal.id_precio : null,
                              pocfinal ? pocfinal.centro_costo : null,
                              pocfinal ? pocfinal.name : null,
                              pocfinal ? pocfinal.codigo : null,
                              pocfinal ? pocfinal.id_evento : null
                            )
                          }
                        >
                          Buy Now
                        </Button>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {pcrfinal ? pcrfinal.name : null}
                        <TooltipComponent
                          icon="help-fill"
                          direction="right"
                          id="tooltip_pcr"
                          text={pcrfinal ? pcrfinal.details_1 : null}
                        />{" "}
                      </td>

                      <td>US {pcrfinal ? pcrfinal.price : null}</td>
                      <td className="text-center">
                        {/* <Paypaldetalle></Paypaldetalle> */}
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() =>
                            crearfactura(
                              pcrfinal ? pcrfinal.id_precio : null,
                              pcrfinal ? pcrfinal.centro_costo : null,
                              pcrfinal ? pcrfinal.name : null,
                              pcrfinal ? pcrfinal.codigo : null,
                              pcrfinal ? pcrfinal.id_evento : null
                            )
                          }
                        >
                          Buy Now
                        </Button>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {apcfinal ? apcfinal.name : null}
                        <TooltipComponent
                          icon="help-fill"
                          direction="right"
                          id="tooltip_apc"
                          text={apcfinal ? apcfinal.details_1 : null}
                        />{" "}
                      </td>
                      <td>US {apcfinal ? apcfinal.price : null}</td>
                      <td className="text-center">
                        {/* <Paypaldetalle></Paypaldetalle> */}
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() =>
                            crearfactura(
                              apcfinal ? apcfinal.id_precio : null,
                              apcfinal ? apcfinal.centro_costo : null,
                              apcfinal ? apcfinal.name : null,
                              apcfinal ? apcfinal.codigo : null,
                              apcfinal ? apcfinal.id_evento : null
                            )
                          }
                        >
                          Buy Now
                        </Button>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {cblfinal ? cblfinal.name : null}
                        <TooltipComponent
                          icon="help-fill"
                          direction="right"
                          id="tooltip_cbl"
                          text={cblfinal ? cblfinal.details_1 : null}
                        />{" "}
                      </td>

                      <td>US {cblfinal ? cblfinal.price : null}</td>
                      <td className="text-center">
                        {/* <Paypaldetalle></Paypaldetalle> */}
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() =>
                            crearfactura(
                              cblfinal ? cblfinal.id_precio : null,
                              cblfinal ? cblfinal.centro_costo : null,
                              cblfinal ? cblfinal.name : null,
                              cblfinal ? cblfinal.codigo : null,
                              cblfinal ? cblfinal.id_evento : null
                            )
                          }
                        >
                          Buy Now
                        </Button>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
              </div>
            </Card>
          </Block>
        </Col>
        <Col lg="6">
          <Block size="lg">
            <CardTitle>
              <span className="text-muted">
                <strong>Stage 4: Special Services</strong>
              </span>
              <hr></hr>
            </CardTitle>
            <Card className="card-bordered card-preview">
              <div className="table-responsive">
                <table className="table table-tranx is-compact">
                  <thead>
                    <tr>
                      <th width="250" className="text-primary">
                        Services
                      </th>
                      <th className="text-primary">Cost</th>
                      <th className="text-primary text-center">Request</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                  <td>
                    Compliance Call (30 Minutes)
                    <TooltipComponent
                      icon="help-fill"
                      direction="right"
                      id="tooltip_seller_2"
                      text="Definición y aplicación de las Reglas de Origen a los productos con el fin de apoyar en la aplicación de los Tratados de Libre Comercio"
                    />{" "}
                  </td>
                  <td>USD 79</td>
                  <td className="text-center">
                    <Button
                      onClick={openWin}
                      className="btn-round btn-icon"
                      color="danger"
                      size="xs"
                    >
                      <Icon name="play" />
                    </Button>{" "}
                  </td>
                </tr> */}
                    <tr>
                      <td>
                        {dnsfinal ? dnsfinal.name : null}
                        <TooltipComponent
                          icon="help-fill"
                          direction="right"
                          id="tooltip_dns"
                          text={dnsfinal ? dnsfinal.details_1 : null}
                        />{" "}
                      </td>

                      <td>US {dnsfinal ? dnsfinal.price : null}</td>
                      <td className="text-center">
                        {/* <PaypalOrigen></PaypalOrigen> */}
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() =>
                            crearfactura(
                              dnsfinal ? dnsfinal.id_precio : null,
                              dnsfinal ? dnsfinal.centro_costo : null,
                              dnsfinal ? dnsfinal.name : null,
                              dnsfinal ? dnsfinal.codigo : null,
                              dnsfinal ? dnsfinal.id_evento : null
                            )
                          }
                        >
                          Buy Now
                        </Button>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {frnfinal ? frnfinal.name : null}
                        <TooltipComponent
                          icon="help-fill"
                          direction="right"
                          id="tooltip_frn"
                          text={frnfinal ? frnfinal.details_1 : null}
                        />{" "}
                      </td>

                      <td>US {frnfinal ? frnfinal.price : null}</td>
                      <td className="text-center">
                        {/* <Paypaldetalle></Paypaldetalle> */}
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() =>
                            crearfactura(
                              frnfinal ? frnfinal.id_precio : null,
                              frnfinal ? frnfinal.centro_costo : null,
                              frnfinal ? frnfinal.name : null,
                              frnfinal ? frnfinal.codigo : null,
                              frnfinal ? frnfinal.id_evento : null
                            )
                          }
                        >
                          Buy Now
                        </Button>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {fsvpfinal ? fsvpfinal.name : null}
                        <TooltipComponent
                          icon="help-fill"
                          direction="right"
                          id="tooltip_fsvp"
                          text={fsvpfinal ? fsvpfinal.details_1 : null}
                        />{" "}
                      </td>
                      <td>US {fsvpfinal ? fsvpfinal.price : null}</td>
                      <td className="text-center">
                        {/* <Paypaldetalle></Paypaldetalle> */}
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() =>
                            crearfactura(
                              fsvpfinal ? fsvpfinal.id_precio : null,
                              fsvpfinal ? fsvpfinal.centro_costo : null,
                              fsvpfinal ? fsvpfinal.name : null,
                              apcfinal ? fsvpfinal.codigo : null,
                              apcfinal ? fsvpfinal.id_evento : null
                            )
                          }
                        >
                          Buy Now
                        </Button>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {lbifinal ? lbifinal.name : null}
                        <TooltipComponent
                          icon="help-fill"
                          direction="right"
                          id="tooltip_lbi"
                          text={lbifinal ? lbifinal.details_1 : null}
                        />{" "}
                      </td>

                      <td>US {lbifinal ? lbifinal.price : null}</td>
                      <td className="text-center">
                        {/* <Paypaldetalle></Paypaldetalle> */}
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() =>
                            crearfactura(
                              lbifinal ? lbifinal.id_precio : null,
                              lbifinal ? lbifinal.centro_costo : null,
                              lbifinal ? lbifinal.name : null,
                              lbifinal ? lbifinal.codigo : null,
                              lbifinal ? lbifinal.id_evento : null
                            )
                          }
                        >
                          Buy Now
                        </Button>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
              </div>
            </Card>
          </Block>
        </Col>
      </Row>
      {/* <Modal size="lg" isOpen={modalLg} toggle={toggleLg}>
        <ModalHeader
          toggle={toggleLg}
          close={
            <button className="close" onClick={toggleLg}>
              <Icon name="cross" />
            </button>
          }
        >
          <span className="text-primary">Finished Reports</span>
        </ModalHeader>
        <ModalBody>
          <Documents
            setdocuments={setdocuments}
            documents={documents}
            clave={clave}
            setarchivos={setarchivos}
          ></Documents>
        </ModalBody>
        <ModalFooter className="bg-light">
          <img
            src="amazon-service_partners-badge (1).png"
            alt=""
            width="100"
            height="100"
          />
        </ModalFooter>
      </Modal> */}
    </React.Fragment>
  );
};
export default ComplianceR;

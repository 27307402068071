import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon } from "../../components/Component";
import Logo from "../logo/Logo";
import Toggle from "../sidebar/Toggle";

import EnglishFlag from '../../images/flags/english-sq.png';
import PortugueseFlag from '../../images/flags/portuguese-sq.png';
import SpanishFlag from '../../images/flags/spanish-sq.png';

const flags = {
  en: EnglishFlag,
  es: SpanishFlag,
  pt: PortugueseFlag,
}

const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const { i18n } = useTranslation()

  const [isLangMenuOpen, showLangMenu] = useState(false)

  const toggleLangMenu = () => showLangMenu((prev) => !prev);

  /**
   * @param {string} langLabel
   */
  const getFormattedLanguageName = (langLabel) => new Intl
    .DisplayNames(langLabel, { type: "language" })
    .of(langLabel);

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
              icon="menu"
              click={props.sidebarToggle}
            />
          </div>

          <div className="nk-header-brand d-xl-none">
            <Logo />
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="chats-dropdown hide-mb-xs" onClick={() => setVisibility(false)}>
                <span className="font-weight-bold">
                  <Icon className="text-primary mr-1" name="home" />

                  <a href="https://gsasellers.com/spec-2710">Página Inicio</a>
                </span>
              </li>

              <Dropdown isOpen={isLangMenuOpen} toggle={toggleLangMenu} direction="down">
                <DropdownToggle
                  aria-expanded
                  data-toggle="dropdown"
                  tag="span"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    className="img-fluid rounded-circle"
                    style={{
                      width: "1.75em",
                      height: "1.75em",
                    }}
                    src={Object.entries(flags).find(([langLabel]) => i18n.language.includes(langLabel) || langLabel.includes(i18n.language))[1]}
                    alt={i18n.language}
                  />
                </DropdownToggle>

                <DropdownMenu>
                  {Object
                    .entries(flags)
                    .filter(([langLabel]) => !(i18n.language.includes(langLabel) || langLabel.includes(i18n.language)))
                    .map(([langLabel, flag], key) => (
                      <DropdownItem key={key} onClick={() => i18n.changeLanguage(langLabel)}>
                        <img
                          className="img-fluid rounded-circle"
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                          }}
                          src={flag}
                          alt={langLabel}
                        />

                        <span className="ml-2" style={{
                          textTransform: 'capitalize',
                        }}>{getFormattedLanguageName(langLabel) || langLabel}</span>
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

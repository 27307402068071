/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";
import { RSelect } from "../../components/Component";
import DatePicker from "react-datepicker";
import states from "states-us";
import { codes, byAlpha2, byAlpha3, byNumeric } from "iso-country-codes";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  carriernameocean: yup.string().required("Carrier Name is Required"),
  oceancountry: yup.string().required("Country is Required"),
  vesselocean: yup.string().required("Vessel is Required"),
  containerocean: yup.string().required("Container Number is Required"),
  voyageocean: yup.string().required("Voyage is Required"),
  blocean: yup.string().required("BL Number is Required"),
  oceanstate: yup.string().required("State is Required"),
  portocean: yup.string().required("Entry Port is Required"),
  dateocean: yup.string().required("Date Arrival is Required"),
});

const Ocean = ({ setActiveStep, pn, setpn, value, option }) => {
  const usestados = [];
  states.forEach((element) =>
    usestados.push({ value: element.name, label: element.name })
  );

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  const [startDate, setStartDate] = useState(new Date());

  const volver = () => {
    setActiveStep(1);
  };
  const formik = useFormik({
    initialValues: {
      carriernameocean: pn.ocean.carriernameocean,
      oceancountry: pn.ocean.oceancountry,
      vesselocean: pn.ocean.vesselocean,
      containerocean: pn.ocean.containerocean,
      voyageocean: pn.ocean.voyageocean,
      blocean: pn.ocean.blocean,
      oceanstate: pn.ocean.oceanstate,
      portocean: pn.ocean.portocean,
      oceantime: pn.ocean.oceantime,
      dateocean: pn.ocean.dateocean,
      wrname: pn.ocean.wrname,
      wrocean: pn.ocean.wrname,
    },
    validationSchema,
    onSubmit: (values) => {
      setpn({
        ...pn,
        mode: {
          mode: value,
          purpose: option.proposito,
          other: option.otro,
        },
        idpn: values.blocean,
        ocean: {
          carriernameocean: values.carriernameocean,
          oceancountry: values.oceancountry,
          vesselocean: values.vesselocean,
          containerocean: values.containerocean,
          voyageocean: values.voyageocean,
          blocean: values.blocean,
          oceanstate: values.oceanstate,
          portocean: values.portocean,
          oceantime: values.oceantime,
          dateocean: values.dateocean,
          wrname: values.wrname,
          wrocean: values.wrocean,
        },
      });
      setActiveStep(3);
    },
  });

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit} id="validation-forms">
        <Row className="g-4">
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="carriernameocean">
                Name of Carrier *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="carriernameocean"
                  id="carriernameocean"
                  className="form-control"
                  defaultValue={formik.values.carriernameocean}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.carriernameocean &&
                    formik.errors.carriernameocean}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="country">
                Country *
              </label>
              <div className="form-control-wrap">
                <RSelect
                  options={countries}
                  id="oceancountry"
                  name="oceancountry"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("oceancountry", selectedOption.value)
                  }
                  defaultValue={formik.values.oceancountry}
                />
                <span className="text-danger">
                  {formik.touched.oceancountry && formik.errors.oceancountry}
                </span>
                <strong className="text-info">
                    {formik.values.oceancountry
                      ? `Country Selected: ${formik.values.oceancountry}`
                      : null}
                  </strong>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="vesselocean">
                Vessel Name *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="vesselocean"
                  id="vesselocean"
                  className="form-control"
                  defaultValue={formik.values.vesselocean}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.vesselocean && formik.errors.vesselocean}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="containerocean">
                Container Number *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="containerocean"
                  name="containerocean"
                  className="form-control"
                  defaultValue={formik.values.containerocean}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.containerocean &&
                    formik.errors.containerocean}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="voyageocean">
                Voyage Number *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="voyageocean"
                  name="voyageocean"
                  className="form-control"
                  defaultValue={formik.values.voyageocean}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.voyageocean && formik.errors.voyageocean}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="blocean">
                Bill of Lading Number *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="blocean"
                  name="blocean"
                  className="form-control"
                  defaultValue={formik.values.blocean}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.blocean && formik.errors.blocean}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="stateair">
                State that Product into to US *
              </label>
              <div className="form-control-wrap">
                <RSelect
                  options={usestados}
                  id="oceanstate"
                  name="oceanstate"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("oceanstate", selectedOption.value)
                  }
                  defaultValue={formik.values.oceanstate}
                />
                <span className="text-danger">
                  {formik.touched.oceanstate && formik.errors.oceanstate}
                </span>
                <strong className="text-info">
                  {formik.values.oceanstate
                    ? `State Selected: ${formik.values.oceanstate}`
                    : null}
                </strong>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="portocean">
                Name of U.S. Port of Entry *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="portocean"
                  name="portocean"
                  className="form-control"
                  defaultValue={formik.values.portocean}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.portocean && formik.errors.portocean}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="arrivalair">
                Date of Arrival *
              </label>
              <div className="form-control-wrap">
                <DatePicker
                  selected={formik.values.dateocean}
                  name="dateocean"
                  defaultValue={formik.values.dateocean}
                  onChange={(a) => formik.setFieldValue("dateocean", a)}
                  className="form-control date-picker"
                />
                <span className="text-danger">
                  {formik.touched.dateocean && formik.errors.dateocean}
                </span>{" "}
              </div>
              <div className="form-note">
                Date Format <code>mm/dd/yyyy</code>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="timeair">
                Time of Arrival
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="oceantime"
                  name="oceantime"
                  className="form-control"
                  defaultValue={formik.values.oceantime}
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        <Row className="g-4">
          <Col lg="12">
            <label className="form-label">Additional Information</label>
            <br></br>
            According to the information provided, the shipment is by Sea. The
            FDA forms ask us for additional information. Please provide the
            following information in order for us to complete the prior notice:
          </Col>
        </Row>
        <hr></hr>
        <Row className="g-4">
          <Col lg="6">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="timeair">
                Warehouse Name *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="wrname"
                  name="wrname"
                  className="form-control"
                  defaultValue={formik.values.wrname}
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="timeair">
                Warehouse Address
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="wrocean"
                  className="form-control"
                  defaultValue={formik.values.wrocean}
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>{" "}
        <br></br>
        <br></br>
        <Row>
          
          <Col lg="1">
            <Button onClick={volver}>Back</Button>
          </Col>
          <Col lg="1">
            <Button type="submit">Next</Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Ocean;

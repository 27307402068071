/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, FormGroup, Collapse } from "reactstrap";
import {
  Button,
  Icon,
  Col,
  Row,
  RSelect,
  Block,
} from "../../../components/Component";
import { TooltipComponent } from "../../../components/Component";
import { codes } from "iso-country-codes";
//import { Updateseller } from "../helpers/updateseller";
import Swal from "sweetalert2";
//import { useAuth0 } from "@auth0/auth0-react";
import Select from "react-select";

import Content from "../../../layout/content/Content";

import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";
import { toast } from "react-toastify";
import UpdateB2B from "../helpers/savebookingb2b";
import Bookingstep from "./bookingstep";

//import Lister from "../../dashboard/components/SellerListFiles";
const BookingDetails = ({
  modal,
  setModal,
  modalTab,
  setModalTab,
  bookingvalue,
  setbookingvalue,
  picki,
  setpicki,
  settotalpicki,
  totalpicki,
  seller,
}) => {
  var today = new Date();
  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };
  const CustomToast = () => {
    return (
      <div className="toastr-text">
        <h5>Save Successfully</h5>
        <p>Customs Broker was added</p>
      </div>
    );
  };

  const [escondido, setescondido] = useState(false);
  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  //const { isAuthenticated, logout, user } = useAuth0();
  const [pickup, setpickup] = useState();
  const [cbporigen, setcbporigen] = useState();
  const [cbpdestino, setcbpdestino] = useState();
  const [bookingfinal, setbookingfinal] = useState({});
  //Aparece on no aparece agente de aduanas en origne
  const [aparece, setaparece] = useState(true);
  const [aparece_1, setaparece_1] = useState(true);

  const [selectpickup, setselectpickup] = useState();

  const savefinal = () => {
    UpdateB2B(bookingvalue[0]._id.$oid, bookingfinal).then(
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your Booking has been Sent to Our Operator",
        showConfirmButton: false,
        timer: 5000,
      }).then(() => setModal(false))
    );
  };
  const cbporigenbook = () => {
    setbookingfinal({ ...bookingfinal, cbporigenbook: cbporigen });
    toast.success(<CustomToast />, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: <CloseButton />,
    });
  };

  const cbpdestinobook = () => {
    setbookingfinal({ ...bookingfinal, cbpdestinobook: cbpdestino });
    toast.success(<CustomToast />, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      closeButton: <CloseButton />,
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered"
      size="lg"
      toggle={() => setModal(false)}
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            setModal(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Shipments Details</h5>
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "1" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("1");
                }}
                href="#personal"
              >
                General Information
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "2" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("2");
                }}
                href="#address"
              >
                Adittional Information
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "4" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("4");
                }}
                href="#address"
              >
                Customs Informatiom
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "5" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("5");
                }}
                href="#address"
              >
                Tracking
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTab === "1" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="12">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="pais">
                          Reference
                        </label>
                        <input
                          defaultValue={
                            bookingvalue ? bookingvalue[0].ref : null
                          }
                          className="form-control"
                          name="referencia"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="pais">
                          Recolection Wharehouse
                        </label>
                        <input
                          defaultValue={
                            bookingvalue ? bookingvalue[0].whin : null
                          }
                          className="form-control"
                          name="whin"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <div className="card-inner">
                        <div className="align-center gx-3">
                          <Col md="4">
                            <div className="flex-item">
                              <span className="sub-text sub-text-sm text-soft">
                                <label
                                  className="form-label"
                                  htmlFor="contactname"
                                >
                                  {seller[0] ? (
                                    <>
                                      <span className="text-primary">
                                        Export From: (Seller):
                                      </span>
                                      <hr></hr>
                                      {seller[0].legalname}
                                      <br></br>
                                      {seller[0].numero} {seller[0].calle}
                                      <br></br>
                                      {seller[0].ciudad} {seller[0].estado}
                                      <br></br>
                                      {seller[0].pais} {seller[0].zip}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="user-alt-fill" />
                                      </span>{" "}
                                      {seller[0].contactname}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="call-alt-fill" />
                                      </span>{" "}
                                      {seller[0].telefono}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="mail-fill" />
                                      </span>{" "}
                                      {seller[0].email}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="whatsapp" />
                                      </span>{" "}
                                      {seller[0].ws}
                                    </>
                                  ) : null}
                                </label>
                              </span>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="flex-item">
                              <span className="sub-text sub-text-sm text-soft">
                                <label
                                  className="form-label"
                                  htmlFor="contactname"
                                >
                                  {bookingvalue ? (
                                    <>
                                      <span className="text-primary">
                                        Import of Record (Import):
                                      </span>
                                      <hr></hr>
                                      {bookingvalue[0].ior.legalname}
                                      <br></br>
                                      {bookingvalue[0].ior.numero}{" "}
                                      {bookingvalue[0].ior.calle}
                                      <br></br>
                                      {bookingvalue[0].ior.ciudad}{" "}
                                      {bookingvalue[0].ior.estado}
                                      <br></br>
                                      {bookingvalue[0].ior.pais}{" "}
                                      {bookingvalue[0].ior.zip}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="user-alt-fill" />
                                      </span>{" "}
                                      {bookingvalue[0].ior.contactname}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="call-alt-fill" />
                                      </span>{" "}
                                      {bookingvalue[0].ior.telefono}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="mail-fill" />
                                      </span>{" "}
                                      {bookingvalue[0].ior.email}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="whatsapp" />
                                      </span>{" "}
                                      {bookingvalue[0].ior.ws}
                                    </>
                                  ) : null}
                                </label>
                              </span>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="flex-item">
                              <span className="sub-text sub-text-sm text-soft">
                                <label
                                  className="form-label"
                                  htmlFor="contactname"
                                >
                                  {bookingvalue ? (
                                    <>
                                      <span className="text-primary">
                                        Export to (Consignee):
                                      </span>
                                      <hr></hr>
                                      {bookingvalue[0].cnee.legalname}
                                      <br></br>
                                      {bookingvalue[0].cnee.numero}{" "}
                                      {bookingvalue[0].cnee.calle}
                                      <br></br>
                                      {bookingvalue[0].cnee.ciudad}{" "}
                                      {bookingvalue[0].cnee.estado}
                                      <br></br>
                                      {bookingvalue[0].cnee.pais},{" "}
                                      {bookingvalue[0].cnee.zip}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="user-alt-fill" />
                                      </span>{" "}
                                      {bookingvalue[0].cnee.contactname}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="call-alt-fill" />
                                      </span>{" "}
                                      {bookingvalue[0].cnee.telefono}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="mail-fill" />
                                      </span>{" "}
                                      {bookingvalue[0].cnee.email}
                                      <br></br>
                                      <span className="glyphicon glyphicon-refresh text-primary">
                                        <Icon name="whatsapp" />
                                      </span>{" "}
                                      {bookingvalue[0].cnee.ws}
                                    </>
                                  ) : null}
                                </label>
                              </span>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Content>
                  <Col md="3">
                    <div className="flex-item">
                      <span className="sub-text sub-text-sm text-soft">
                        <label className="form-label" htmlFor="contactname">
                          {bookingvalue ? (
                            <>
                              <span className="text-primary">
                                Shipment Details
                              </span>
                              <hr></hr>
                              <span className="glyphicon glyphicon-refresh text-danger">
                                <Icon name="arrow-up-right" />
                              </span>{" "}
                              <span className="text-primary">
                                {bookingvalue[0].ior.pais}
                              </span>
                              <br></br>
                              <span className="glyphicon glyphicon-refresh text-danger">
                                <Icon name="arrow-down-right" />
                              </span>{" "}
                              <span className="text-primary">
                                {bookingvalue[0].ior.pais}
                              </span>
                              <br></br>
                              <span className="glyphicon glyphicon-refresh text-danger">
                                <Icon name="masonry-fill" />
                              </span>{" "}
                              <span className="text-primary">
                                {bookingvalue[0].qty} Pallets
                              </span>
                              <br></br>
                              <span className="glyphicon glyphicon-refresh text-danger">
                                <Icon name="meter" />
                              </span>{" "}
                              <span className="text-primary">
                                {bookingvalue[0].kg} Kgs
                              </span>
                              <br></br>
                              <span className="glyphicon glyphicon-refresh text-danger">
                                <Icon name="sign-usd-alt" />
                              </span>{" "}
                              <span className="text-primary">
                                USD {bookingvalue[0].fob} FOB
                              </span>
                            </>
                          ) : null}
                        </label>
                      </span>
                    </div>
                  </Col>
                </Content>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        Close
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTab === "2" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="legalname">
                      Services
                    </label>
                    <input
                      type="text"
                      id="services"
                      className="form-control"
                      name="services"
                      disabled
                      defaultValue={
                        bookingvalue ? bookingvalue[0].services : null
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="dbaname">
                      Pickup Date
                    </label>
                    <input
                      type="date"
                      id="date"
                      className="form-control"
                      name="date"
                      value={
                        bookingvalue
                          ? bookingvalue[0].booking[0].fechapickup
                          : null
                      }
                      disabled
                      placeholder="Pickup Date"
                    />
                  </FormGroup>
                </Col>

                <Col md="12">
                  {" "}
                  <label className="form-label" htmlFor="legalname">
                    Pickup Address
                  </label>
                  <hr></hr>
                  <Row className="gy-4">
                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="numero"
                        >
                          Number
                        </label>
                        <input
                          type="text"
                          id="numero"
                          name="numero"
                          defaultValue={
                            bookingvalue
                              ? bookingvalue[0].picksel[0].numero
                              : null
                          }
                          className="form-control"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="calle"
                        >
                          Street
                        </label>
                        <input
                          type="text"
                          id="calle"
                          name="calle"
                          defaultValue={
                            bookingvalue
                              ? bookingvalue[0].picksel[0].calle
                              : null
                          }
                          disabled
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="barrio"
                        >
                          District
                        </label>
                        <input
                          type="text"
                          id="barrio"
                          name="barrio"
                          defaultValue={
                            bookingvalue
                              ? bookingvalue[0].picksel[0].barrio
                              : null
                          }
                          disabled
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="gy-4">
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="ciudad"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          id="ciudad"
                          name="ciudad"
                          defaultValue={
                            bookingvalue
                              ? bookingvalue[0].picksel[0].ciudad
                              : null
                          }
                          disabled
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="estado"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          id="estado"
                          name="estado"
                          defaultValue={
                            bookingvalue
                              ? bookingvalue[0].picksel[0].estado
                              : null
                          }
                          disabled
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="pais"
                        >
                          Country
                        </label>
                        <input
                          name="pais"
                          defaultValue={
                            bookingvalue
                              ? bookingvalue[0].picksel[0].pais
                              : null
                          }
                          disabled
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="zip"
                        >
                          Zip Code
                        </label>
                        <input
                          type="text"
                          id="zip"
                          name="zip"
                          defaultValue={
                            bookingvalue ? bookingvalue[0].picksel[0].zip : null
                          }
                          disabled
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="gy-4">
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="zip"
                        >
                          Pickup Contact Name
                        </label>
                        <input
                          type="text"
                          id="contactname"
                          name="contactname"
                          defaultValue={
                            bookingvalue
                              ? bookingvalue[0].picksel[0].contactname
                              : null
                          }
                          disabled
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="zip"
                        >
                          Phone
                        </label>
                        <input
                          type="text"
                          id="telefono"
                          name="telefono"
                          defaultValue={
                            bookingvalue
                              ? bookingvalue[0].picksel[0].telefono
                              : null
                          }
                          disabled
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-label text-primary"
                          htmlFor="zip"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          defaultValue={
                            bookingvalue
                              ? bookingvalue[0].picksel[0].email
                              : null
                          }
                          disabled
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>{" "}
                  <hr></hr>
                </Col>

                <Col md="8">
                  <FormGroup>
                    <label className="form-label" htmlFor="legalname">
                      Special Instructions
                    </label>
                    <textarea
                      type="text"
                      id="instrucciones"
                      className="form-control"
                      name="instrucciones"
                      defaultValue={
                        bookingvalue
                          ? bookingvalue[0].booking[0].instrucciones
                          : null
                      }
                      disabled
                      placeholder="Special Instructions"
                    />
                  </FormGroup>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        Close
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>

            <div
              className={`tab-pane ${modalTab === "4" ? "active" : ""}`}
              id="address"
            >
              <Row className="gy-4">
                <Col md="6">
                  <Card className="card-bordered">
                    {bookingvalue ? (
                      bookingvalue[0].booking[0].cbporigenbook ? (
                        <CardBody className="card-inner">
                          <CardTitle tag="h6">
                            Your Customs Broker in Origin
                          </CardTitle>
                          <FormGroup>
                            <label
                              className="form-label text-primary"
                              htmlFor="estado"
                            >
                              Customs Broker Name
                            </label>
                            <input
                              type="text"
                              id="namecbporigen"
                              name="namecbporigen"
                              defaultValue={
                                bookingvalue
                                  ? bookingvalue[0].booking[0].cbporigenbook
                                      .name
                                  : null
                              }
                              disabled
                              className="form-control"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-label text-primary"
                              htmlFor="estado"
                            >
                              Contact Name
                            </label>
                            <input
                              type="text"
                              id="estado"
                              name="estado"
                              defaultValue={
                                bookingvalue
                                  ? bookingvalue[0].booking[0].cbporigenbook
                                      .contactname
                                  : null
                              }
                              disabled
                              className="form-control"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-label text-primary"
                              htmlFor="estado"
                            >
                              Contact Phone
                            </label>
                            <input
                              type="text"
                              id="estado"
                              name="estado"
                              defaultValue={
                                bookingvalue
                                  ? bookingvalue[0].booking[0].cbporigenbook
                                      .contactphone
                                  : null
                              }
                              disabled
                              className="form-control"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-label text-primary"
                              htmlFor="estado"
                            >
                              Contact Email
                            </label>
                            <input
                              type="text"
                              id="estado"
                              name="estado"
                              defaultValue={
                                bookingvalue
                                  ? bookingvalue[0].booking[0].cbporigenbook
                                      .contactemail
                                  : null
                              }
                              disabled
                              //    defaultValue={update ? update.estado : null}
                              className="form-control"
                            />
                          </FormGroup>
                        </CardBody>
                      ) : null
                    ) : null}

                    <div className="card-content p-3">
                      <CardImg
                        height={70}
                        src="Transplace_Uber-Freight-Logo.png"
                        alt=""
                      />
                    </div>
                    {seller ? (
                      seller.cbporigen ? (
                        <Content>
                          <li className="text-muted text-justify fs-{8}px ff-italic">
                            <span className="glyphicon glyphicon-refresh text-success">
                              <Icon name="check-circle" />
                            </span>
                            <span>
                              {" "}
                              Congratulations, your registration with our
                              customs broker at Export Country was successfully
                              completed.
                            </span>
                          </li>
                        </Content>
                      ) : (
                        <Content>
                          <li className="text-muted text-justify fs-{8}px ff-italic">
                            <br></br>{" "}
                            <span className="glyphicon glyphicon-refresh text-danger">
                              <Icon name="alert-fill" />
                            </span>
                            <span>
                              {" "}
                              The registration process with the our customs
                              broker at Export Country has not yet been
                              completed. Please consult with our commercial
                              agent or check messages in Dashboard.
                            </span>
                          </li>
                          <CardBody className="card-inner">
                            <div className="accordion accordian-s3">
                              <div className="accordion-item ">
                                <div
                                  className="accordion-head"
                                  onClick={() => setIsOpen(!isOpen)}
                                >
                                  <h6 className="title text-primary">
                                    Required Documents
                                  </h6>
                                  <span className="accordion-icon"></span>
                                </div>
                                <Collapse
                                  className="accordion-body"
                                  isOpen={isOpen === true ? true : false}
                                >
                                  <div className="accordion-inner">
                                    <ul>
                                      <li>
                                        Constancia de Situación Fiscal mes en
                                        curso
                                      </li>
                                      <li>
                                        Comprobante de domicilio actual (agua,
                                        luz, teléfono) antigüedad no mayor a 3
                                        meses.
                                      </li>
                                      <li>Carta de Encomienda 2022</li>
                                      <li>Opinión Positiva mes en curso</li>
                                      <li>Carta Compromiso OEA</li>
                                      <li>
                                        Identificacion oficial del representante
                                        legal
                                      </li>
                                      <li>Acta Constitutiva de la empresa</li>
                                      <li>
                                        Poder Notarial del representante legal.
                                      </li>
                                      <li>
                                        Fotografía de la fachada donde se vea el
                                        numero exterior, calle o nombre de la
                                        empresa.
                                      </li>
                                    </ul>
                                  </div>
                                </Collapse>
                              </div>
                            </div>
                          </CardBody>
                        </Content>
                      )
                    ) : null}
                  </Card>
                </Col>
                <Col md="6">
                  <Card className="card-bordered">
                    {bookingvalue ? (
                      bookingvalue[0].booking[0].cbpdestinobook ? (
                        <CardBody className="card-inner">
                          <CardTitle tag="h6">
                            Your Customs Broker in USA
                          </CardTitle>

                          <FormGroup>
                            <label
                              className="form-label text-primary"
                              htmlFor="estado"
                            >
                              Customs Broker Name
                            </label>
                            <input
                              type="text"
                              id="estado"
                              name="estado"
                              defaultValue={
                                bookingvalue
                                  ? bookingvalue[0].booking[0].cbpdestinobook
                                      .name
                                  : null
                              }
                              disabled
                              className="form-control"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-label text-primary"
                              htmlFor="estado"
                            >
                              Contact Name
                            </label>
                            <input
                              type="text"
                              id="estado"
                              name="estado"
                              defaultValue={
                                bookingvalue
                                  ? bookingvalue[0].booking[0].cbpdestinobook
                                      .contactname
                                  : null
                              }
                              disabled
                              className="form-control"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-label text-primary"
                              htmlFor="estado"
                            >
                              Contact Phone
                            </label>
                            <input
                              type="text"
                              id="estado"
                              name="estado"
                              defaultValue={
                                bookingvalue
                                  ? bookingvalue[0].booking[0].cbpdestinobook
                                      .contactphone
                                  : null
                              }
                              disabled
                              className="form-control"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label
                              className="form-label text-primary"
                              htmlFor="estado"
                            >
                              Contact Email
                            </label>
                            <input
                              type="text"
                              id="estado"
                              name="estado"
                              defaultValue={
                                bookingvalue
                                  ? bookingvalue[0].booking[0].cbpdestinobook
                                      .contactemail
                                  : null
                              }
                              disabled
                              className="form-control"
                            />
                          </FormGroup>
                        </CardBody>
                      ) : null
                    ) : null}

                    <div className="card-content p-3">
                      <CardImg
                        height={70}
                        width="10"
                        src="logo-black.11958aba.svg"
                        alt=""
                      />
                    </div>
                    {seller ? (
                      seller.fior ? (
                        <Content>
                          <li className="text-muted text-justify fs-{8}px ff-italic">
                            <span className="glyphicon glyphicon-refresh text-success">
                              <Icon name="check-circle" />
                            </span>
                            <span>
                              {" "}
                              Congratulations, your registration with our
                              customs broker at Import Country was successfully
                              completed.
                            </span>
                          </li>
                        </Content>
                      ) : (
                        <Content>
                          <li className="text-muted text-justify fs-{8}px ff-italic">
                            <br></br>{" "}
                            <span className="glyphicon glyphicon-refresh text-danger">
                              <Icon name="alert-fill" />
                            </span>
                            <span>
                              {" "}
                              The registration process with the our customs
                              broker at Import Country has not yet been
                              completed. Please consult with our commercial
                              agent or check messages in Dashboard.
                            </span>
                          </li>
                          <CardBody className="card-inner">
                            <div className="accordion accordian-s3">
                              <div className="accordion-item ">
                                <div
                                  className="accordion-head"
                                  onClick={() => setIsOpen(!isOpen)}
                                >
                                  <h6 className="title text-primary">
                                    Required Documents
                                  </h6>
                                  <span className="accordion-icon"></span>
                                </div>
                                <Collapse
                                  className="accordion-body"
                                  isOpen={isOpen === true ? true : false}
                                >
                                  <div className="accordion-inner">
                                    <ul>
                                      <li>Company Articles of Incorporation</li>
                                      <li>
                                        Company Registration (SAT in case of
                                        Mexico)
                                      </li>
                                      <li>
                                        Copy of the Legal Representative's
                                        Passport (Foreign) or Identity Card{" "}
                                      </li>
                                      <li>
                                        Copy of Second Representative's Passport
                                        (Foreing) or Identity Card
                                      </li>
                                    </ul>
                                  </div>
                                </Collapse>
                              </div>
                            </div>
                          </CardBody>
                        </Content>
                      )
                    ) : null}
                  </Card>
                </Col>
                <Col size="12">
                  <div className="text-muted">
                    Note: Do not worry if you do not have your legal documents
                    at this time. You can upload your legal documents later in
                    the legal documents section of the dashobard
                  </div>
                  <br></br>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        Close
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div className="tab-content">
              <div
                className={`tab-pane ${modalTab === "5" ? "active" : ""}`}
                id="personal"
              >
                <Bookingstep bookingvalue={bookingvalue}></Bookingstep>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BookingDetails;

export const ListPn = async (e) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({idcliente : e});

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const resp = await fetch(
    "https://apiecl.com/listpn",
    requestOptions
  );
  const sellers = await resp.json();
  return sellers;
};

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween,
} from "../../components/Component";
import SearchProduct from "./SearchProduct";
import Sellerstep from "./sellerstep";
import Report from "./report";
//import { useAuth0 } from "@auth0/auth0-react";
//import ListHtsTools from "./ListHtsTools";
//import ListHtsTable from "./ListHtsTable";
import {
  Card,
  CardHeader,
  CardFooter,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardLink,
} from "reactstrap";
import Iframe from "react-iframe";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import GetSpec from "../helpers/getspec";
import { useParams } from "react-router-dom";
import PostTag from "../helpers/tags";
import ResponsiveEmbed from "react-responsive-embed";
import { useTranslation, Trans } from "react-i18next";
export default function Categories() {
  //const { isAuthenticated, logout, user } = useAuth0();
  const { t, i18n } = useTranslation();
  console.log("IDIOMITA", i18n.resolvedLanguage);
  let { id } = useParams();
  const [idcliente, setidcliente] = useState(false);
  const [modalLg, setModalLg] = useState(false);
  const toggleLg = () => setModalLg(!modalLg);
  const [modalLgtoys, setModalLgtoys] = useState(false);
  const toggleLgtoys = () => setModalLgtoys(!modalLgtoys);
  const [modalLgshoes, setModalLgshoes] = useState(false);
  const toggleLgshoes = () => setModalLgshoes(!modalLgshoes);
  const [modalLgbeauty, setModalLgbeauty] = useState(false);
  const toggleLgbeauty = () => setModalLgbeauty(!modalLgbeauty);
  const [modalLgdrugs, setModalLgdrugs] = useState(false);
  const toggleLgdrugs = () => setModalLgdrugs(!modalLgdrugs);
  const [modalLgkitchen, setModalLgkitchen] = useState(false);
  const toggleLgkitchen = () => setModalLgkitchen(!modalLgkitchen);
  const [modalLgapparel, setModalLgapparel] = useState(false);
  const toggleLgapparel = () => setModalLgapparel(!modalLgapparel);
  const [modalLgbags, setModalLgbags] = useState(false);
  const toggleLgbags = () => setModalLgbags(!modalLgbags);
  const [modalLgtextiles, setModalLgtextiles] = useState(false);
  const toggleLgtextiles = () => setModalLgtextiles(!modalLgtextiles);
  const [modalLgjew, setModalLgjew] = useState(false);
  const toggleLgjew = () => setModalLgjew(!modalLgjew);
  const [modalLgfur, setModalLgfur] = useState(false);
  const toggleLgfur = () => setModalLgfur(!modalLgfur);
  const [modalLgbooks, setModalLgbooks] = useState(false);
  const toggleLgbooks = () => setModalLgbooks(!modalLgbooks);
  const [modalLggarden, setModalLggarden] = useState(false);
  const toggleLggarden = () => setModalLggarden(!modalLggarden);
  const [modalLgtools, setModalLgtools] = useState(false);
  const toggleLgtools = () => setModalLgtools(!modalLgtools);
  const [modalLgelectronic, setModalLgelectronic] = useState(false);
  const toggleLgelectronic = () => setModalLgelectronic(!modalLgelectronic);

  useEffect(() => {
    GetSpec().then((resp) => console.log(resp));
  }, []);
  
  return (
    <>
      <React.Fragment>
        <Content>
          <Head title="Compliance Robot"></Head>
          <Block>
            <PreviewAltCard className="border-secondary">
              <CardTitle tag="h4" className="text-muted">
                {t("description.titulo_categorias")}
              </CardTitle>

              <hr></hr>
              <br></br>

              <Row className="g-gs">
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "alimentos").then(() => toggleLg())
                    }
                  >
                    <CardBody className="card-inner text-center">
                     <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-sauce-bottle-48.png`}
                        alt=""
                      /></Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {" "}
                          {t("categorias.alimentos")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "cocina").then(() => toggleLgkitchen())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-kitchen-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.cocina")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "juguetes").then(() => toggleLgtoys())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-toy-car-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.juguetes")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "calzado").then(() => toggleLgshoes())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-shoes-48.png`}
                        alt=""
                      />
                 </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.calzado")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "textiles").then(() => toggleLgtextiles())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-blankie-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.textiles")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>{" "}
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "belleza").then(() => toggleLgbeauty())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-dispenser-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.belleza")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "vestimenta").then(() => toggleLgapparel())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-clothes-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.vestimenta")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "Bolsos y Maletas").then(() => toggleLgbags())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-carry-on-bag-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.bolsos")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "Medicamentos").then(() => toggleLgdrugs())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-pharmacy-shopping-basket-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.medicamento")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "Libros").then(() => toggleLgbooks())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-books-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.libros")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "jardin").then(() => toggleLggarden())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-growing-plant-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.jardin")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col lg="3">
                  <Card className="card-bordered">
                    <CardBody className="card-inner text-center">
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-pet-48.png`}
                        alt=""
                      />
                      <br></br>
                      <strong>
                        <small className="text-muted">Mascotas</small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col> */}
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "muebles").then(() => toggleLgfur())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-furniture-48.png`}
                        alt=""
                      />
                     </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.muebles")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "joyas").then(() => toggleLgjew())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-jewelry-48.png`}
                        alt=""
                      />
                     </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.joyas")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "electronica").then(() =>
                        toggleLgelectronic()
                      )
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-gadgets-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.electronica")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>{" "}
                <Col lg="3">
                  <Card
                    className="card-bordered"
                    onClick={() =>
                      PostTag(id, "herramientas").then(() => toggleLgtools())
                    }
                  >
                    <CardBody className="card-inner text-center">
                    <Button>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons8-tools-48.png`}
                        alt=""
                      />
                      </Button>
                      <br></br>
                      <strong>
                        <small className="text-muted">
                          {t("categorias.herramientas")}
                        </small>
                      </strong>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </PreviewAltCard>

            <Modal size="lg" isOpen={modalLg} toggle={toggleLg}>
              <ModalHeader
                toggle={toggleLg}
                close={
                  <button className="close" onClick={toggleLg}>
                    <Icon name="cross" />
                  </button>
                }
              >
                {t("categorias.alimentos")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/food/spanish/Grocery_es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/food/pt/Grocery+es+(1)+pt-BR+(1).pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/food/en/Grocery+es+(1)+en-US.pdf"
                  ratio="4:3"
                />}
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={modalLgtoys} toggle={toggleLgtoys}>
              <ModalHeader
                toggle={toggleLgtoys}
                close={
                  <button className="close" onClick={toggleLgtoys}>
                    <Icon name="cross" />
                  </button>
                }
              >
                 {t("categorias.juguetes")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/toys/spanish/Toys_1+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/toys/pt/Toys_1+es+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/toys/en/Toys_1+es+en-US.pdf"
                  ratio="4:3"
                />}
                 
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={modalLgshoes} toggle={toggleLgshoes}>
              <ModalHeader
                toggle={toggleLgshoes}
                close={
                  <button className="close" onClick={toggleLgshoes}>
                    <Icon name="cross" />
                  </button>
                }
              >
                {t("categorias.calzado")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/footwear/spanish/Shoes+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/footwear/pt/shoes-en+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/footwear/en/shoes-en.pdf"
                  ratio="4:3"
                />}
                  
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLgbeauty} toggle={toggleLgbeauty}>
              <ModalHeader
                toggle={toggleLgbeauty}
                close={
                  <button className="close" onClick={toggleLgbeauty}>
                    <Icon name="cross" />
                  </button>
                }
              >
                  {t("categorias.belleza")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/beauty/spanish/Beauty+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/beauty/pt/Beauty+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/beauty/en/Beauty.pdf"
                  ratio="4:3"
                />}
                  
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLgdrugs} toggle={toggleLgdrugs}>
              <ModalHeader
                toggle={toggleLgdrugs}
                close={
                  <button className="close" onClick={toggleLgdrugs}>
                    <Icon name="cross" />
                  </button>
                }
              >
                 {t("categorias.medicamento")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/drugs/spanish/drugs+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/drugs/pt/drugs+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/drugs/en/drugs.pdf"
                  ratio="4:3"
                />}
                 
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLgkitchen} toggle={toggleLgkitchen}>
              <ModalHeader
                toggle={toggleLgkitchen}
                close={
                  <button className="close" onClick={toggleLgkitchen}>
                    <Icon name="cross" />
                  </button>
                }
              >
                  {t("categorias.cocina")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/kitchen/spanish/Kitchen.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/kitchen/pt/Home-+Home+Improvement_Kitchen+%26+Bath+Fixtures+(1)+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/kitchen/en/Home-+Home+Improvement_Kitchen+%26+Bath+Fixtures+(1).pdf"
                  ratio="4:3"
                />}
                  
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={modalLgapparel} toggle={toggleLgapparel}>
              <ModalHeader
                toggle={toggleLgapparel}
                close={
                  <button className="close" onClick={toggleLgapparel}>
                    <Icon name="cross" />
                  </button>
                }
              >
                 {t("categorias.vestimenta")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/apparel/spanish/Apparel.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/apparel/pt/Apparel-+Children+or+Baby_Boys'+or+Girls'+Clothes+(1)+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/apparel/en/Apparel-+Children+or+Baby_Boys'+or+Girls'+Clothes+(1).pdf"
                  ratio="4:3"
                />}
                 
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLgbags} toggle={toggleLgbags}>
              <ModalHeader
                toggle={toggleLgbags}
                close={
                  <button className="close" onClick={toggleLgbags}>
                    <Icon name="cross" />
                  </button>
                }
              >
                 {t("categorias.bolsos")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/bags/spanish/Luggage-+Backpacks+%26+Cases_Casual+backpacks+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/bags/pt/Luggage-+Backpacks+%26+Cases_Casual+backpacks+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/bags/en/Luggage-+Backpacks+%26+Cases_Casual+backpacks.pdf"
                  ratio="4:3"
                />}
                 
                  <ResponsiveEmbed
                    src=""
                    ratio="4:3"
                  />
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLgtextiles} toggle={toggleLgtextiles}>
              <ModalHeader
                toggle={toggleLgtextiles}
                close={
                  <button className="close" onClick={toggleLgtextiles}>
                    <Icon name="cross" />
                  </button>
                }
              >
                  {t("categorias.textiles")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/home-textil/spanish/HomeTextil+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/home-textil/pt/HomeTextil+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/home-textil/en/HomeTextil.pdf"
                  ratio="4:3"
                />}
                 
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLgjew} toggle={toggleLgjew}>
              <ModalHeader
                toggle={toggleLgjew}
                close={
                  <button className="close" onClick={toggleLgjew}>
                    <Icon name="cross" />
                  </button>
                }
              >
                {t("categorias.joyas")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/jewelry/spanish/Jewellery+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/jewelry/pt/Jewellery+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/jewelry/en/Jewellery.pdf"
                  ratio="4:3"
                />}
                  
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLgfur} toggle={toggleLgfur}>
              <ModalHeader
                toggle={toggleLgfur}
                close={
                  <button className="close" onClick={toggleLgfur}>
                    <Icon name="cross" />
                  </button>
                }
              >
                 {t("categorias.muebles")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/furniture/spanish/Furniture+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/furniture/pt/Furniture+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/furniture/en/Furniture.pdf"
                  ratio="4:3"
                />}
                 
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLgbooks} toggle={toggleLgbooks}>
              <ModalHeader
                toggle={toggleLgbooks}
                close={
                  <button className="close" onClick={toggleLgbooks}>
                    <Icon name="cross" />
                  </button>
                }
              >
                 {t("categorias.libros")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/books/spanish/Books+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/books/pt/Books+es+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/books/en/Books+es+en-US.pdf"
                  ratio="4:3"
                />}
                  
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLggarden} toggle={toggleLggarden}>
              <ModalHeader
                toggle={toggleLggarden}
                close={
                  <button className="close" onClick={toggleLggarden}>
                    <Icon name="cross" />
                  </button>
                }
              >
                  {t("categorias.jardin")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/garden/spanish/Home-+Lawn+%26+Garden_Gardening+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/garden/pt/Home-+Lawn+%26+Garden_Gardening+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/garden/en/Home-+Lawn+%26+Garden_Gardening.pdf"
                  ratio="4:3"
                />}
                
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={modalLgtools} toggle={toggleLgtools}>
              <ModalHeader
                toggle={toggleLgtools}
                close={
                  <button className="close" onClick={toggleLgtools}>
                    <Icon name="cross" />
                  </button>
                }
              >
                 {t("categorias.herramientas")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/tools/spanish/Home-+Home+Improvement_Power+%26+Hand+Tools+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/tools/pt/Home-+Home+Improvement_Power+%26+Hand+Tools+es+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/tools/en/Home-+Home+Improvement_Power+%26+Hand+Tools+es+en-US.pdf"
                  ratio="4:3"
                />}
                  
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            <Modal
              size="lg"
              isOpen={modalLgelectronic}
              toggle={toggleLgelectronic}
            >
              <ModalHeader
                toggle={toggleLgelectronic}
                close={
                  <button className="close" onClick={toggleLgelectronic}>
                    <Icon name="cross" />
                  </button>
                }
              >
                  {t("categorias.electronica")}
              </ModalHeader>
              <ModalBody>
                <p>
                {i18n.resolvedLanguage === "es" ?
                  <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/electronic/spanish/electrical+es.pdf"
                    ratio="4:3"
                  />: i18n.resolvedLanguage === "pt" ? 
                   <ResponsiveEmbed
                    src="https://spec-ecl.s3.amazonaws.com/electronic/pt/electrical+es+pt-BR.pdf"
                    ratio="4:3"
                  />: <ResponsiveEmbed
                  src="https://spec-ecl.s3.amazonaws.com/electronic/en/electrical+es+en-US.pdf"
                  ratio="4:3"
                />}
                 
                </p>
              </ModalBody>
              <ModalFooter className="bg-light">
                <span className="sub-text"></span>
              </ModalFooter>
            </Modal>
            {/* 

                  
                   
                    <Button color="secondary">
                      <img
                        className="text-end"
                        align="right"
                        src="icons8-toys-64.png"
                        width="30"
                        height="30"
                        alt="usa"
                      ></img>
                      <span>&nbsp;&nbsp;Toys</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                  </spam>{" "}
                  <spam>
                    {" "}
                    <Button color="secondary">
                      <img
                        src="icons8-zapato-caballero-64.png"
                        width="30"
                        height="30"
                        alt="usa"
                      ></img>
                      <span>&nbsp;&nbsp;Shoes</span>&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                    <br></br>
                    <br></br>
                  </spam>{" "}
                  <spam>
                    {" "}
                    <Button color="secondary">
                      <img
                        className="text-end"
                        align="right"
                        src="icons8-casa-68.png"
                        width="30"
                        height="30"
                        alt="usa"
                      ></img>
                      &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;Home&nbsp;&nbsp;&nbsp;
                    </Button>
                  </spam>
                  <spam>
                    {" "}
                    <Button color="secondary">
                      <img
                        className="text-end"
                        align="right"
                        src="icons8-computer-64.png"
                        width="100"
                        height="100"
                        alt="usa"
                      ></img>
                      &nbsp; &nbsp;&nbsp;&nbsp;PC&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp;
                    </Button>
                  </spam>{" "}
                </PreviewAltCard> */}
            {/* <Col lg="6">
                <PreviewAltCard className="h-100 border-secondary">
                  <Button color="secondary">
                    <img
                      className="text-end"
                      align="right"
                      src="icons8-paquete-94.png"
                      width="64"
                      height="64"
                      alt="usa"
                    ></img>
                    &nbsp;&nbsp;Product<br></br>
                  </Button>&nbsp;&nbsp;
                  <spam>
                    {" "}
                    <Button color="secondary">
                      <img
                        className="text-end"
                        align="right"
                        src="icons8-paquete-94.png"
                        width="64"
                        height="64"
                        alt="usa"
                      ></img>
                      &nbsp;&nbsp;Product<br></br>
                    </Button>
                  </spam>
                  <br></br>
                  <br></br>
                  <Button color="secondary">
                    <img
                      className="text-end"
                      align="right"
                      src="icons8-paquete-94.png"
                      width="64"
                      height="64"
                      alt="usa"
                    ></img>
                    &nbsp;&nbsp;Product<br></br>&nbsp;&nbsp;Documentation
                  </Button>
                  <spam>
                    {" "}
                    <Button color="secondary">
                      <img
                        className="text-end"
                        align="right"
                        src="icons8-paquete-94.png"
                        width="64"
                        height="64"
                        alt="usa"
                      ></img>
                      &nbsp;&nbsp;Product<br></br>&nbsp;&nbsp;Documentation
                    </Button>
                  </spam>
                  <br></br>
                </PreviewAltCard>
              </Col> */}
            {/* <Col lg="12">
              {hidden.report ? (
                <PreviewAltCard className="h-100 border-secondary">
                  <Report></Report>
                </PreviewAltCard>
              ) : null}
            </Col>{" "} */}
          </Block>
        </Content>
      </React.Fragment>
    </>
  );
}

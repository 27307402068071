import HttpClient from './providers.helper';

  class FileProvider {
    httpClient = HttpClient;

    async SubirExcel(file, clave) {
      const formData = new FormData();
      formData.append('file', file);
      return await this.httpClient.post(
        `https://apiecl.com/uploaddocuments?id_seller=${clave.clave}`,
        // 'http://192.168.0.101:5000/excel/file',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'origin':'x-requested-with',
            'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
            'Access-Control-Allow-Origin': '*'
          },
        }
      );
    }  
  }

  export default FileProvider;

/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
/* eslint-disable no-unused-vars */
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Icon from "../../components/icon/Icon";
import classnames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import DataTable from "react-data-table-component";
import moment from "moment";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
} from "../../components/block/Block";
import { PreviewCard, CodeBlock } from "../../components/preview/Preview";
import {
  Button,
  BlockBetween,
  TooltipComponent,
  OverlineTitle,
  RSelect,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import Switch from "react-switch";
import Iframe from "react-iframe";
import Swal from "sweetalert2";
import GetCitas from "../helpers/getcitas";
import ModalAppoint from "./modalappoint";
import ModalReprogramar from "./modalreprogramar";
import { Link } from "react-router-dom";
const Principal = ({ ...props }) => {
  const { isAuthenticated, logout, user } = useAuth0();
  const [activeIconTab, setActiveIconTab] = useState("6");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  var [hidden, sethidden] = useState(false);
  const [escondido, setescondido] = useState(true);
  const [data, setdata] = useState();
  const [modalap, setModalap] = useState(false);
  const [modalrep, setModalrep] = useState(false);
  const [linke, setlinke] = useState();

  useEffect(() => {
    GetCitas(user.email).then((resp) => setdata(resp));
  }, [user.email, setModalap, modalap, hidden, sethidden]);

  //CONTROLA LA VENTANA DE BOOKINGS
  var myWindow;
  function openWin() {
    myWindow = window.open(
      `https://booking.appointy.com/gsasellers?sr=1010183&email=${user.email}`,
      "_blank",
      "width=600, height=600"
    );
  }
  var pusher = new Pusher("537eade63136d3305990", {
    cluster: "us2",
  });
  var channel = pusher.subscribe("my-channel");

  channel.bind(user.email, function (e) {
    myWindow.close();
    Swal.fire({
      icon: "success",
      title: "Congratulations",
      text: "your appointment has been booked.",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        sethidden(!hidden);
      }
    });
  });

  //CONTROLA LA VENTANA DE REPROGRAMACION
  var myWindow1;
  function openMyWindow1(row, id) {
    myWindow1 = window.open(row, "_blank", "width=600, height=600");

    var pusher1 = new Pusher("97cbbe5caad77fedb957", {
      cluster: "us2",
    });

    var channel1 = pusher1.subscribe("my-channel");
    channel1.bind(id, function (data) {
      myWindow1.close();
      Swal.fire({
        imageUrl: "icons8-reprogramar-una-tarea-96.png",
        title: "Congratulations",
        text: "your appointment has been resheduled.",
      }).then((result) => {
        if (result.isConfirmed) {
          sethidden(!hidden);
        }
      });
    });
  }
  const [id_m, setid_m] = useState();
  const [link, setlink] = useState();
  const modalcitas = (row1, row) => {
    setlink(row);
    setid_m(row1);
    setModalap(true);
  };
  const modalreprogramar = (e) => {
    setlink(e);
    setModalrep(true);
  };
  const ActionComponent = ({ row1, row, onClick }) => {
    const clickHandler = () => onClick(row1, row);
    return (
      <Button color="danger" onClick={clickHandler}>
        <Icon name="minus-circle-fill" />
        <span>Cancel</span>
      </Button>
    );
  };
  const ActionComponent1 = ({ row, id, onClick }) => {
    const clickHandler = () => onClick(row, id);
    return (
      <Button color="info" onClick={clickHandler}>
        <Icon name="update" />
        <span>Reschedule</span>
      </Button>
    );
  }

  const ActionComponent2 = ({row,onClick}) => {
   
    const clickHandler = () => onClick(row);
    return (
      <Badge color="warning" onClick={clickHandler}>
        <Icon name="video" />
        <span>Enter</span>
      </Badge>
    );
  };

  const room = (e) => {
    setescondido(false);
    setlinke(e);
  };

  const volver = () => {

    setescondido(true);
  }

  const columnas = [
    // {
    //   name: "Meeting ID",
    //   cell: (row) => row.id_meeting,
    // },
    {
      name: "Date",
      cell: (row) => moment(row.start_time).format("LL"),
    },
    {
      name: "Start Time",
      cell: (row) => moment(row.start_time).format("LT"),
    },
    {
      name: "End Time",
      cell: (row) => moment(row.end_time).format("LT"),
    },
    {
      name: "Time Zone",

      cell: (row) => row.time_zone,
    },
    {
      name: "Service",
      cell: (row) => row.service,
    },
    
    {
      name: "Meeting Room",
      cell: (row) => (
        <>
           <ActionComponent2
            row={row.linkseller}
            onClick={room}
            
          ></ActionComponent2>
        </>
      )
    },
    {
      name: "Actions",
      right: true,
      cell: (row) => (
        <>
          <ActionComponent1
            row={row.res_link}
            onClick={openMyWindow1}
            id={row.id_meeting}
          ></ActionComponent1>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <ActionComponent
            row1={row.id_meeting}
            row={row.cancel_link}
            onClick={modalcitas}
          ></ActionComponent>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Appoinment Meeting - Video Call</BlockTitle>
              <BlockDes className="text-soft">Easy, Reliable and Fast</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                <li>
                  {escondido ? (
                    <Link to={"/"}>
                      <Button color="light" outline className="btn-white">
                        <Icon name="arrow-left-circle"></Icon>
                        <span>Back</span>
                      </Button>{" "}
                    </Link>
                  ) : (
                    
                      <Button onClick={volver} color="light" outline className="btn-white">
                        <Icon name="arrow-left-circle"></Icon>
                        <span>Back</span>
                      </Button>
                   
                  )}
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Content>
          {" "}
          <div>

          {escondido ? (
            <Button onClick={openWin} color="primary">
              {" "}
              <Icon name="calendar"></Icon>
              <span>Appointment a New Call</span>
            </Button>) : null }

          </div>
          <br></br>
          {escondido ? 
          <DataTable
            striped
            pagination
            columns={columnas}
            data={data}
            title="Actives Appointments"
          /> : 
          <div ClassName="text-center">
            {/* <iframe src=   "https://invoice.stripe.com/i/acct_1FkNgaDnAXMk52OO/test_YWNjdF8xRmtOZ2FEbkFYTWs1Mk9PLF9NTElOY1puRjlsUTFvWEVydTVmejc3Wjc0eXVJcE5BLDUyNDM0OTM40200J6nX74A0?s=ap"></iframe> */}
            <iframe src={linke} width="1200" height="600"  allow="camera; microphone; fullscreen; speaker; display-capture"></iframe> 
          </div> }
        </Content>
      </Content>
      <ModalAppoint
        link={link}
        modalap={modalap}
        setModalap={setModalap}
        id_m={id_m}
      ></ModalAppoint>
      <ModalReprogramar
        link={link}
        modalrep={modalrep}
        setModalrep={setModalrep}
      ></ModalReprogramar>
    </React.Fragment>
  );
};

export default Principal;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import { Button, Icon, Col, Row, RSelect } from "../../components/Component";
import { codes } from "iso-country-codes";
import { useAuth0 } from "@auth0/auth0-react";
import Swal from "sweetalert2";
import DocumentsGenerales from "../../dashboard/components/docgenerales";
import { Updatesku } from "../helpers/updatesku";
import { toast } from "react-toastify";

const ModalEditProduct = ({
  modaledit,
  setModaledit,
  modalTabedit,
  setModalTabedit,
  sku,
  datas,
}) => {
  

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  const { isAuthenticated, logout, user } = useAuth0();

  const [guardarsku, setguardarsku] = useState();
  

  useEffect(() => {
    setguardarsku(datas[0]);
  }, [datas, modaledit]);

  // /* user:{ _id:user.sub}, */
  // id_cliente: user.sub,
  // sku: datas[0] ? datas[0].sku : null,
  // shortdescription: datas[0] ? datas[0].shortdescription : null,
  // fob: datas[0] ? datas[0].fob : null,
  // country_origin: datas[0] ? datas[0].country_origin : null,
  // upc_number: datas[0] ? datas[0].upc_number : null,
  // weight: datas[0] ? datas[0].weight : null,
  // mark: datas[0] ? datas[0].mark : null,

  const handlingChange = (event) => {
    setguardarsku({
      ...guardarsku,
      [event.target.name]: event.target.value,
    });
    //  console.log(guardarsku);
  };

  const SelectChange = (event) => {
    setguardarsku({
      ...guardarsku,
      country_origin: event.value,
    });
    //   console.log(guardarsku);
  };

  const update = async () => {
    if (
      guardarsku.sku !== "" &&
      guardarsku.shortdescription !== "" &&
      guardarsku.country_origin !== "" &&
      guardarsku.weight !== ""
    ) {
      Updatesku(guardarsku).then(
        setguardarsku({
          id_cliente: user.sub,
          sku: "",
          shortdescription: "",
          fob: "",
          country_origin: "",
          upc_number: "",
          weight: "",
          mark: "",
        }),
       setModaledit(false),
       toast.success(<CustomToast5 />, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        closeButton: <CloseButton />,
      })
      );
    } else {
      Swal.fire({
        title: "oops!",
        text: "Please complete all fields!!",
        icon: "warning",
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [hidden, sethidden] = useState({
    escondido: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [clas, useclas] = useState({
    datos: "",
  });
  /* const buscarhts = () => {
		sethidden({ escondido: true });
		useclas({ datos: guardarsku.shortdescription });
	}; */
  const clave = `${user.sub}/skus/${datas[0] ? datas[0].sku : null}`;
  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };
  const CustomToast5 = () => {
    return (
      <div className="toastr-text">
        <h5>Congratulations</h5>
        <p>Product Was Successfully Updated</p>
      </div>
    );
  };
  return (
    <Modal
      isOpen={modaledit}
      className="modal-dialog-centered"
      size="lg"
      toggle={() => setModaledit(false)}
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            setModaledit(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Edit Product</h5>
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTabedit === "1" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTabedit("1");
                }}
                href="#personal"
              >
                General Information
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTabedit === "2" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTabedit("2");
                }}
                href="#personal"
              >
                Documents
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTabedit === "1" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="3">
                  <FormGroup>
                    <label className="form-label" htmlFor="contact-name">
                      SKU Number
                    </label>
                    <input
                      type="text"
                      id="sku"
                      className="form-control"
                      name="sku"
                      //onChange={handlingChange}
                      //onChange={(e) => onInputChange(e)}
                      defaultValue={datas[0] ? datas[0].sku : null}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label className="form-label" htmlFor="cargo">
                      UPC Number
                    </label>
                    <input
                      id="upc_number"
                      name="upc_number"
                      className="form-control"
                      onChange={handlingChange}
                      //onChange={(e) => onInputChange(e)}
                      defaultValue={datas[0] ? datas[0].upc_number : null}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="cargo">
                      Mark
                    </label>
                    <input
                      id="mark"
                      name="mark"
                      className="form-control"
                      onChange={handlingChange}
                      //onChange={(e) => onInputChange(e)}
                      defaultValue={datas[0] ? datas[0].mark : null}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="pais">
                      Country Origin
                    </label>
                    <RSelect
                      options={countries}
                      placeholder="Select a country"
                      onChange={SelectChange}
                      defaultValue={{
                        label: datas[0] ? datas[0].country_origin : null,
                        value: datas[0] ? datas[0].country_origin : null,
                      }}
                      //   onChange={(e) =>
                      //     setEvento({
                      //       ...evento,
                      //       legaladdress: {
                      //         ...evento.legaladdress,
                      //         pais: e.value,
                      //       },
                      //     })
                      //   }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="telefono">
                      FOB Value (Unit)
                    </label>
                    <input
                      type="text"
                      id="fob"
                      name="fob"
                      className="form-control"
                      onChange={handlingChange}
                      // onChange={(e) => onInputChange(e)}
                      defaultValue={datas[0] ? datas[0].fob : null}
                      placeholder="FOB Value"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="legalname">
                      Short Description
                    </label>
                    <input
                      type="text"
                      id="shortdescription"
                      name="shortdescription"
                      className="form-control"
                      onChange={handlingChange}
                      // onChange={(e) => onInputChange(e)}
                      defaultValue={datas[0] ? datas[0].shortdescription : null}
                      placeholder="Enter Short Description"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label className="form-label" htmlFor="telefono">
                      Weight (Kg)
                    </label>
                    <input
                      type="text"
                      id="wight"
                      name="weight"
                      className="form-control"
                      onChange={handlingChange}
                      // onChange={(e) => onInputChange(e)}
                      defaultValue={datas[0] ? datas[0].weight : null}
                      placeholder="Weight Kg"
                    />
                  </FormGroup>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={update}
                        /* onClick={(ev) => {
                            ev.preventDefault();
                            submitForm();
                          }} */
                      >
                        Update Product
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setModaledit(false);
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTabedit === "2" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="12">
                  <FormGroup>
                    <label className="form-label" htmlFor="legalname">
                      Upload Documents (Technical Sheets, Photos)
                    </label>
                    <DocumentsGenerales clave={clave}></DocumentsGenerales>
                  </FormGroup>
                </Col>
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={update}
                        /* onClick={(ev) => {
                            ev.preventDefault();
                            submitForm();
                          }} */
                      >
                        Update Product
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setModaledit(false);
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditProduct;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";
import { RSelect } from "../../components/Component";
import DatePicker from "react-datepicker";
import states from "states-us";
import { codes, byAlpha2, byAlpha3, byNumeric } from "iso-country-codes";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  carriernametruck: yup.string().required("Carrier Name is Required"),
  truckcountry: yup.string().required("Country is Required"),
  truckstate: yup.string().required("State is Required"),
  crosspoint: yup.string().required("Cross Point is Required"),
  datetruck: yup.string().required("Date is Required"),
  blnumber: yup.string().required("Bill of Landing is Required"),
});

const Truck = ({setActiveStep, pn, setpn, value, option}) => {
  const usestados = [];
  states.forEach((element) =>
    usestados.push({ value: element.name, label: element.name })
  );

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );
  const [startDate, setStartDate] = useState(new Date());

  const volver = () => {
    setActiveStep(1);
  };

  const formik = useFormik({
    initialValues: {
      carriernametruck: pn.truck.carriernametruck,
      truckcountry: pn.truck.truckcountry,
      license: pn.truck.license,
      blnumber: pn.truck.blnumber,
      truckstate: pn.truck.truckstate,
      crosspoint: pn.truck.crosspoint,
      datetruck: pn.truck.datetruck,
      timetruck: pn.truck.timetruck,
    },
    validationSchema,
    onSubmit: (values) => {
      
      setpn({
        ...pn,
        mode: {
          mode: value,
          purpose: option.proposito,
          other: option.otro,
        },
      idpn: values.blnumber,
      truck: {
        carriernametruck: values.carriernametruck,
        truckcountry: values.truckcountry,
        license: values.license,
        blnumber: values.blnumber,
        truckstate: values.truckstate,
        crosspoint: values.crosspoint,
        datetruck: values.datetruck,
        timetruck: values.timetruck,
      },
    });
      setActiveStep(3);
    },
  });

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit} id="validation-forms">
        <Row className="g-4">
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="carriernameocean">
                Name of Carrier *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="carriernametruck"
                  name="carriernametruck"
                  className="form-control"
                  defaultValue={formik.values.carriernametruck}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.carriernametruck &&
                    formik.errors.carriernametruck}
                </span>
              </div>
            </FormGroup>{" "}
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="country">
                Country *
              </label>
              <div className="form-control-wrap">
                <RSelect
                  options={countries}
                  id="truckcountry"
                  name="truckcountry"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("truckcountry", selectedOption.value)
                  }
                  defaultValue={formik.values.oceancountry}
                />
                <span className="text-danger">
                  {formik.touched.truckcountry && formik.errors.truckcountry}
                </span>
                <strong className="text-info">
                    {formik.values.truckcountry
                      ? `Country Selected: ${formik.values.truckcountry}`
                      : null}
                  </strong>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="carriernameocean">
                Vehicle License Number
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="license"
                  className="form-control"
                  defaultValue={formik.values.license}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.carriernametruck &&
                    formik.errors.carriernametruck}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="carriernameocean">
                Bill of Landing Number *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="blnumber"
                  name="blnumber"
                  className="form-control"
                  defaultValue={formik.values.blnumber}
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="stateair">
                State that Product into to US *
              </label>
              <div className="form-control-wrap">
                <RSelect
                  options={usestados}
                  id="truckstate"
                  name="truckstate"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("truckstate", selectedOption.value)
                  }
                  defaultValue={formik.values.truckstate}
                />
                <span className="text-danger">
                  {formik.touched.truckstate && formik.errors.truckstate}
                </span>
                <strong className="text-info">
                    {formik.values.truckstate
                      ? `State Selected: ${formik.values.truckstate}`
                      : null}
                  </strong>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="carriernameocean">
                Name of Crossing Point *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="crosspoint"
                  className="form-control"
                  defaultValue={formik.values.crosspoint}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.crosspoint && formik.errors.crosspoint}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="arrivalair">
                Date of Arrival *
              </label>
              <div className="form-control-wrap">
                <DatePicker
                  selected={formik.values.datetruck}
                  name="datetruck"
                  defaultValue={formik.values.datetruck}
                  onChange={(a) => formik.setFieldValue("datetruck", a)}
                  className="form-control date-picker"
                />
                <span className="text-danger">
                  {formik.touched.datetruck && formik.errors.datetruck}
                </span>{" "}
              </div>
              <div className="form-note">
                Date Format <code>mm/dd/yyyy</code>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="timeair">
                Time of Arrival
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="timetruck"
                  name="timetruck"
                  className="form-control"
                  defaultValue={formik.values.timetruck}
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>{" "}
        <Row>
          <Col lg="1">
            <Button onClick={volver}>Back</Button>
          </Col>
          <Col lg="1">
            <Button type="submit">Next</Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Truck;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Icon from "../../../components/icon/Icon";
import classnames from "classnames";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
} from "../../../components/block/Block";
import {
  Button,
  BlockBetween,
  TooltipComponent,
  OverlineTitle,
  RSelect,
} from "../../../components/Component";
import "react-data-table-component-extensions/dist/index.css";
import CalculosMx from "./calculos";
import Welcome from "./welcome";
import MxPalletExpo from "./MxPalletExpo";
import MxTipoTRansporte from "./MxTipoTransporte";

const Principal = () => {
  var [hidden, sethidden] = useState({
    ltl: false,
    ftl: false,
    expo: false,
    summary: false,
    welcome: true,
    pais: "",
    tipo: false,
  });
  return (
    <>
      <React.Fragment>
        <Head title="GSA SELLER B2B Module"></Head>

        {/* <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>
                  B2C Seller - Quick Quotation and Booking
                </BlockTitle>
                <BlockDes className="text-soft">
                  <p>Delivery Inventory to FBA</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <ul className="nk-block-tools g-3">
                  <li>
                    <Button color="light" outline className="btn-white">
                      <Icon name="download-cloud"></Icon>
                      <span>Back</span>
                    </Button>
                  </li>
                </ul>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead> */}
        <div className="card-stretch">
          <div className="card-inner">
            {hidden.welcome ? (
              <Welcome hidden={hidden} sethidden={sethidden}></Welcome>
            ) : null}
            {hidden.tipo ? (
              <MxTipoTRansporte
                hidden={hidden}
                sethidden={sethidden}
              ></MxTipoTRansporte>
            ) : null}
            {hidden.ltl ? <CalculosMx></CalculosMx> : null}
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
export default Principal;

import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
} from "../../components/Component";

const ListHtsTools = ({ encabezado, setencabezado }) => {
  const search = () => {
    setencabezado({
      ...encabezado,
      hidden3: false,
      hidden2: true,
    });
  };
  return (
    <>
      <Link role="button" to="/htstax">
        <Button onClick={search} variant="contained" color="secondary">
          + Nueva Búsqueda
        </Button>
      </Link>
      &nbsp; &nbsp; &nbsp;
      <Link role="button" to="/hts">
        <Button variant="contained" color="secondary">
          + Volver a Lista
        </Button>
      </Link>
    </>
  );
};

export default ListHtsTools;

import React from "react";
import Principal from "./components/principal";


const CalculatorB2C= () => {
  return (
    <React.Fragment>
      <Principal></Principal>
    </React.Fragment>
  );
};

export default CalculatorB2C;

import React, { useState, useEffect } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { CardTitle } from "reactstrap";
import {nanoid} from 'nanoid';
import {
  Icon,
  TooltipComponent,
  Button,
  Row,
  Block,
} from "../../components/Component";
import {
  checkFileMIMEType,
  checkFileSize,
  getFormattedSize,
} from '../../profile/helpers/files.helper';
import DataTable from "react-data-table-component";
import { GetDocumentos } from "../helpers/getdocumentos";

import { DeleteDocument } from "../helpers/deletedocuments";
import Swal from "sweetalert2";
import { BajarDocument } from "../helpers/bajardocumentos";
import { FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import FileProvider from "../../profile/providers/file.provider";


const blobToBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  }
);

const DocumentsGenerales = ({clave}) => {
  const [documents, setdocuments] = useState();
  const [flag, setflag] = useState(false);
  const { register, handleSubmit } = useForm();
  const MAXIMUM_FILES_LIMIT = null;
  /**
   * Size in MB
   */
  const MAXIMUM_FILE_SIZE = 20;
  const ALLOWED_FORMATS = ".pdf, .doc, .docx, .odt, image/*";

  const [files, setFiles] = useState([]);

  const [escondido, setEscondido] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleFilesSubmit = async (event) => {
    event.preventDefault();
    for (const file of files) {
      const response = await new FileProvider().sendProduct(file, clave)
      if (response.data === 'ok'){
        Swal.fire({
          icon: 'success',
          title: 'Successfuly upload',
          text: 'all right!',
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'You must complete the filter data',
          text: 'Something went wrong!',
        });
      }
    }
  };

  const getCurrentFiles = async (event) => {
    const rawFiles = event.target.files;
    const fileList = []; // WIll contain an array of type File[]
    if (!rawFiles) {
      return;
    }
    if (rawFiles instanceof Array) {
      rawFiles.forEach((file) => fileList.push(file));
    } else {
      const rawFilesArray = Array.from(rawFiles);
      rawFilesArray.forEach((file) => fileList.push(file));
    }
    const finalFileList = [];
    for (const file of fileList) {
      const extensionSeparatorIndex = file.name.lastIndexOf('.');
      const formattedName = file.name.substring(
        0,
        extensionSeparatorIndex
      );
      const extension = file.name.substr(extensionSeparatorIndex);
      const encodedData = await blobToBase64(file);
      const formattedFile = {
        name: formattedName,
        extension,
        file: encodedData?.toString() ?? '',
        type: 'append',
        size: file.size,
      };
      if (
        files.find(
          (appendedFile) => appendedFile.name === formattedFile.name
        )
      ) {
        Swal.fire({
          icon: 'info',
          title: `The file ${
            formattedFile.name + formattedFile.extension
          } is already added`,
        });
        break;
      }
      if (!checkFileMIMEType(file.type, ALLOWED_FORMATS)) {
        Swal.fire({
          icon: 'warning',
          title:
            'Please select another file (Supported formats: images and PDF)',
          html: `<small class="text-muted">The file ${file.name} is not supported</small>`,
        });
        break;
      }
      if (!checkFileSize(file.size, MAXIMUM_FILE_SIZE)) {
        Swal.fire({
          icon: 'warning',
          title: `The maximum file size is ${MAXIMUM_FILE_SIZE}MB`,
          html: `<small class="text-muted">The file ${
            file.name
          } have a size of ${getFormattedSize(file.size, 2)}</small>`,
        });
        break;
      }
      if (MAXIMUM_FILES_LIMIT) {
        if (files.length < MAXIMUM_FILES_LIMIT) {
          finalFileList.push(file);
        } else {
          Swal.fire({
            icon: 'info',
            title: `The maximum file quantity to upload is ${MAXIMUM_FILES_LIMIT}`,
          });
          break;
        }
      } else {
        finalFileList.push(file);
      }
    }
    if (finalFileList.length > 0) {
      setFiles([...files, ...finalFileList]);
    }
    event.target.value = event.target.defaultValue; // Reset input cached value
  };

  useEffect(() => {
    GetDocumentos(clave).then((response) => setdocuments(response));
  },  [flag]);

  const ActionComponent = ({ id, text, color, icon, row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button onClick={clickHandler}>
        <>
          <div className={`card-tools text-${color}`}>
            <TooltipComponent
              icon={icon}
              direction="left"
              id={id}
              text={text}
            />
          </div>
        </>
      </Button>
    );
  };

  const deleterow = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteDocument(e).then(() => {
          const newstate = documents.filter((item) => item.name !== e);
          setdocuments(newstate);
        });
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
    // remove file
  const removeFile = (event, index) => {
    if (event.isTrusted) {
      const currentFiles = Object.create(files);
      currentFiles.splice(index, 1);
      setFiles([...currentFiles]);
    }
  };

  const bajar = (e) => {
    BajarDocument(e).then((resp) => window.open(resp, "_blank"));
  };

  const columnas = [
    {
      name: "File Name",
      selector: (row) => `${row.name}`,
      cell: (row) => (row ? row.name.split(`${clave}/`) : null),
    },
    // {
    //   name: "Size",
    //   selector: (row) => row.size,
    // },
    {
      cell: (row) => (
        <>
          <ActionComponent
            id="tooltip-2"
            text="Download File"
            color="success"
            icon="file-download"
            row={row.name}
            onClick={bajar}
          ></ActionComponent>
          <ActionComponent
            id="tooltip-1"
            text="Delete File"
            color="danger"
            icon="trash"
            row={row.name}
            onClick={deleterow}
          ></ActionComponent>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Content>
        <div>
        <form onSubmit={handleFilesSubmit}>
          <div className="input-group">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                onChange={getCurrentFiles}
                multiple
                id="file-uploader"
              />
              <label
                className="custom-file-label"
                htmlFor="file-uploader"
              >
                Choose files
              </label>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              {files.length > 0 && (
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  type="submit"
                >
                  Upload listed files
                </Button>
              )}
              <span
                className={[
                  'text-primary',
                  files.length > 0 && 'float-right',
                ]
                  .filter(Boolean)
                  .join(' ')}
              >
                {files.length > 0 &&
                  `Files in queue (${files.length}):`}
              </span>
            </div>
          </div>
          {files.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th className="text-info">File Name</th>
                  <th className="text-info">File Size</th>
                  <th className="text-info">Actions</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => (
                  <tr key={nanoid()}>
                    <td>{file.name}</td>
                    <td>{getFormattedSize(file.size, 2)}</td>
                    <td>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={(event) => removeFile(event, index)}
                        disabled={submitting}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </form>
        </div>
      </Content>

      <DataTable
        responsive
        pointerOnHover
        dense
        striped
        pagination
        columns={columnas}
        data={documents}
      />
    </React.Fragment>
  );
};
export default DocumentsGenerales;

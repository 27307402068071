/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Center from "react-center";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { PreviewCard } from "../../components/preview/Preview";
import {
  BlockHead,
  BlockTitle,
  Icon,
  BlockDes,
  BlockHeadContent,
  Block,
  BlockBetween,
} from "../../components/Component";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";


const Stripe = ({pn}) => {
  
  let date = new Date().toDateString();
  
  return (
    <>
      <React.Fragment>
        <Head title="Invoice Detail"></Head>
        <Content>
          <Block>
            <div className="invoice">
              <div className="invoice-action">
                {/* <Link to={`${process.env.PUBLIC_URL}/invoice-print/${user.id}`} target="_blank">
                  <Button size="lg" color="primary" outline className="btn-icon btn-white btn-dim">
                    <Icon name="printer-fill"></Icon>
                  </Button>
  </Link> */}
              </div>
              <div className="invoice-wrap">
                <div className="invoice-brand text-left">
                  <img
                    src="https://fotos-ecl.s3.amazonaws.com/new-ecl-logo+(11)"
                    alt=""
                  />
                </div>

                <div className="invoice-head">
                  <div className="invoice-contact">
                    <span className="overline-title"></span>
                    <div className="invoice-contact-info">
                      <h4 className="title">Ecommerce Logistics LLC</h4>
                      <ul className="list-plain">
                        <li>
                          <Icon name="map-pin-fill"></Icon>
                          <span>
                            200 Leslie Dr.
                            <br />
                            Hallandale Beach, FL 33099
                          </span>
                        </li>
                        <li>
                          <Icon name="call-fill"></Icon>
                          <span>+1(786)250-9701</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="invoice-desc">
                    <h3 className="title">Invoice</h3>
                    <ul className="list-plain">
                      <li className="invoice-id">
                        <span>Invoice ID</span>:<span>{pn.idpn}</span>
                      </li>
                      <li className="invoice-date">
                        <span>Date</span>:<span>{date}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="invoice-bills">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-150px">Item ID</th>
                          <th className="w-60">Description</th>
                          <th>Price</th>
                          <th>Qty</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>PN1000</td>
                          <td>Prior Notice - Emission Support</td>
                          <td>$18.95</td>
                          <td>1</td>
                          <td>$18.95</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">Subtotal</td>
                          <td>$18.95</td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">Processing fee</td>
                          <td>$00.00</td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">TAX</td>
                          <td>$00.00</td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2">Grand Total</td>
                          <td>$18.95</td>
                        </tr>{" "}
                      </tfoot>
                    </table>
                    <form
                      action="https://apiecl.com/checkout"
                      method="POST"
                    >
                      <input name="idpn" defaultValue={pn.idpn} hidden></input>
                      <div className="text-right">
                        <Button type="submit" size="xl">
                          <span>Checkout</span>&nbsp;&nbsp;
                          <img
                            src="https://fotos-ecl.s3.amazonaws.com/icons8-stripe+(1).svg"
                            alt=""
                          />
                        </Button>
                      </div>
                    </form>
                    <div className="nk-notes ff-italic fs-12px text-soft">
                      Invoice was created on a computer and is valid without the
                      signature and seal.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Block>
        </Content>
      </React.Fragment>
    </>
  );
};

export default Stripe;
/* const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
); */

/*export default function App() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay />
  );
} */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from "react";
import { GetFba } from "../helpers/getfba";



export const UseListFba = (idcliente) => {
 
const [state, setState] = useState({
    data: [],
  });

  useEffect(() => {
    GetFba().then((imgs) => {
      setState({
        data: imgs,
      });
    });
  }, []);
 
  return state;
};
/* eslint-disable import/prefer-default-export */
export const HtsGetCategories = async htsnumber => {
	
	var requestOptions = {
		method: 'GET',
		redirect: 'follow'
	  };

	// const url = "https://j6zrjwrhe0.execute-api.us-west-2.amazonaws.com/prod/htsgov";
	const resp = await fetch(`https://apiecl.com/getcategories?clave=${htsnumber}`, requestOptions);
	const data = await resp.json();
	

	return data;
};

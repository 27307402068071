/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";
import { RSelect } from "../../components/Component";
import DatePicker from "react-datepicker";
import states from "states-us";
import { codes, byAlpha2, byAlpha3, byNumeric } from "iso-country-codes";
import { useFormik, Field } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  carriernamecourier: yup.string().required("Carrier Name is Required"),
  trackingcourier: yup.string().required("Tracking Nr. is Required"),
  shimpentmodecourier: yup.string().required("Shipment Mode is Required"),
  date: yup.string().required("Date Arrival is Required"),
});

const Courier = ({ setActiveStep, pn, setpn, value, option }) => {
  const shimpentmodecourier = [
    { value: "Air", label: "Air" },
    { value: "Ground", label: "Ground" },
  ];

  const volver = () => {
    setActiveStep(1);
  };

  const [startDate, setStartDate] = useState(new Date());

  const formik = useFormik({
    initialValues: {
      carriernamecourier: pn.courier.carriernamecourier,
      trackingcourier: pn.courier.trackingcourier,
      shimpentmodecourier: pn.courier.shimpentmodecourier,
      date: pn.courier.date,
    },
    validationSchema,
    onSubmit: (values) => {
      setpn({
        ...pn,
        mode: {
          mode: value,
          purpose: option.proposito,
          other: option.otro,
        },
        idpn: values.trackingcourier,
        courier: {
          carriernamecourier: values.carriernamecourier,
          trackingcourier: values.trackingcourier,
          shimpentmodecourier: values.shimpentmodecourier,
          date: values.date,
        },
      });
      setActiveStep(3);
    },
  });
  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit} id="validation-forms">
        <Row className="g-4">
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="carriernamecourier">
                Name of Carrier *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="carriernamecourier"
                  name="carriernamecourier"
                  className="form-control"
                  defaultValue={formik.values.carriernamecourier}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.carriernamecourier &&
                    formik.errors.carriernamecourier}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="trackingcourier">
                Tracking Number *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="trackingcourier"
                  name="trackingcourier"
                  className="form-control"
                  defaultValue={formik.values.trackingcourier}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.trackingcourier &&
                    formik.errors.trackingcourier}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="shimpentmodecourier">
                Mode of Shipment *
              </label>
              <div className="form-control-wrap">
                <RSelect
                  id="shimpentmodecourier"
                  name="shimpentmodecourier"
                  options={shimpentmodecourier}
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "shimpentmodecourier",
                      selectedOption.value
                    )
                  }
                  defaultValue={formik.values.shimpentmodecourier}
                />
                <span className="text-danger">
                  {formik.touched.shimpentmodecourier &&
                    formik.errors.shimpentmodecourier}
                </span>
                <strong className="text-info">
                  {formik.values.shimpentmodecourier
                    ? `Mode Selected: ${formik.values.shimpentmodecourier}`
                    : null}
                </strong>
              </div>
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="datearrivalcourier">
                Date of Arrival *
              </label>
              <div className="form-control-wrap">
                <DatePicker
                  selected={formik.values.date}
                  name="date"
                  defaultValue={formik.values.date}
                  onChange={(a) => formik.setFieldValue("date", a)}
                  className="form-control date-picker"
                />
                <span className="text-danger">
                  {formik.touched.date && formik.errors.date}
                </span>
              </div>
              <div className="form-note">
                Date Format <code>mm/dd/yyyy</code>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col lg="1">
            <Button onClick={volver}>Back</Button>
          </Col>
          <Col lg="1">
            <Button type="submit">Next</Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Courier;

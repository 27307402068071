/* eslint-disable no-unused-vars */
import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BlockBetween,
} from "../../components/Component";

import { useAuth0 } from "@auth0/auth0-react";
import ListHtsTools from "./ListHtsTools";
import ListHtsTable from "./ListHtsTable";

export default function Principal() {
  const { isAuthenticated, logout, user } = useAuth0();

  return (
    <div>
      <React.Fragment>
        <Head title="Dashboard"></Head>
        <Content>
          <BlockHead size="sm"></BlockHead>
          <br></br>
          <BlockHead size="sm">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Dashboard
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to GSA Seller Accelerator</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent></BlockHeadContent>
          </BlockHead>
          <Block>
            <Row className="g-gs">
              <Col lg="4" md="4">
                <PreviewAltCard className="border-secondary h-10">
                  <ListHtsTools />
                </PreviewAltCard>
              </Col>
              <br></br>
              <Col lg="12">
                <PreviewAltCard className="h-100 border-secondary">
                  <ListHtsTable idcliente={user.sub} />
                </PreviewAltCard>
              </Col>{" "}
            </Row>
          </Block>
        </Content>
      </React.Fragment>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { GetSku } from '../helpers/GetSku';



const UseGetSku = idseller => {
	const [state, setState] = useState({
		productos: []
	});

	useEffect(() => {
		GetSku(idseller).then(imgs => {
			setState({
				productos: imgs
			});
		});
	}, [idseller]);

	return state;
};

export default UseGetSku;
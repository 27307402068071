export const GetOps = async (cliente) => {
  
    
    var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const response = await fetch(
    `https://apiecl.com/getopb2b?id_seller=${cliente}`,
    requestOptions
  );
  const respuesta = response.json();
  return respuesta;
};





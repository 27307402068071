/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import Icon from "../../../components/icon/Icon";
import classnames from "classnames";
import DataTable from "react-data-table-component";
import { Label, Form } from "reactstrap";
import { Modal, ModalBody, FormGroup, Collapse, Input } from "reactstrap";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
} from "../../../components/block/Block";
import {
  Button,
  BlockBetween,
  TooltipComponent,
  OverlineTitle,
  RSelect,
} from "../../../components/Component";
import Select from "react-select";
import "react-data-table-component-extensions/dist/index.css";
import Ops from "./ops";
import { UseListFba } from "../hooks/usegetfba";
import classNames from "classnames";
import { useForm } from "react-hook-form";

const Fbas = ({ setbookingfinal, bookingfinal }) => {
  const formClass = classNames({
    "form-validate": true,
  });

  function currencyFormatter(value) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: "USD"
    }) 
    return formatter.format(value)
  }

  const { data } = UseListFba();

  const [selectfba, setselectfba] = useState();

  useEffect(() => {
    const fbas = [];
    data.forEach((element) =>
      fbas.push({ value: element.code, label: element.code })
    );
    setselectfba({ value: null, fbas });
  }, [data]);

  const { errors, register, handleSubmit } = useForm();

  const [listaeventos, setlistaeventos] = useState({
    listaeventos: [],
  });
  const [eventos, seteventos] = useState({
    id_evento: "",
    tipo: "",
    qty: "",
    handling: "",
    fba: "",
  });

  const [totalh, settotalh] = useState()

  useEffect(() => {
    let totalito = 0;
    listaeventos.listaeventos.forEach(
      (element) => (totalito = totalito + element.hc)
    );
    settotalh(totalito)
    setbookingfinal({...bookingfinal, handling: totalito, distribucion: listaeventos.listaeventos })
    
  }, [listaeventos]);

  useEffect(() => {
    if (eventos.tipo === "Pallets") {
      if (parseFloat(eventos.qty) * 9 < 60) {
        seteventos({ ...eventos, hc: 35.0 + 60.0 });
      } else {
        seteventos({ ...eventos, hc: 35.0 + 9 * parseFloat(eventos.qty) });
      }
    } else {
      if (parseFloat(eventos.qty) * 3.6 < 60) {
        seteventos({ ...eventos, hc: 35.0 + 60.0 });
      } else {
        seteventos({ ...eventos, hc: 35.0 + 3.6 * parseFloat(eventos.qty) });
      }
    }
  }, [eventos.qty, eventos.tipo]);

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button size="sm" onClick={clickHandler}>
        <Icon className="text-danger" name="trash" />
      </Button>
    );
  };

  const onFormSubmit = (e) => {
    if (
      eventos.id_evento === "" ||
      eventos.qty === "" ||
      eventos.tipo === ""
      //   eventos.width === "" ||
      //   eventos.height === ""
    ) {
      alert("Please Input Information");
    }
    // else if (eventos.tipo ==="Pallets") {

    //      setlistaeventos({
    //         listaeventos: [...listaeventos.listaeventos, eventos],
    //       });
    // }
    else {
      setselect({ ...select, value: null });
      setselectfba({ ...selectfba, value: null });
      setlistaeventos({
        listaeventos: [...listaeventos.listaeventos, eventos],
      });
      seteventos({ qty: "" });
      
    }
  };

  const handleChange = (e) => {
    const idelemento = listaeventos.listaeventos.length + 1;
    seteventos({
      ...eventos,
      [e.target.name]: e.target.value,
      id_evento: idelemento,
    });
  };

  const deleterow = (e) => {
    const newstate = listaeventos.listaeventos.filter(
      (item) => item.id_evento !== e
    );
    setlistaeventos({ listaeventos: newstate });
  };

  const columns = [
    {
      name: "Item Nro.",
      selector: (row) => `${row.id_evento}`,
      cell: (row) => `${row.id_evento}`,
    },
    {
      name: "Packaging",
      selector: (row) => `${row.tipo}`,
      cell: (row) => `${row.tipo}`,
    },
    {
      name: "Quantities",
      selector: (row) => `${row.qty}`,
      cell: (row) => `${row.qty}`,
    },
    {
      name: "FBA",
      selector: (row) => `${row.fba}`,
      cell: (row) => `${row.fba}`,
    },
    {
      name: "Handling Cost",
      selector: (row) => currencyFormatter(row.hc),
      cell: (row) => currencyFormatter(row.hc),
    },

    {
      name: "Actions",
      center: true,

      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row) => (
        <ActionComponent
          row={row.id_evento}
          onClick={deleterow}
        ></ActionComponent>
      ),
    },
  ];

  const pack = [
    { value: "Pallets", label: "Pallets" },
    { value: "Boxes", label: "Boxes" },
  ];

  const [select, setselect] = useState({
    value: null,
    pack,
  });

  return (
    <>
      <React.Fragment>
        <Head title="GSA SELLER B2B Module"></Head>
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <ul className="nk-block-tools g-3">
                  <li></li>
                </ul>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <div className="card-stretch">
            <div className="card-inner">
              <Form onSubmit={handleSubmit(onFormSubmit)} className={formClass}>
                <Row className="g-gs">
                  <Col lg="3">
                    <label className="form-label" htmlFor="qty">
                      Packaging to Delivery
                    </label>
                    <div>
                      <FormGroup>
                        <RSelect
                          options={select.pack}
                          value={select.value}
                          placeholder="Select"
                          onChange={(e) => {
                            setselect({ ...select, value: e });
                            seteventos({
                              ...eventos,
                              tipo: e.value,
                            });
                          }}
                        />
                      </FormGroup>{" "}
                    </div>
                  </Col>

                  <Col lg="3">
                    {" "}
                    <FormGroup>
                      <label className="form-label" htmlFor="legalname">
                        Quantities
                      </label>
                      <div className="form-control-wrap">
                        <input
                          className="form-control"
                          type="number"
                          id="qty"
                          name="qty"
                          value={eventos.qty}
                          onChange={handleChange}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <label className="form-label" htmlFor="pais">
                      Amazon FBA
                    </label>
                    <RSelect
                      placeholder="Select FBA"
                      //onChange={SelectChange}
                      //   defaultValue={{
                      //     label: datas[0] ? datas[0].country_origin : null,
                      //     value: datas[0] ? datas[0].country_origin : null,
                      //   }}
                      options={selectfba ? selectfba.fbas : null}
                      value={selectfba ? selectfba.value : null}
                      onChange={(e) => {
                        setselectfba({ ...selectfba, value: e });
                        seteventos({
                          ...eventos,
                          fba: e.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <br></br>
                      <Button color="secondary" size="md">
                        <Icon name="arrow-left"></Icon>
                        {/* <Spinner size="sm" type="grow" /> */}
                        <span>Add Item</span>
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              <DataTable
                dense
                striped
                columns={columns}
                data={listaeventos.listaeventos}
              />
              <Col lg="4">
                <label className="form-label" htmlFor="qty">
                  Total Handling Cost
                </label>
                <br></br>
               <strong> {currencyFormatter(totalh)}</strong>
                {/* <input
                      className="form-control"
                      type="number"
                      id="totalh"
                      name="totalh"
                      value={totalh}
                      disabled
                    />{" "} */}
              </Col>
            </div>
          </div>
        </Content>
      </React.Fragment>
    </>
  );
};
export default Fbas;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
} from "../../components/Component";
import { findUpper } from "../../utils/Utils";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Center from "react-center";
import { useAuth0 } from "@auth0/auth0-react";
import { SaveEvento } from "../helpers/savepn";
const Review = ({ pn, setpn, setActiveStep, setActiveIconTab }) => {
  const pagar = () => {
    SaveEvento(pn).then((reponse) => setActiveStep(5));
  };


  const grabar = () => {
   
   SaveEvento(pn).then((value) => window.location.replace("/priornotice"))
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Units",
      selector: (row) => row.units,
    },
    {
      name: "Weight/unit",
      selector: (row) => row.w,
    },
    {
      name: "Manufacturer",
      selector: (row) => row.manufacturer,
    },
    {
      name: "Address",
      selector: (row) => row.maddress,
    },
    {
      name: "City",
      selector: (row) => row.mcity,
    },
    {
      name: "State/Province",
      selector: (row) => row.mstate,
    },
    {
      name: "Country",
      selector: (row) => row.mcountry,
    },
    {
      name: "Zip Code",
      selector: (row) => row.mzipcode,
    },
  ];

  const data = [];

  return (
    <React.Fragment>
      <Content>
        <Block>
          <Row className="gy-5">
            <Col lg="6">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5" className="text-primary">
                    Shipper Info
                  </BlockTitle>
                  <p>
                    <strong>Información del Enviador</strong>
                  </p>
                </BlockHeadContent>
              </BlockHead>
              <Card className="card-bordered border-secondary">
                <ul className="data-list is-compact">
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Company Name</div>
                      <div className="data-value">
                        {pn.shipper.shcompanyname}
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">
                        FDA Registration
                      </div>
                      <div className="data-value">
                        {pn.shipper.shfdaregistration}
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Contact Name</div>
                      <div className="data-value">
                        {pn.shipper.shcontactname}
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Phone</div>
                      <div className="data-value">{pn.shipper.shphone}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">e-mail</div>
                      <div className="data-value">{pn.shipper.shemail}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Address</div>
                      <div className="data-value">{pn.shipper.shaddress}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">City</div>
                      <div className="data-value">{pn.shipper.shcity}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">State/Province</div>
                      <div className="data-value">{pn.shipper.shstate}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Zip Code</div>
                      <div className="data-value">{pn.shipper.shzipcode}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Country</div>
                      <div className="data-value">{pn.shipper.shcountry}</div>
                    </div>
                  </li>
                </ul>
              </Card>
            </Col>
            <Col lg="6">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5" className="text-primary">
                    Consignee Info
                  </BlockTitle>
                  <p>
                    <strong>Información del Importador</strong>
                  </p>
                </BlockHeadContent>
              </BlockHead>
              <Card className="card-bordered border-secondary">
                <ul className="data-list is-compact">
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Company Name</div>
                      <div className="data-value">
                        {pn.consignee.ccompanyname}
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">
                        FDA Registration
                      </div>
                      <div className="data-value">
                        {pn.consignee.cfdaregistration}
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Contact Name</div>
                      <div className="data-value">
                        {pn.consignee.ccontactname}
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Phone</div>
                      <div className="data-value">{pn.consignee.cphone}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">e-mail</div>
                      <div className="data-value">{pn.consignee.cemail}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Address</div>
                      <div className="data-value">{pn.consignee.caddress}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">City</div>
                      <div className="data-value">{pn.consignee.ccity}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">State/Province</div>
                      <div className="data-value">{pn.consignee.cstate}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Zip Code</div>
                      <div className="data-value">{pn.consignee.czipcode}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Country</div>
                      <div className="data-value">{pn.consignee.ccountry}</div>
                    </div>
                  </li>
                </ul>
              </Card>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row className="gy-5">
            <Col lg="6">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5" className="text-primary">
                    Shipping and Transport Info
                  </BlockTitle>
                  <p>
                    <strong>Información del Embarque</strong>
                  </p>
                </BlockHeadContent>
              </BlockHead>
            </Col>
          </Row>
          <Row className="gy-5">
            <Col lg="6">
              <Card className="card-bordered border-secondary">
                <ul className="data-list is-compact">
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Modal</div>
                      <div className="data-value">{pn.mode.mode}</div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label text-info">Purpose</div>
                      <div className="data-value">{pn.mode.purpose}</div>
                    </div>
                  </li>
                  {pn.mode.purpose === "Other" ? (
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Others</div>
                        <div className="data-value">{pn.mode.others} </div>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </Card>
            </Col>
            {/* COURIER */}
            {pn.mode.mode === "Courier" ? (
              <Col lg="6">
                <Card className="card-bordered border-secondary">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Carrier Name</div>
                        <div className="data-value">
                          {pn.courier.carriernamecourier}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Tracking Number
                        </div>
                        <div className="data-value">
                          {pn.courier.trackingcourier}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Shipment Mode
                        </div>
                        <div className="data-value">
                          {pn.courier.shimpentmodecourier}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Arrival Date</div>
                        <div className="data-value">
                          {pn.courier.date.toDateString()}
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Col>
            ) : null}
            {/* END COURIER */}

            {/* AIR */}
            {pn.mode.mode === "Air" ? (
              <Col lg="6">
                <Card className="card-bordered border-secondary">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Carrier Name</div>
                        <div className="data-value">
                          {pn.air.carriernameair}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Carrier Country
                        </div>
                        <div className="data-value">{pn.air.aircountry}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Flight Number
                        </div>
                        <div className="data-value">{pn.air.flightair}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Air Will Bill Number
                        </div>
                        <div className="data-value">{pn.air.airwaybillair}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          State Entry to US
                        </div>
                        <div className="data-value">{pn.air.airstate}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Airport of Entry{" "}
                        </div>
                        <div className="data-value">{pn.air.airport}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Arrival Date</div>
                        <div className="data-value">
                          {pn.air.dateair.toDateString()}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Arrival Time</div>
                        <div className="data-value">{pn.air.timeair}</div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Col>
            ) : null}
            {/* END AIR */}
            {/* OCEAN */}
            {pn.mode.mode === "Ocean" ? (
              <Col lg="6">
                <Card className="card-bordered border-secondary">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Carrier Name</div>
                        <div className="data-value">
                          {pn.ocean.carriernameocean}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Carrier Country
                        </div>
                        <div className="data-value">
                          {pn.ocean.oceancountry}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Vessel Name</div>
                        <div className="data-value">{pn.ocean.vesselocean}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Container Name
                        </div>
                        <div className="data-value">
                          {pn.ocean.containerocean}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Voyage Number
                        </div>
                        <div className="data-value">{pn.ocean.voyageocean}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Bill of Landing Number
                        </div>
                        <div className="data-value">{pn.ocean.blocean}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          State Entry to US
                        </div>
                        <div className="data-value">{pn.ocean.oceanstate}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Port of Entry
                        </div>
                        <div className="data-value">{pn.ocean.portocean}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Port of Entry
                        </div>
                        <div className="data-value">{pn.ocean.portocean}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Date of Arrival
                        </div>
                        <div className="data-value">
                          {pn.ocean.dateocean.toDateString()}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Time of Arrival
                        </div>
                        <div className="data-value">{pn.ocean.oceantime}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Warehouse Address
                        </div>
                        <div className="data-value">{pn.ocean.wrocean}</div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Col>
            ) : null}
            {/* OCEAN */}
            {/* TRUCK */}
            {pn.mode.mode === "Truck" ? (
              <Col lg="6">
                <Card className="card-bordered border-secondary">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Carrier Name</div>
                        <div className="data-value">
                          {pn.truck.carriernametruck}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Carrier Country
                        </div>
                        <div className="data-value">
                          {pn.truck.truckcountry}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          License Plate
                        </div>
                        <div className="data-value">{pn.truck.license}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Bill of Landing
                        </div>
                        <div className="data-value">{pn.truck.blnumber}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          State entry to USA
                        </div>
                        <div className="data-value">{pn.truck.truckstate}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Point of Crossing USA
                        </div>
                        <div className="data-value">{pn.truck.crosspoint}</div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Arrival Date</div>
                        <div className="data-value">
                          {pn.truck.datetruck.toDateString()}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">
                          Time to Arrival
                        </div>
                        <div className="data-value">{pn.truck.timetruck}</div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Col>
            ) : null}
            {/* FIN TRUCK */}
            {/* POSTAL */}
            {pn.mode.mode === "Postal" ? (
              <Col lg="6">
                <Card className="card-bordered border-secondary">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Carrier Name</div>
                        <div className="data-value">
                          {pn.postal.carriernamepostal}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Arrival Date</div>
                        <div className="data-value">
                          {pn.postal.datepostal.toDateString()}
                        </div>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label text-info">Arrival Time</div>
                        <div className="data-value">{pn.postal.timepostal}</div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Col>
            ) : null}
            {/* FIN POSTAL */}
          </Row>
          <Row className="gy-5">
            <Col lg="12">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5" className="text-primary">
                    Products Info
                  </BlockTitle>
                  <p>
                    <strong>Información de los Productos</strong>
                  </p>
                </BlockHeadContent>
              </BlockHead>
              <Card className="card-bordered border-secondary">
                <DataTable
                  striped
                  data={pn.products}
                  columns={columns}
                ></DataTable>
              </Card>
            </Col>
          </Row>
          <br></br>
          <br></br>{" "}
          <Center>
            <Row>
             {/* <Button onClick={grabar} color="secondary">
                Save for Later
              </Button> */}
              &nbsp; &nbsp;
              <Button onClick={pagar} color="secondary">
                Finish & Pay
              </Button>
            </Row>
          </Center>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Review;

/* eslint-disable import/prefer-default-export */
export const GetSku = async (idcliente) => {
	const requestOptions = {
		method: 'GET',
		redirect: 'follow'
		
	};

	const resp = await fetch(`https://apiecl.com/getsku?id_seller=${idcliente.idcliente}`, requestOptions);
	const sku = await resp.json();
	return sku;
};

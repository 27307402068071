const Coyote = async (update,event) =>{

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const lista = {
    pallets: event,
    detalle: update
}
const raw = JSON.stringify({
 lista
});
const requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow',
  signal: AbortSignal.timeout(40000),
  
};


const response = await fetch(
    `https://apiecl.com/coyote`,
    requestOptions, 
  );
  const respuesta = response.json();
  return respuesta;

}

export default Coyote

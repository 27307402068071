
export const SaveEvento = async e => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(e);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return new Promise((resolve, reject) =>{ 
    fetch("https://o7x6kqobo4.execute-api.us-east-1.amazonaws.com/dev/pnsave", requestOptions)
    .then(response => response.text())
    .then(resolve)
    .catch(reject)
  })
}


/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  ModalBody,
  Modal,
  DropdownItem,
  Form,
  Badge,
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
  Row,
  Col,
  OverlineTitle,
  RSelect,
  
} from "../../components/Component";
import { useForm } from "react-hook-form";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { useListPn } from "../hooks/usegetpn";
import { useAuth0 } from "@auth0/auth0-react";


const History = () => {
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    add: false,
  });
  const [modalDetail, setModalDetail] = useState(false);

  const { isAuthenticated, logout, user } = useAuth0();

  const { data } = useListPn(user.sub);

  const datos = [];
  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button
        onClick={clickHandler}
        className="btn-round btn-icon"
        color="success"
        size="sm"
      >
        <Icon name="eye" />
      </Button>
    );
  };
  /*
  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.ref.localeCompare(b.ref));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.ref.localeCompare(a.ref));
      setData([...sortedData]);
    }
  }; */

  // function to reset the form
  /* const resetForm = () => {
    setFormData({
      orderType: "Buy",
      amountBTC: "",
      amountUSD: "",
      balanceBTC: "",
      balanceUSD: "",
      status: "Pending",
    });
  };
*/
  // function to close the form modal
  /* const onFormCancel = () => {
    setModal({ add: false });
    resetForm();
  }; */

  // submit function to add a new item
  /*const onFormSubmit = (submitData) => {
    const { amountBTC, amountUSD, balanceUSD, balanceBTC } = submitData;
    let submittedData = {
      id: data.length + 1,
      ref: "YWLX52JG73",
      date: "18/10/2019 12:04 PM",
      desc:
        formData.orderType === "Profit"
          ? "Credited " + formData.orderType
          : formData.orderType + " Funds",
      orderType: formData.orderType,
      amountBTC: amountBTC,
      amountUSD: amountUSD,
      balanceBTC: balanceBTC,
      balanceUSD: balanceUSD,
      status: formData.status,
    };
    setData([submittedData, ...data]);
    resetForm();
    setModal({ add: false });
  };
*/
  // function to change to approve property for an item
  /*
  const onActiveClick = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Active";
    setData([...newData]);
  };

  // function to change to reject property for an item
  const onPendingClick = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Pending";
    setData([...newData]);
  };

  const onInactiveClick = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Inactive";
    setData([...newData]);
  };

  // function to load detail data
  /* const loadDetail = (id) => {
    let index = data.findIndex((item) => item.id === id);
    setDetail(data[index]);
  }; */

  // function to toggle details modal
  const toggleModalDetail = () => setModalDetail(!modalDetail);

  const { errors, register, handleSubmit } = useForm();

  const columns = [
    {
      name: "Id",
      selector: (row) => row.idpn,
      sortable: true,
    },
    {
      name: "Shipping Mode",
      selector: (row) => row.mode.mode,
      sortable: true,
    },
    {
      name: "Purpose",
      selector: (row) => row.mode.purpose,
      sortable: true,
    },
    {
      name: "Shipper",
      selector: (row) => row.shipper.shcompanyname,
      sortable: true,
    },
    {
      name: "Consignee",
      selector: (row) => row.consignee.ccompanyname,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.daterequest,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => <Badge className="bg-info">{row.status}</Badge>,
      sortable: true,
    },
    {
      name: "Payment",
      selector: (row) =>
        row.pago ? (
          <Badge className="bg-success">Paid OK</Badge>
        ) : (
          <Badge className="bg-danger">Not Paid</Badge>
        ),
      sortable: true,
    },

    {
      name: "Actions",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      cell: (row) =>
        row.pago ? null : (
         <>
         <Link to={`/invoice?idpn=${row.idpn}`}>
            <img
              src="https://fotos-ecl.s3.amazonaws.com/icons8-stripe+(1).svg"
              alt=""
            />
          </Link>  
        </>
        ),
    },
  ];

  const nuevopago = () => {
    window.location.replace("/invoice");
  };
  const tableData = {
    columns,
    data,
  };

  return (
    <React.Fragment>
      <Head title="Seller List - GSA SELLER SYSTEM"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>PN List</BlockTitle>
              <BlockDes className="text-soft">
                <p>Global Selling Program</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                <li>
                  <Button color="light" outline className="btn-white">
                    <Icon name="download-cloud"></Icon>
                    <span>Export</span>
                  </Button>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className="rounded-pill border border-primary">
          <div className="card-stretch">
            <div className="card-inner">
              <DataTableExtensions export={false} print={false} {...tableData}>
                <DataTable pagination striped highlightOnHover />
              </DataTableExtensions>
            </div>
          </div>
        </Block>
        <Modal
          isOpen={modalDetail}
          toggle={() => toggleModalDetail()}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                toggleModalDetail();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            {/* <TabsPage detail={detail}></TabsPage> */}
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default History;

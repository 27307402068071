export const GetRateMx = async (wh, qty) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const response = await fetch(
    `https:///apiecl.com/getmx?wh=${wh}&qty=${qty}`,
    requestOptions
  );
  const respuesta = response.json();
  return respuesta;
};


/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Form } from "reactstrap";
import { RSelect } from "../../components/Component";
import DatePicker from "react-datepicker";
import states from "states-us";
import { codes, byAlpha2, byAlpha3, byNumeric } from "iso-country-codes";
import Courier from "./courier";
import Air from "./air";
import Ocean from "./ocean";
import Truck from "./truck";
import Postal from "./postal";

const Shipping = ({ pn, setpn, setActiveStep }) => {
  const [value, setvalue] = useState();
  const [option, setoption] = useState({
    proposito: "Commercial",
    otro: "",
  });

  const options = [
    { value: "Ocean", label: "Ocean" },
    { value: "Courier", label: "Courier" },
    { value: "Truck", label: "Truck" },
    { value: "Air", label: "Air" },
    { value: "Postal", label: "Postal" },
  ];

  const usestados = [];
  states.forEach((element) =>
    usestados.push({ value: element.abbreviation, label: element.name })
  );

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.alpha2, label: element.name })
  );

  //Seleccion del tipo de transporte

  const selectchange = (e) => {
    setvalue(e.value);
  };
  const optionchange = (e) => {
    setoption({
      ...option,
      proposito: e.target.value,
    });
  };

  const otherchange = (e) => {
    setoption({
      ...option,
      otro: e.target.value,
    });
  };

  return (
    <div>
      <Row className="g-4">
        <Col lg="3">
          <FormGroup className="form-group">
            <label className="form-label" htmlFor="fdaregistration">
              Select Mode of Transport *
            </label>
            <RSelect options={options} name="modos" onChange={selectchange} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="g-4">
        <Col lg="12">
          <FormGroup>
            <label className="form-label" htmlFor="fdaregistration">
              Purpose of Shipment *
            </label>
            <ul className="custom-control-group g-3 align-center flex-wrap">
              <li>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input form-control"
                    defaultChecked
                    name="purpose"
                    id="purpose1"
                    value="Commercial"
                    onChange={optionchange}
                  />
                  <label className="custom-control-label" htmlFor="purpose1">
                    Commercial
                  </label>
                </div>
              </li>
              <li>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input form-control"
                    name="purpose"
                    id="purpose2"
                    value="Analysis"
                    onChange={optionchange}
                  />
                  <label className="custom-control-label" htmlFor="purpose2">
                    Analysis
                  </label>
                </div>
              </li>
              <li>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input form-control"
                    name="purpose"
                    id="purpose3"
                    value="Personal"
                    onChange={optionchange}
                  />
                  <label className="custom-control-label" htmlFor="purpose3">
                    Personal
                  </label>
                </div>
              </li>
              <li>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input form-control"
                    name="purpose"
                    id="purpose4"
                    value="Samples"
                    onChange={optionchange}
                  />
                  <label className="custom-control-label" htmlFor="purpose4">
                    Samples
                  </label>
                </div>
              </li>
              <li>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input form-control"
                    name="purpose"
                    id="purpose5"
                    value="Transportation & Exportation"
                    onChange={optionchange}
                  />
                  <label className="custom-control-label" htmlFor="purpose5">
                    Transportation & Exportation
                  </label>
                </div>
              </li>
              <li>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input form-control"
                    name="purpose"
                    id="purpose6"
                    value="Other"
                    onChange={optionchange}
                  />
                  <label className="custom-control-label" htmlFor="purpose6">
                    Other
                  </label>
                </div>
              </li>
              <li>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="other"
                    name="other"
                    value={option.other}
                    onChange={otherchange}
                    className="form-control"
                  />
                </div>
              </li>
            </ul>
          </FormGroup>
        </Col>
      </Row>
      <hr></hr>
      <br></br>
      <br></br>
      {/* Courier */}
      {value === "Courier" ? (
        <Courier
          setActiveStep={setActiveStep}
          pn={pn}
          setpn={setpn}
          value={value}
          option={option}
        ></Courier>
      ) : null}
      {/* FIN COURIER */}

      {/* AIR  */}
      {value === "Air" ? (
        <Air
          setActiveStep={setActiveStep}
          pn={pn}
          setpn={setpn}
          value={value}
          option={option}
        ></Air>
      ) : null}
      {/* FIN AIR */}

      {/* OCEAN  */}
      {value === "Ocean" ? (
        <Ocean
          setActiveStep={setActiveStep}
          pn={pn}
          setpn={setpn}
          value={value}
          option={option}
        ></Ocean>
      ) : null}

      {/* FIN OCEAN */}

      {/* Land / Truck  */}
      {value === "Truck" ? (
        <Truck
          setActiveStep={setActiveStep}
          pn={pn}
          setpn={setpn}
          value={value}
          option={option}
        ></Truck>
      ) : null}
      {/* FIN TRUCK  */}

      {/* Correo  */}
      {value === "Postal" ? (
        <Postal
          setActiveStep={setActiveStep}
          pn={pn}
          setpn={setpn}
          value={value}
          option={option}
        ></Postal>
      ) : null}

      {/*<ActionButtons {...props} nextStep={validate} /> */}
    </div>
  );
};

export default Shipping;

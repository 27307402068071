export const CrearInvoiceStripe = async (idstripe, id_precio, centro_costo, id_seller,name, codigo,id_evento) => {
    
   
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "customer_id": idstripe,
      "price": id_precio,
      "centro_costo": centro_costo,
      "id_seller": id_seller,
      "name":   name,
      "codigo": codigo,
      "id_evento": id_evento,
    });
    
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    
   const resp =  await fetch("https://apiecl.com/stripeinvoice", requestOptions)
   const respuesta = await resp.json()
   return respuesta


  };


 

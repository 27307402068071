/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import { codes, byAlpha2, byAlpha3, byNumeric } from "iso-country-codes";
import { RSelect } from "../../components/Component";
import { useAuth0 } from "@auth0/auth0-react";
const validationSchema = yup.object({
  shcompanyname: yup.string().required("Company Name is Required"),
  shcontactname: yup.string().required("Name is Required"),
  shphone: yup.string().required("Phone is Required"),
  shemail: yup
    .string()
    .email("Invalid email Format")
    .required("Email is Required"),
  shaddress: yup.string().required("Address is Required"),
  shcity: yup.string().required("City is Required"),
  shstate: yup.string().required("State is Required"),
  shcountry: yup.string().required("Country is Required"),
  shzipcode: yup.string().required("Zip Code is Required"),
});

const Shipper = ({ pn, setpn, setActiveStep }) => {
  
  const { isAuthenticated, logout, user } = useAuth0();
  
  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );

  let date = new Date().toDateString();
  

  const formik = useFormik({
    initialValues: {
      shcompanyname: pn.shipper.shcompanyname,
      shfdaregistration: pn.shipper.shfdaregistration,
      shcontactname: pn.shipper.shcontactname,
      shphone: pn.shipper.shphone,
      shemail: pn.shipper.shemail,
      shaddress: pn.shipper.shaddress,
      shcity: pn.shipper.shcity,
      shstate: pn.shipper.shstate,
      shcountry: pn.shipper.shcountry,
      shzipcode: pn.shipper.shzipcode,
    },
    validationSchema,
    onSubmit: (values) => {
      setActiveStep(1);
      setpn({
        ...pn,
        idcliente: user.sub,
        pago: false,
        daterequest: date,
        status: "In Process",
        shipper: {
          shcompanyname: values.shcompanyname,
          shfdaregistration: values.shfdaregistration,
          shcontactname: values.shcontactname,
          shphone: values.shphone,
          shemail: values.shemail,
          shaddress: values.shaddress,
          shcity: values.shcity,
          shstate: values.shstate,
          shcountry: values.shcountry,
          shzipcode: values.shzipcode,
        },
      });
    },
  });

  return (
    <div>
      <form noValidate onSubmit={formik.handleSubmit} id="validation-forms">
        <Row className="g-4">
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="companyname">
                Company Name *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="shcompanyname"
                  name="shcompanyname"
                  className="form-control"
                  defaultValue={formik.values.shcompanyname}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.shcompanyname && formik.errors.shcompanyname}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="fdaregistration">
                FDA Registration
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="shfdaregistration"
                  name="shfdaregistration"
                  className="form-control"
                  defaultValue={formik.values.shfdaregistration}
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="contactname">
                Contact Name *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="shcontactname"
                  name="shcontactname"
                  className="form-control"
                  defaultValue={formik.values.shcontactname}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.shcontactname && formik.errors.shcontactname}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="phone">
                Phone *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="shphone"
                  name="shphone"
                  className="form-control"
                  defaultValue={formik.values.shphone}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.shphone && formik.errors.shphone}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="email">
                Email *
              </label>
              <div className="form-control-wrap">
                <input
                  type="email"
                  id="shemail"
                  name="shemail"
                  className="form-control"
                  defaultValue={formik.values.shemail}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.shemail && formik.errors.shemail}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="5">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="address">
                Address *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="shaddress"
                  name="shaddress"
                  className="form-control"
                  defaultValue={formik.values.shaddress}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.shaddress && formik.errors.shaddress}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="city">
                City *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="shcity"
                  name="shcity"
                  className="form-control"
                  defaultValue={formik.values.shcity}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.shcity && formik.errors.shcity}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="state">
                State/Province *
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="shstate"
                  name="shstate"
                  className="form-control"
                  defaultValue={formik.values.shstate}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.shstate && formik.errors.shstate}
                </span>
              </div>
            </FormGroup>
          </Col>
          {
            <Col lg="3">
              <FormGroup className="form-group">
                <label className="form-label" htmlFor="country">
                  Country *
                </label>
                <div className="form-control-wrap">
                  <RSelect
                    options={countries}
                    id="shcountry"
                    name="shcountry"
                    onChange={(selectedOption) =>
                      formik.setFieldValue("shcountry", selectedOption.value)
                    }
                    defaultValue={formik.values.shcountry}
                  />
                  <span className="text-danger">
                    {formik.touched.shcountry && formik.errors.shcountry}
                  </span>
                  <strong className="text-info">
                    {formik.values.shcountry
                      ? `Country Selected: ${formik.values.shcountry}`
                      : null}
                  </strong>
                </div>
              </FormGroup>
            </Col>
          }
          <Col lg="2">
            <FormGroup className="form-group">
              <label className="form-label" htmlFor="shzipcode">
                Zip Code
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="shzipcode"
                  id="shzipcode"
                  className="form-control"
                  defaultValue={formik.values.shzipcode}
                  onChange={formik.handleChange}
                />
                <span className="text-danger">
                  {formik.touched.shzipcode && formik.errors.shzipcode}
                </span>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        <Button type="submit">Next</Button>
      </form>

      {/*<ActionButtons {...props} nextStep={validate} /> */}
    </div>
  );
};

export default Shipper;

const GetChina = async htsnumber => {
	
	
	var requestOptions = {
		method: 'GET',
		redirect: 'follow'
	  };

	const resp = await fetch(`https://apiecl.com/chinos?clave=${htsnumber}`, requestOptions);
	const data = await resp.json();
	return data;
};

export default GetChina;


  
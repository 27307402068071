export const FirmarDocumento = async (info, id_cliente) => {
  var raw = JSON.stringify({
    info: info,
    id_cliente: id_cliente,
  });

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://apiecl.com/aceptarservicio", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
  
  }
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from "react";
import { ListPn } from "../helpers/listpn";


export const useListPn = (idcliente) => {
 
const [state, setState] = useState({
    data: [],
  });

  useEffect(() => {
    ListPn(idcliente).then((imgs) => {
      setState({
        data: imgs,
      });
    });
  }, []);
  return state;
};
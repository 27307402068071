import React from "react";
import Principal from "./components/principal";
//import Content from "../../layout/content/Content";
//import Head from "../../layout/head/Head";

const HTSTAX = () => {
  return (
    <React.Fragment>
      <Principal></Principal>
    </React.Fragment>
  );
};

export default HTSTAX;
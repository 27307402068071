/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { GetSeller } from '../../dashboard/helpers/getseller';


const UseGetseller = valued => {
	const [state, setState] = useState({
		seller: [],
		loading: true,
		totalito: ''
	});

	useEffect(() => {
		GetSeller(valued).then(imgs => {
			setState({
				seller: imgs
			});
		});
	}, []);
  
	return state;
};

export default UseGetseller;
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, FormGroup, ModalFooter, Collapse, Table, Input, Label, Button, Alert, Container} from "reactstrap";
import {
  Icon,
  Col,
  Row,
  RSelect,
} from "../../components/Component";
import { codes } from "iso-country-codes";
import Swal from "sweetalert2";
import { useAuth0 } from "@auth0/auth0-react";
import Content from "../../layout/content/Content";
import Iframe from "react-iframe";
import { InvoiceSigned } from "../helpers/getsign";
import { BajarFirmado } from "../helpers/bajarfirmado";
import SubidorExcel from "./SubidaDocumentos";
import ProductCategoriesSelector from "./product_categories_selector";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import { FirmarDocumento } from "../helpers/firmardocumento";

const ModalGeneral = ({
  modaldoc,
  setModaldoc,
  modalTabdoc,
  setModalTabdoc,
  seller,
  invoice,
  sethidden,
  hidden,
  
}) => {
  const fecha = new Date().toLocaleDateString();
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  const { isAuthenticated, logout, user } = useAuth0();
  console.log(user.sub)
  const clave = `${seller ? seller.id_cliente : ""}/${invoice}`;
  const [categorias, setcategorias] = useState({
    ToysGames: seller ? seller["categorias"]?.ToysGames : false,
    ChildrenApparels: seller ? seller["categorias"]?.ChildrenApparels : false,
    Clothing: seller ? seller["categorias"]?.Clothings : false,
    Sportswear: seller ? seller["categorias"]?.Sportswear : false,
    Electronics: seller ? seller["categorias"]?.Electronics : false,
    Furniture: seller ? seller["categorias"]?.Furniture : false,
    Textiles: seller ? seller["categorias"]?.Textiles : false,
    Pet: seller ? seller["categorias"]?.Pet : false,
    HealthPersonalCare: seller
      ? seller["categorias"]?.HealthPersonalCare
      : false,
    KitchenProducts: seller ? seller["categorias"]?.KitchenProducts : false,
    FoodandGroceries: seller ? seller["categorias"]?.FoodandGroceries : false,
  });

  const [invoices, setInvoices] = useState([]);
  const [info, setinfo] = useState(seller ? seller["categorias"]["info"] : " ");
  const [isChecked, setIsChecked] = useState({});
  
  const DocumentSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    position: Yup.string()
      .min(3, 'Too Short!')
      .required('Required'),
    company: Yup.string()
      .min(3, 'Too Short!')
      .required('Required'),
    checked: Yup.bool()
      .oneOf([true], 'You need to accept the terms and conditions'),
  });

  useEffect(() => {
    setcategorias({
      ToysGames: seller ? seller["categorias"]?.ToysGames : false,
      ChildrenApparels: seller ? seller["categorias"]?.ChildrenApparels : false,
      Clothing: seller ? seller["categorias"]?.Clothings : false,
      Sportswear: seller ? seller["categorias"]?.Sportswear : false,
      Electronics: seller ? seller["categorias"]?.Electronics : false,
      Furniture: seller ? seller["categorias"]?.Furniture : false,
      Textiles: seller ? seller["categorias"]?.Textiles : false,
      Pet: seller ? seller["categorias"]?.Pet : false,
      HealthPersonalCare: seller
        ? seller["categorias"]?.HealthPersonalCare
        : false,
      KitchenProducts: seller ? seller["categorias"]?.KitchenProducts : false,
      FoodandGroceries: seller ? seller["categorias"]?.FoodandGroceries : false,
    });
    setIsChecked({
      customCheck1: categorias ? categorias?.ToysGames : false,
      customCheck2: categorias ? categorias?.ChildrenApparels: false,
      customCheck3: categorias ? categorias?.Clothings: false,
      customCheck4: categorias ? categorias?.Sportswear: false,
      customCheck5: categorias ? categorias?.Electronics: false,
      customCheck6: categorias ? categorias?.Furniture: false,
      customCheck7: categorias ? categorias?.Textiles :  false,
      customCheck8: categorias ? categorias?.Pet: false,
      customCheck9: categorias ? categorias?.HealthPersonalCare: false,
      customCheck10: categorias ? categorias?.KitchenProducts: false,
      customCheck11: categorias ? categorias?.FoodandGroceries: false,
    });
  }, [seller, modaldoc]);

  const finish = () => {
    Swal.fire({
      icon: "success",
      title: "You have finished",
      text: "Remember Sign the Disclaimer or your process will not start",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setModaldoc(false);
      }
    });
  };

  const countries = [];
  codes.forEach((element) =>
    countries.push({ value: element.name, label: element.name })
  );

  const [sign, setsign] = useState("");
  const [spinnersign, setspinnersign] = useState(false);
  console.log('general')
  const signature = () => {
    setspinnersign(true);
    setsign(false);
    InvoiceSigned(user.sub, user.email,invoice.invoice, invoice.codigo).then((resp) => {
      setsign(resp);
      setspinnersign(false);
      //CONTROLO LA FIRMA DEL DISCLAIMER DEL REPORTE GENERAL DE COMPLIANCE
      var pusher = new Pusher("bc3e56fd2d6638db854d", {
        cluster: "mt1",
      });
      var channel = pusher.subscribe("my-channel");
      channel.bind(resp.id_doc, function (e) {
        sethidden(!hidden)
        setModaldoc(false);
        Swal.fire({
          icon: "success",
          title: "Congratulations",
          text: "You have completed your request.",
        });
      });
      //CIERRE DEL CONTROL DE LA FIRMA
    });
  };

  const [linkbajar, setlinkbajar] = useState();
  const Bajar = () => {
    BajarFirmado(seller.id_general).then((resp) => setlinkbajar(resp));
  };

  const [country, setCountry] = useState('United States')
  
  const SelectChange = (e) => {
    setCountry(
      e.value,
    );
  }
  console.log(country)
  return (
    <>
      <Modal
        isOpen={modaldoc}
        className="modal-dialog-centered"
        size="xl"
        toggle={() => setModaldoc(false)}
      >
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModaldoc(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>

          <div className="p-2">
            <h5 className="title">Preliminar General Risk Report</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTabdoc === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTabdoc("1");
                  }}
                  href="#personal"
                >
                  1. General Information
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTabdoc === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTabdoc("2");
                  }}
                  href="#address"
                >
                  2. Bussiness Details
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTabdoc === "3" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTabdoc("3");
                  }}
                  href="#address"
                >
                  3. Documents to Upload
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTabdoc === "4" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTabdoc("4");
                  }}
                  href="#address"
                >
                  4. Disclaimer
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTabdoc === "1" ? "active" : ""}`}
              id="personal"
            >
              <Content>
                <Row className="gy-4">
                  <Col md="3">
                    <FormGroup>
                      <label className="form-label text-primary" htmlFor="pais">
                        Export Company{" "}
                      </label>
                      <br></br>
                      <ul className="text-muted">
                        <li>{seller ? seller.legalname : ""}</li>
                        <li>
                          {seller ? seller.calle : ""}{" "}
                          {seller ? seller.numero : ""}{" "}
                          {seller ? seller.barrio : ""}
                        </li>
                        <li>
                          {seller ? seller.ciudad : ""}{" "}
                          {seller ? seller.estado : ""}{" "}
                          {seller ? seller.pais : ""}
                        </li>
                        <li>
                          {seller ? seller.zip : ""}, Phone:{" "}
                          {seller ? seller.telefono : ""}
                        </li>
                        <li>Tax Id: {seller ? seller.tax_id : ""}</li>
                      </ul>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <FormGroup>
                      <label className="form-label text-primary" htmlFor="pais">
                        Import Country{" "}
                      </label>
                      <br></br>
                      <RSelect
                        options={countries}
                        placeholder="United States"
                        onChange={SelectChange}
                        defaultValue="United States"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label text-primary" htmlFor="pais">
                        General Report Scope
                      </label>
                      <br></br>
                      <ul className="text-justify">
                        <li>
                          General Assessment of risks and feasibility of
                          compliance with regulations imposed by governmental
                          agencies government agencies in the USA.
                        </li>
                        <li>
                          Suggestions and services to be required to comply with
                          the regulations. to reduce of the risks of detention
                          and return of shipments at the border.
                        </li>
                      </ul>
                    </FormGroup>
                  </Col>
                </Row>
              </Content>
            </div>
            <div
              className={`tab-pane ${modalTabdoc === "2" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md='12' lg='12'>
                 <ProductCategoriesSelector seller={seller} />
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTabdoc === "3" ? "active" : ""}`}
              id="personal"
            >
           <SubidorExcel clave={clave}></SubidorExcel>
            </div>
            <div
              className={`tab-pane ${modalTabdoc === "4" ? "active" : ""}`}
              id="personal"
            >
              {invoice ? (
                invoice.signed === true ? (
                  <>
                    <Content>
                      {" "}
                      <Button color="danger" onClick={Bajar}>
                        Download Document
                      </Button>
                      <Iframe url={linkbajar} height="0px" width="0%" />
                    </Content>
                  </>
                ) : (
                  <Formik
                    initialValues={{
                      name: '',
                      position: '',
                      company: '',
                      checked: false,
                      country: country
                    }}
                    validationSchema={DocumentSchema}
                    onSubmit= { async (values) => {
                      console.log(values)
                      console.log(invoice)
                      FirmarDocumento(values, invoice)
                    }}
                  >
                    {({ errors, touched }) => (
                    <Row className="gy-4">
                      <Form>
                        <Content>
                          <Col md='12' lg='12' className='d-flex justify-content-end mt-2'>
                            <Button type='submit' color='primary'>Finalizar</Button>
                          </Col>
                            {errors.checked && touched.checked ? (
                              <Alert color="danger">You must complete all the fields at the bottom of the page</Alert>
                            ) : null}
                          <Col md='10' lg='10' className='mb-3'>
                            <h6><strong style={{color:"#136C7E"}}>Disclaimer.</strong></h6>
                            <p>
                                By accessing or using the Services, or by using our website, affiliated websites, and the like, you
                                agree to the following terms and conditions:<br/>
                            </p>
                          </Col>
                          <Col md='12' lg='12'>
                            <Table>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong style={{color:"#136C7E"}}>1.</strong> Your Company agrees, as a manufacturer, user, exporter, or importer, that it is legally responsible for each
                                    consumer product, and compliance with applicable governing laws, for each consumer product made, sold,
                                    imported or manufactured;
                                  </td>
                                  <td>
                                    <strong style={{color:"#136C7E"}}>2.</strong> Your Company acknowledges that Ecommerce Logistics LLC provides a general information service only to
                                    assist in the export, import and sale of consumer goods;
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong style={{color:"#136C7E"}}>3.</strong> Your Company acknowledges that the information and Services provided by Ecommerce Logistics LLC do
                                    not constitute legal advice or provided by licensed attorneys;<br/>
                                  </td>
                                  <td>
                                    <strong style={{color:"#136C7E"}}>4.</strong> Your Company acknowledges that the Services and information provided by Ecommerce Logistics LLC are
                                    not guaranteed, and their results are based on investigations conducted by its professional Compliance team
                                    with the best interests of your Company in mind.<br/>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong style={{color:"#136C7E"}}>5.</strong> Your Company acknowledges that the information and/or Services provided by Ecommerce Logistics LLC
                                    are not binding for any legal purposes, except for any agreement signed between Ecommerce Logistics and
                                    Your Company.<br/>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <Table>
                              <thead>
                                <tr>
                                  <th style={{color:"#136C7E"}}>
                                    Report Goals
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong style={{color:"#136C7E"}}>•</strong> Reduction of the risks of detention and return of shipments at the border.
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong style={{color:"#136C7E"}}>•</strong> General information on the application of the FTA or explain the requirements to obtain the activation of the
                                    CBP Number issued by US Customs, in the case of Sellers that are not established in the USA.
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong style={{color:"#136C7E"}}>•</strong> Description of the regulations for each category of product, in general; so that Sellers can take into
                                    consideration the risks and feasibility of complying with these regulations.
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Content>
                        <Col md='12' lg='12'>
                          <Content>
                            <Row>
                              <Col lg='4'>
                                <Label for="exampleEmail"><strong style={{color:"#136C7E"}}>Name</strong></Label>
                               <FormGroup>
                                 <Field type="text" name="name" id="name" className="form-control-lg form-control" placeholder="Please insert name" />
                               </FormGroup>
                                {errors.name && touched.name ? (
                                  <div><Alert color="danger">{errors.name} </Alert></div>
                                ) : null}
                              </Col>
                              <Col lg='4'>
                                  <Label for="examplePassword"><strong style={{color:"#136C7E"}}>Position</strong></Label>
                                <FormGroup>
                                  <Field type="text" name="position" id="position" className="form-control-lg form-control" placeholder="please insert position" />
                                </FormGroup>
                                {errors.position && touched.position ? (
                                    <div><Alert color="danger">{errors.position}</Alert></div>
                                  ) : null}
                              </Col>
                              <Col lg='4'>
                                  <Label for="examplePassword"><strong style={{color:"#136C7E"}}>Company</strong></Label>
                                <FormGroup>
                                  <Field type="text" name="company" id="company" className="form-control-lg form-control" placeholder="please  insert company" />
                                </FormGroup>
                                  {errors.company && touched.company ? (
                                    <div> <Alert color="danger">{errors.company} </Alert></div>
                                  ) : null}
                              </Col>
                              <Col lg='12' className='d-flex justify-content-center mt-3'>
                                <FormGroup>
                                  <Label for="examplePassword">
                                    <Field type="checkbox" name="checked" id="checked" />
                                        I agree with the terms and conditions of the present contracted service
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col lg='12' className='d-flex justify-content-center'>
                                {errors.checked && touched.checked ? (
                                    <div><Alert color="danger">{errors.checked}</Alert></div>
                                  ) : null}
                              </Col>
                              <Col lg='12' className='d-flex justify-content-center mt-3'>
                                <Button type='submit' color='primary'>Finalizar</Button>
                              </Col>
                            </Row>
                          </Content>
                          </Col>
                        </Form>
                      </Row>
                      )}
                  </Formik>
                )
              ) : null}
              <br></br>
            </div>
          </div>
        </ModalBody>
        {/* <ModalFooter className="bg-light">
          <img
            width="120"
            height="120"
            src="amazon-service_partners-badge (1).png"
            alt=""
          />
        </ModalFooter> */}
      </Modal>
    </>
  );
};

export default ModalGeneral;

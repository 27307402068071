const GetSpec = async () => {
	
	const requestOptions = {
		method: "POST",
	  };
	
	  const response = await fetch(
		`https://apiecl.com/tycspec`,
		requestOptions
	  );
	  const respuesta = response.json();
	  console.log(respuesta);
	  return respuesta;
};

export default GetSpec;

import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import {
  TooltipComponent,
  Button,
} from "../../components/Component";
import DataTable from "react-data-table-component";
import { GetDocumentos } from "../helpers/getdocumentos";

import { DeleteDocument } from "../helpers/deletedocuments";
import Swal from "sweetalert2";
import { BajarDocument } from "../helpers/bajardocumentos";
import { FormGroup, Table } from "reactstrap";
import { useForm } from "react-hook-form";

const DocumentsGenerales = ({clave}) => {
  const [documents, setdocuments] = useState();
  const [flag, setflag] = useState(false);
  const { register, handleSubmit } = useForm();
  const [filesname, setFilesname] = useState([]);

  const [escondido, setEscondido] = useState(false);

  const onSubmit = async (data) => {
    const formData = new FormData();
    const ultimo = data.file.length;
    for (let i = 0; i < ultimo; ++i) {
      formData.append("file", data.file[i]);
      const response = await fetch(
        `https://apiecl.com/uploaddocuments?id_seller=${clave}`,
        {
          method: "POST",
          body: formData,
        }
      ).then((res) => console.log(ultimo));
    }

    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Your work has been saved",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      setflag(!flag);
      setFilesname([]);
    });
    //alert(JSON.stringify(`${res.message}, status: ${res.status}`));
  };

  const getCurrentFiles = (event) => {
    var rawFiles = event.target.files;
    const finalFileList = [];
    for (const file of rawFiles) {
      finalFileList.push(file.name);
    }

    setFilesname(finalFileList);

    setEscondido(true);
  };

  useEffect(() => {
    GetDocumentos(clave).then((response) => setdocuments(response));
  },  [flag]);

  const ActionComponent = ({ id, text, color, icon, row, onClick }) => {
    const clickHandler = () => onClick(row);
    return (
      <Button onClick={clickHandler}>
        <>
          <div className={`card-tools text-${color}`}>
            <TooltipComponent
              icon={icon}
              direction="left"
              id={id}
              text={text}
            />
          </div>
        </>
      </Button>
    );
  };

  const deleterow = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteDocument(e).then(() => {
          const newstate = documents.filter((item) => item.name !== e);
          setdocuments(newstate);
        });
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const bajar = (e) => {
    BajarDocument(e).then((resp) => window.open(resp, "_blank"));
  };

  const columnas = [
    {
      name: "File Name",
      selector: (row) => `${row.name}`,
      cell: (row) => (row ? row.name.split(`${clave}/`) : null),
    },
    // {
    //   name: "Size",
    //   selector: (row) => row.size,
    // },
    {
      cell: (row) => (
        <>
          <ActionComponent
            id="tooltip-2"
            text="Download File"
            color="success"
            icon="file-download"
            row={row.name}
            onClick={bajar}
          ></ActionComponent>
          <ActionComponent
            id="tooltip-1"
            text="Delete File"
            color="danger"
            icon="trash"
            row={row.name}
            onClick={deleterow}
          ></ActionComponent>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Documents Requested</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Technical sheets.</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Certifications if available.</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Detailed description of the products, components, manufacturing materials.</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Manufacturing process</td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>Procurement processes for products, materials and/or components.</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>Hazardous material contents if any.</td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>In case of foodstuffs labels and allergen contents. Food safety process.</td>
            </tr>
          </tbody>
        </Table>
      <Content>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group mb-5 mt-n4">
              <div className="custom-file">
                <label className="custom-file-label" htmlFor="file-uploader" style={{ border: '2px solid #136C7E', }}>
                <input
                  type="file"
                  {...register("file")}
                  className="custom-file-input"
                  onChange={getCurrentFiles}
                  multiple
                  id="file-uploader"
                />
                  Choose files
                </label>
              </div>
            </div>
            {filesname.length > 0 ? (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-info">File Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filesname.map((img, index) => {
                      return (
                        <tr key={index}>
                          <td>{img}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <FormGroup>
                  <Button color="primary" type="submit">
                    Upload
                  </Button>
                </FormGroup>
              </>
            ) : null}
          </form>{" "}
        </div>
      </Content>

      <DataTable
        responsive
        pointerOnHover
        dense
        striped
        pagination
        columns={columnas}
        data={documents}
      />
    </React.Fragment>
  );
};
export default DocumentsGenerales;

// const Subirfiles = ({ idcliente, skus, modal, setModal }) => {

//   const getCurrentFiles = (event) => {
//     var rawFiles = event.target.files;

//     // const fileList = []; // WIll contain an array of type File[]
//     // if (!rawFiles) {
//     //   return;
//     // }
//     // if (rawFiles instanceof Array) {
//     //   rawFiles.forEach((file) => fileList.push(file));
//     // } else {
//     //   const rawFilesArray = Array.from(rawFiles);
//     //   rawFilesArray.forEach((file) => fileList.push(file));
//     // }
//     // const finalFileList = [];

//     const finalFileList = [];
//     for (const file of rawFiles) {
//       finalFileList.push(file.name);
//     }

//     setFilesname(finalFileList);

//     setEscondido(true);

//     //   const extensionSeparatorIndex = file.name.lastIndexOf(".");
//     //   const formattedName = file.name.substring(0, extensionSeparatorIndex);
//     //   const extension = file.name.substr(extensionSeparatorIndex);
//     //   const encodedData = await blobToBase64(file);
//     //   const formattedFile = {
//     //     name: formattedName,
//     //     extension,
//     //     file: encodedData?.toString() ?? "",
//     //     type: "append",
//     //     size: file.size,
//     //   };
//     //   if (
//     //     files.find((appendedFile) => appendedFile.name === formattedFile.name)
//     //   ) {
//     //     Swal.fire({
//     //       icon: "info",
//     //       title: `The file ${
//     //         formattedFile.name + formattedFile.extension
//     //       } is already added`,
//     //     });
//     //     break;
//     //   }
//     //   if (!checkFileMIMEType(file.type, ALLOWED_FORMATS)) {
//     //     Swal.fire({
//     //       icon: "warning",
//     //       title:
//     //         "Please select another file (Supported formats: images and PDF)",
//     //       html: `<small class="text-muted">The file ${file.name} is not supported</small>`,
//     //     });
//     //     break;
//     //   }
//     //   if (!checkFileSize(file.size, MAXIMUM_FILE_SIZE)) {
//     //     Swal.fire({
//     //       icon: "warning",
//     //       title: `The maximum file size is ${MAXIMUM_FILE_SIZE}MB`,
//     //       html: `<small class="text-muted">The file ${
//     //         file.name
//     //       } have a size of ${getFormattedSize(file.size, 2)}</small>`,
//     //     });
//     //     break;
//     //   }
//     //   if (MAXIMUM_FILES_LIMIT) {
//     //     if (files.length < MAXIMUM_FILES_LIMIT) {
//     //       finalFileList.push(formattedFile);
//     //     } else {
//     //       Swal.fire({
//     //         icon: "info",
//     //         title: `The maximum file quantity to upload is ${MAXIMUM_FILES_LIMIT}`,
//     //       });
//     //       break;
//     //     }
//     //   } else {
//     //     finalFileList.push(formattedFile);
//     //   }
//     // }
//     // if (finalFileList.length > 0) {
//     //   setFiles([...files, ...finalFileList]);
//     // }
//     // event.target.value = event.target.defaultValue; // Reset input cached value
//   };

//   const onSubmit = async (data) => {
//     const formData = new FormData();
//     const ultimo = data.file.length;
//     for (let i = 0; i < ultimo; ++i) {
//       formData.append("file", data.file[i]);
//       const response = await fetch(
//         "https://apiecl.com/uploaddocuments?id_seller=amazon|amzn1.account.AHG4S7Q35E4KB5DZGK6BENXDZ6IQ/legales",
//         {
//           method: "POST",
//           body: formData,
//         }
//       ).then((res) => console.log(ultimo));
//     }

//     Swal.fire({
//       position: "top-end",
//       icon: "success",
//       title: "Your work has been saved",
//       showConfirmButton: false,
//       timer: 1500,
//     }).then(() => {
//       setModal(false);
//       setFilesname([]);
//     });
//     //alert(JSON.stringify(`${res.message}, status: ${res.status}`));
//   };
//   return (
//     <React.Fragment>
//       <Modal
//         isOpen={modal}
//         className="modal-dialog-centered"
//         size="lg"
//         toggle={() => {
//           setModal(false);
//           setFilesname([]);
//         }}
//       >
//         <ModalBody>
//           <a
//             href="#dropdownitem"
//             onClick={(ev) => {
//               ev.preventDefault();
//               setModal(false);
//               setFilesname([]);
//             }}
//             className="close"
//           >
//             <Icon name="cross-sm"></Icon>
//           </a>
//           <div className="p-2">
//             <h5 className="title text-primary">Upload Files</h5>
//             <hr></hr>
//             <Content>
//               <div>
//                 <form onSubmit={handleSubmit(onSubmit)}>
//                   <div className="input-group mb-5 mt-n4">
//                     <div className="custom-file">
//                       <input
//                         type="file"
//                         {...register("file")}
//                         className="custom-file-input"
//                         onChange={getCurrentFiles}
//                         multiple
//                         id="file-uploader"
//                       />
//                       <label
//                         className="custom-file-label"
//                         htmlFor="file-uploader"
//                       >
//                         Choose files
//                       </label>
//                     </div>
//                   </div>
//                   {filesname.length > 0 ? (
//                     <>
//                       <table className="table">
//                         <thead>
//                           <tr>
//                             <th className="text-info">File Name</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {filesname.map((img, index) => {
//                             return (
//                               <tr key={index}>
//                                 <td>{img}</td>
//                               </tr>
//                             );
//                           })}
//                         </tbody>
//                       </table>
//                       <FormGroup>
//                         <Button color="primary" type="submit">
//                           Upload
//                         </Button>
//                       </FormGroup>
//                     </>
//                   ) : null}
//                 </form>{" "}
//               </div>
//             </Content>
//           </div>
//         </ModalBody>
//       </Modal>
//     </React.Fragment>
//   );
// };

// export default Subirfiles;

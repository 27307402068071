const SaveClasHts = async (event1, event2, idcliente) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const fecha = new Date();
  const fecha1 = fecha.toDateString();
  event1.fecha_creacion = fecha1;
  var raw = JSON.stringify(event1);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    `https://apiecl.com//savehts?id_seller=${idcliente}&sku=${event2}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export default SaveClasHts
const UpdateB2B = async (id,event) => {
	
	const myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');
	const raw = JSON.stringify(event);
	
	const RequestInit  = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'												
	};
	fetch(`https://apiecl.com/savebookingb2c?id=${id}`, RequestInit)
		.then(response => response.text())
		.then(result => console.log(result))
		.catch(error => console.log('error', error));
	
	

};

export default UpdateB2B;
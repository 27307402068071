const GetZipMx = async event => {
	var requestOptions = {
		method: 'GET',
		redirect: 'follow'
		};
	

	const resp = await fetch(`https://apiecl.com/getzip?zip=${event}`, requestOptions);
	const sku = await resp.json();
	
	return sku;
};

export default GetZipMx;